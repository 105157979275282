import dayjs from "dayjs";
import { jwtDecode } from 'jwt-decode';
// 
/**
 * 데이트 형식 변경
 * @param {String} pChkTp 체크 구분 - 월 : month , 일별 : day
 * @param {String} pVal
 * @returns 2024.01 / 2024.01.01
 */
export const MonthDate = (pChkTp, pVal) => {
    let rDate = pVal.replaceAll('-', '. ');
    if (pChkTp === 'day') {
        rDate = rDate.substr(0, 12);
    } else {
        rDate = rDate.substr(0, 7);
    }
    // console.log(rDate);
    return rDate;
}

// 문자 변환
export const replaceAll = (str, oldValue, newValue) => {
    return str.replace(new RegExp(oldValue, 'g'), newValue);
}

/**
 * 금액 3자리 콤마
 * @param {number} pVal
 * @returns string ex) 1,000
 */
export const numberWithCommas = pVal => {
    pVal = pVal == null ? 0 : pVal;
    return pVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

// 날짜
export const formatDate = (inputDate, dateFormat) => {
    let date = '';

    if (inputDate === undefined || inputDate === '' || dateFormat === '-') {
        date = new Date();
    } else {
        date = inputDate;
    };

    if (dateFormat === undefined || dateFormat === null) {
        return dayjs(date).format();
    } else {
        return dayjs(date).format(dateFormat);
    };
};

/**
 * 숫자만 입력 체크
 * @param {String} pVal 
 * @returns true/false
 */
export const isNumerChk = pVal => {
    let val = pVal.toString();
    const findStr = val.match(/[0-9]+/);

    if (val === findStr)
        return true;
    else
        return false;
};

/**
 * 숫자외에 제거
 * @param {String} pVal 
 * @returns true/false
 */
export const NonNumeric = (pVal) => { 
    // 숫자를 제외한 모든 문자를 제거
    return pVal.replace(/[^0-9]/g, '');
  };

/**
 * 필수 항목 입력 체크 공백일 경우 false
 * @param {string} pChkTp : 체크 구분 - 숫자:num, 날짜:date, 문자:string
 * @param {string} pVal : 확인할 값
 * @returns true/false
 */
export const isValidation = (pChkTp, pVal) => {
    // console.log(pVal);
    if (pChkTp === 'select') {
        if (pVal.replace(/\s/g, '') === '' || pVal === '0')
            return false;
    } else {
        if (pVal.replace(/\s/g, '') === '')
            return false;
        else {
            if (pChkTp === 'num') {
                if (!isNumerChk(pVal.replace(/\./g, '').replace(/\,/g, '')))
                    return false;
            };
        };
    };

    return true;
};

/**
 * 공백이 아닐 경우 입력 체크
 * @param {string} pChkTp : 체크 구분 - 숫자:num, 날짜:date, 문자:string
 * @param {string} pVal : 확인할 값
 * @returns true/false
 */
export const isEmptyValidation = (pChkTp, pVal) => {
    const val = pVal;

    if (val.replace(/\s/g, '') !== '') {
        if (pChkTp === 'num') {
            if (!isNumerChk(val.replace(/\./g, '').replace(/\,/g, '')))
                return false;
        } else if (pChkTp === 'date') {
            const pattern = new RegExp(/^(19[7-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/); // yyyy-MM-dd

            if (!pattern.test(pVal))
                return false;
        };
    };

    return true;
};

/**
 * timestamp 생성
 * @param {number} pType : 구분-1:yyyy-MM-dd HH.mm:sszzz, 2:yyyyMMdd_HHmmss, 3:yyyy-MM-01, 4:yyyy-MM-dd
 * @returns string
 */
export const timeStampCustom = (pType) => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let hour = today.getHours();
    let minute = today.getMinutes();
    let second = today.getSeconds();
    let msecond = today.getMilliseconds();
    let timestamp = '';

    month = (month < 10 ? '0' : '') + month;
    date = (date < 10 ? '0' : '') + date;
    hour = (hour < 10 ? '0' : '') + hour;
    minute = (minute < 10 ? '0' : '') + minute;
    second = (second < 10 ? '0' : '') + second;
    if (msecond < 100) msecond = '0' + msecond;
    else if (msecond < 10) msecond = '00' + msecond;

    if (pType === 3)
        timestamp = today.getFullYear() + '-' + month + '-01';
    else
        timestamp = today.getFullYear() + '-' + month + '-' + date;

    if (pType === 1)
        timestamp += ' ' + hour + '.' + minute + ':' + second + ' ' + msecond;
    else if (pType === 2)
        timestamp += '_' + hour + '' + minute + '' + second;

    return timestamp;
}

export const isoFormatDate = (isoString) => {
    // Date 객체로 변환
    const date = new Date(isoString);
    // 연도, 월, 일 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    // 시간, 분, 초 추출
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // yyyy-mm-dd hh:mm:ss 형식으로 조합
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

// ID 정규화 체크
export const isEmailTest = (pVal) => {
    const emailRegex = /^[a-zA-Z0-9-._]+@[a-zA-Z0-9]+\.[a-zA-Z0-9.]+$/;
    return emailRegex.test(pVal) || pVal === '';
};
// pasword 정규화 체크
export const isPwTest = (pVal) => {
    const passReg1 = /^(?=.*[A-Za-z])(?=.*[@$!%*?&#+^()_+-/])[A-Za-z@$!%*?&#+^()_+-/]{8,20}$/ //영문, 특수문자, 8~20자
    const passReg2 = /^(?=.*\d)(?=.*[?=.*[@$!%*?&#+^()_+-/])[\d?=.*[@$!%*?&#+^()_+-/]{8,20}$/ //숫자, 특수문자, 8~20자
    const passReg3 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/ // 영문, 숫자, 8~20자
    const passReg4 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[?=.*[@$!%*?&#+^()_+-/])[A-Za-z\d?=.*[@$!%*?&#+^()_+-/]{8,20}$/ // 영문, 숫자, 8~20자

    return (passReg1.test(pVal) || passReg2.test(pVal) || passReg3.test(pVal) || passReg4.test(pVal)) || pVal === '';
};


// 글자수 초과 시 (...) 대체
export const shortenWords = (str, length) => {
    let result = '';
    if (str.length > length) {
        // console.log(str.length)
      result = str.substr(0, length) + '...';
    } else {
      result = str;
    }
    return result;
};

export const decodedToken = (token) => {
    if(token === ''){
        return '';
    }else{
        return jwtDecode(token);
    }
};