import React, { useEffect } from 'react';

const Laypop = ({ data, closePop, handleConfirm, handleCancel  }) => {

    //스크롤방지
    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
    `;
        return () => {
            document.body.style.cssText = '';
            // 컴포넌트가 언마운트될 때 이전 스타일로 되돌립니다.
            // const scrollY = document.body.style.top;
            // window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, [])

    return (
        <div className="layerPop" >
            <div className="layerCon">
                <div className="layerTop">
                    <div className="ltCont">
                        <p>알림</p>
                        <a href="javascript:;" onClick={closePop}><img src="/images/btn/x.svg" alt="닫기" /></a>
                    </div>
                    <div className="layerCont">
                        {/* 태그입력을 위한 __html */}
                        <p dangerouslySetInnerHTML={{__html: data}} />
                    </div>
                </div>
                {/* 버튼이 두개일 경우 div에 .twoBtn 추가 */}
                <div className='popBtnBox '>
                    <a href="javascript:;" className="popBtn" onClick={closePop}>확인</a>
                </div>
            </div>
        </div>
    )
}
export default Laypop;