import React from 'react';

const AppStore = () => {
    return (
        <div className="appStore">
            <a href="https://imin.onelink.me/bcPR/cscehns9" target="_blank" rel="noopener noreferrer">
                <img src="/images/icon/ico_google.svg" alt="Google Play" />
                Google Play
            </a>
            <a href="https://imin.onelink.me/bcPR/cscehns9" target="_blank" rel="noopener noreferrer">
                <img src="/images/icon/ico_apple.svg" alt="App Store"/>
                App Store
            </a>
        </div>
    )
}

export default AppStore;
