import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_PARTNERS_FRONT_API_URL,
  // headers: {
  //   Authorization: `Bearer ${localStorage.getItem('token')}`,
  // },
});

api.interceptors.request.use(
  (config) => {
    // console.log(config);
    return config;
  },
  (error) => {
    return error.response.data;
  }
);

api.interceptors.response.use(
  (response) => {
    // console.log(response.config.url);
    if (!(response.status === 200 || response.status === 201 || response.status === 204))
      throw new Error();

    if (response.data.errors)
      throw new Error(response.data.errors);

    // console.log(response);
    if (response.config.responseType === 'blob')
      return response
    else
      return response.data;
  },
  async (error) => {
    // console.log(error);
    return error.response.data;
  }
);