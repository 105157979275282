import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue, useResetRecoilState, useRecoilState} from 'recoil';
import { useNavigate} from "react-router-dom";
import loginInfoState from 'store/State';
import Laypop from "components/Laypop";
import * as layout from 'common/js/layout';
import {isoFormatDate, decodedToken} from 'common/js/func';

const Header = ({setHideFooter }) => {
    const currentPath = window.location.pathname // 현재 url
    
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    const resetLoginInfoState = useResetRecoilState(loginInfoState); // 로그인 정보 초기화
    const { token, isLogin, loginExpiration} = loginInfoValue;
    const decodeInfoValue = decodedToken(token);
    const { pnidx, unm, rmcd} = decodeInfoValue;
    // const {}
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        // 로그인 x 상태에서 리포트, 1:1문의 페이지 이동시
        setJoinBox(false);
        if (!isLogin && ((page.includes('promotion') || page.includes('report') || page.includes('Ask')))) {
            openPop('로그인 후 이용해주세요.');
        } else {
            movePage(page);
        }
    }

    const [pageCnt, setPageCnt] = useState(0);
    /**
    * 뒤페이지 이동 (값이 1이하일경우 외부페이지에서 접속)
    */
    const goBack = () => {
        if (pageCnt <= 1) {
            movePage('/');
        } else {
            movePage(-1);
        }
    }

    /****************************************************** */
    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
        if (isLogin) {
            logout();
        } else {
            movePage('/myinfo/Login');
        }
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };

    /******************************************* */
    // 너비 resize 관련

    /**
     * 1: 웹 메뉴 2 모바일 1~6(로그인관련) 메뉴
     */
    const [subMenu, setSubMenu] = useState(1);
    const [menuNm, setMenuNm] = useState('');
    const [menuSelect, setMenuSelect] = useState(999);

    /**
     * 너비체크
     */
    useEffect(() => {
        setPageCnt(prevCount => prevCount + 1);
        webResize();
        menuClick();
        window.addEventListener('resize', webResize);

        // 컴포넌트가 unmount 될 때 이벤트  해제
        return () => {
            window.removeEventListener('resize', webResize);
        };

    }, [currentPath]);
    
    /**
     * 모바일 헤더 적용
     */
    const webResize = () => {
        const innerWid = window.innerWidth;
        // if (innerWid < 767 && currentPath.includes('/myinfo')) {
        if (innerWid < 767 && currentPath !== '/') {
            setSubMenu(2);
            menuNmChange();
        } else {
            setSubMenu(1);
            setHideFooter(false);
        }
    };

    /**
     * 메뉴명
     */
    const menuNmChange = () => {
        setMenuNm(layout.sideMenuNm(currentPath));
        if (currentPath === '/myinfo/Join' || currentPath === '/myinfo/Login') {
            setHideFooter(true);
        } else {
            setHideFooter(false);
        }
    }

    /**
     * 메뉴 클릭시 체크
     * */
    const menuClick = () => {
        if (currentPath.includes('promotion')) {
            setMenuSelect(0);
        } else if (currentPath.includes('report')) {
            setMenuSelect(1);
        } else if (currentPath.includes('usage')) {
            setMenuSelect(2);
        } else if (currentPath.includes('notice')) {
            setMenuSelect(3);
        } else {
            setMenuSelect(999);
        }
    }

    /*************************************************************** */
    // 스크롤 관련
    useEffect(() => {
        window.addEventListener('scroll', indexScroll); // 일정 영역 도달시 버튼 보임/감춤

        return () => {
            window.removeEventListener('scroll', indexScroll); // 일정 영역 도달시 버튼 보임/감춤
        };
    }, []);

    // 헤더 오퍼시티
    const indexScroll = (event) => {
        setJoinBox(false);
        setDepthDisplay1(false);
        setDepthDisplay2(false);
        const scrollPos = window.scrollY;
        const obj = document.getElementById('header');
        if (scrollPos > 100) {
            // 해더 투명도
            obj.className = 'headerWrap oper';
        } else {
            if (!document.querySelector('.imgClick')) { // 버튼 클릭 상태에서는 x
                obj.className = 'headerWrap';
            }
        }

    }

    
    /******************************************* */
    // 헤더 버튼 관련
    const [joinBox, setJoinBox] = useState(false);

    const toggleProfileBox = (event) => {
        setJoinBox(!joinBox);
        setDepthDisplay1(false);
        setDepthDisplay2(false);
    };

    const showProfileBox = () => {
        // setJoinBox(true);
    };

    const hideProfileBox = () => {
        // setJoinBox(false);
    };

    const [depthDisplay1, setDepthDisplay1] = useState(false);
    const [depthDisplay2, setDepthDisplay2] = useState(false);
    const depthDisplay = (depthTp) => {
        setJoinBox(false);
        if (depthTp === 1) {
            setDepthDisplay1(!depthDisplay1);
            setDepthDisplay2(false);
        } else {
            setDepthDisplay2(!depthDisplay2);
            setDepthDisplay1(false);
        }

    }
    const WebMenu = () => {
        const topM = layout.topMenu.map((topT, index) => {
            const sideM = layout.sideMenu[index];
            const isDepth1 = index === 1;
            const isDepth2 = index !== 0 && index !== 3 && !isDepth1;
            const isActive = index === menuSelect;

            const sideLi = sideM.map((sT, idx) => (
                <li key={idx}>
                    <a href="javascript:;" onClick={() => {
                        pageMoveA(sT.m_url);
                        setDepthDisplay1(false);
                        setDepthDisplay2(false);
                    }}>
                        {sT.m_nm}
                    </a>
                </li>
            ));
            return (
                <li key={index} >
                    {index !== 0 && index !== 3 ? (
                        <>
                            {index === 1 ? (
                                <>
                                    <a href="javascript:;" className={index === menuSelect ? 'active' : ''} onClick={() => depthDisplay(1)}>
                                        {topT.m_nm}
                                    </a>
                                    <ul className="miniDepth openBox" style={{ display: depthDisplay1 ? 'block' : depthDisplay2 ? 'none' : '' }}>{sideLi}</ul>
                                </>
                            ) : (
                                <>
                                    <a href="javascript:;" className={index === menuSelect ? 'active' : ''} onClick={() => depthDisplay(2)}>
                                        {topT.m_nm}
                                    </a>
                                    <ul className="miniDepth openBox" style={{ display: depthDisplay2 ? 'block' : depthDisplay1 ? 'none' : '' }}>{sideLi}</ul>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <a href="javascript:;" className={index === menuSelect ? 'active' : ''} onClick={() => {
                                pageMoveA(topT.m_url);
                                setDepthDisplay1(false);
                                setDepthDisplay2(false);
                            }}>
                                {topT.m_nm}
                            </a>
                        </>
                    )}
                </li>
            );
        });

        return <ul className="webMenu openBox">{topM}</ul>;
    }

    const NavMenu = () => {
        const topM = layout.topMenu.map((topT, index) => {
            const sideM = layout.sideMenu[index];

            const sideLi = sideM.map((sT, idx) => (
                    <li key={idx}>
                        <a href="javascript:;" onClick={() => pageMoveA(sT.m_url)}>
                            {sT.m_nm}
                        </a>
                    </li>
            ));

            return (
                <li key={index} className="depthOne">
                    <a href="javascript:;" onClick={() => pageMoveA(topT.m_url)}>
                        {topT.m_nm}
                    </a>
                    {index !== 0 && index !== 3 &&
                        <ul className="depthTwo">{sideLi}</ul>
                    }
                </li>
            );
        });
        return <ul className="nav">{topM}</ul>;
    }

    /**
     * 메뉴바 클릭시
     */
    const [menubarTp, setMenubarTp] = useState(false);
    const menuBarClick = () =>{
        setMenubarTp(!menubarTp);
        setJoinBox(false);
        setDepthDisplay1(false);
        setDepthDisplay2(false);
    }

     /******************************************* */
    // 로그인 관련 
    const [loginInfo, setLoginInfo] = useRecoilState(loginInfoState);

    // 버튼 클릭 추가
    useEffect(() => {
        document.addEventListener('click', loginExtension);
        // 컴포넌트가 언마운트될 때  제거
        return () => {
            document.removeEventListener('click', loginExtension);
        };
    }, []);

    // 페이지 이동시 실행
    useEffect(() => {
        // loginExtension();
    }, [currentPath]);

    // 마운트 시 이벤트 실행
    useEffect(() => {
        // console.log(loginInfo.loginExpiration);
        startTimer();

        //언마운트될 때 타이머를 멈춤
        return () => {
            stopTimer();
        };
    }, [loginInfo])

    /**
     * 로그인 연장
     */
    const loginExtension = (event) => {
        const clickedElement = event.target;
        const closestElement = clickedElement.closest('.openBox');
        if (!closestElement) {
            setJoinBox(false);
            setDepthDisplay1(false);
            setDepthDisplay2(false);
        }
        const date = new Date();
        // const loginExpiration = new Date(date.setHours(date.getHours() + 6)); // 로그인 만료 6시간으로 설정
        // const loginExpiration = new Date(date.setHours(date.getHours() + 1)); // 로그인 만료 1시간테스트
        const loginExpiration = new Date(date.getTime() + 30 * 60000);// 30분
        // const loginExpiration = new Date(date.setSeconds(date.getSeconds() + 1)); // 로그인 만료 5초 테스트

        setLoginInfo(prevLoginInfo => ({
            ...prevLoginInfo,
            loginExpiration: loginExpiration.toISOString(),
        }));
        startTimer();
    }

    const timer = useRef(null);
    /**
     * 일정시간 지났을시 로그아웃
     */
    const startTimer = () => {
        if (!timer.current && loginInfo.isLogin) { //타이머 값이 있고 로그인되어있을시.
            timer.current = setInterval(() => {
                // console.log(isoFormatDate(loginInfo, .loginExpiration));
                if (new Date(loginInfo.loginExpiration) < new Date()) {
                    stopTimer();
                    // openPop('로그인 후 6시간이 지났습니다.<br />재 로그인 후 이용해 주세요.');
                    openPop('장시간 미사용으로 로그아웃 되었습니다. 다시 로그인해 주세요.');
                };
            }, 1000);
        }
    };

    /**
     * 이벤트 멈춤
     */
    const stopTimer = () => {
        if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }
    };
    /**
     * 로그아웃
     */
    const logout = () => {
        resetLoginInfoState();
        localStorage.clear();
        window.location.href = '/';
    };
    return (
        <>
            {/* {startTimer()} */}
            <section className="headerWrap" id="header">
                <section className="header">
                    <h1 className="logo" style={{ display: subMenu === 1 ? '' : 'none' }}>
                        <a href="javascript:;" onClick={() => pageMoveA('/')}>
                            <img src="/images/logo.png" alt="TWAVE Paartners" />
                        </a></h1>
                    {/* 모바일(767px 이하) 서브페이지 top에 하단 div.subHeader */}
                    <div className="subHeader" style={{ display: subMenu === 2 ? '' : 'none' }}>
                        <a href="javascript:;" onClick={goBack}><img src="/images/btn/chevron-left.svg" alt="뒤로가기" /></a>
                        <h2>{menuNm}</h2>
                    </div>

                    <header>
                        <WebMenu/>
                        <div className="headRight">
                            <div className="mobileMenu" style={{ display: 'none' }} onClick={menuBarClick}>
                                <a href="javascript:;">
                                    <img src="/images/btn/menu.svg" alt="menu" />
                                </a>
                                <div className="gnb" style={{ display: menubarTp ? 'block' : 'none' }}>
                                    <div className="gnbBg">
                                        <div>
                                            <a href="javascript:;" onClick={() => pageMoveA('/')}>
                                                <img src="/images/icon/home.svg" alt="home" />
                                                home</a>
                                            <a href="javascript:;" className="closeBtn">
                                                <img src="/images/btn/x.svg" alt="" />

                                            </a>
                                        </div>
                                        <NavMenu />
                                    </div>
                                </div>
                            </div>
                            <div className="profileBox openBox">
                                {isLogin ? (
                                    <>
                                        <a href="javascript:;" className="progileLogin" onClick={toggleProfileBox} onMouseOver={showProfileBox} onMouseOut={hideProfileBox}>
                                            {/* <img src="/images/icon/ico_profile.svg" alt="profile" /> */}
                                            <span>{unm.substr(0, 1)}</span>
                                        </a>
                                        <ul className="openBox" style={{ display: joinBox  ? 'block' : 'none' }}>
                                            <li><a href="javascript:;" onClick={() => pageMoveA('/myinfo/Myinfo')}>내 정보 관리</a></li>
                                            <li><a href="javascript:;" onClick={logout}>로그아웃</a></li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <a href="javascript:;" onClick={toggleProfileBox} onMouseOver={showProfileBox} onMouseOut={hideProfileBox}>
                                            <img src="/images/icon/ico_profile.svg" alt="profile" />
                                        </a>
                                        <ul className="openBox" style={{ display: joinBox ? 'block' : 'none' }}>
                                            <li><a href="javascript:;" onClick={() => pageMoveA('/myinfo/Login')}>로그인</a></li>
                                            <li><a href="javascript:;" onClick={() => pageMoveA('/myinfo/Join')}>회원가입</a></li>
                                        </ul>
                                    </>
                                )
                                }
                            </div>
                        </div>
                    </header>
                </section>
            </section>
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        </>
    )
}
export default Header;