import React, { useState, useEffect } from 'react';

/**
 * Pagination
 * @param {number, number, number, number} 
 * @returns compornant
 */
const Pagination = ({ totalPage, limit, page, setPage }) => {
    const [currentPageArray, setCurrentPageArray] = useState([]);
    const [totalPageArray, setTotalPageArray] = useState([]);

    const sliceArrayByLimit = (totalPage, limit) => {
        const pageArray = Array.from({ length: totalPage }, (_, i) => i);
        const slicedPageArray = [];

        for (let i = 0; i < pageArray.length; i += limit) {
            slicedPageArray.push(pageArray.slice(i, i + limit));
        }

        return slicedPageArray;
    };

    // 다음 페이징의 첫 페이지 번호
    const firstPage = nowPage => {
        const currentPage = (nowPage - 1);
        const nextPage = (Math.floor(currentPage / limit) * limit) + 1;
        
        return nextPage;
    };

    useEffect(() => {
        // console.log('page % limit');
        if (page % limit === 1) {
            setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
        } else if (page % limit === 0) {
            setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
        }
    }, [page]);

    useEffect(() => {
        // console.log('slicedPageArray');
        const slicedPageArray = sliceArrayByLimit(totalPage, limit);
        setTotalPageArray(slicedPageArray);
        setCurrentPageArray(slicedPageArray[0]);
    }, [totalPage]);

    return (
        <ul className="pagination">
            <li key="first" className="first">
                <button
                    onClick={() => {
                        if (limit >= page)
                            return null;
                        else
                            setPage(1);
                    }}
                    className={limit >= page ? "disabled" : null}>
                    <img src="/images/btn/first.svg" alt="맨 앞으로 가기" />
                </button>
            </li>
            <li key="prev" className="prev">
                <button
                    onClick={() => {
                        if (limit >= page)
                            return null;
                        else                            
                            setPage(firstPage(page - limit));
                    }}
                    className={limit >= page ? "disabled" : null}>
                    <img src="/images/btn/prev.svg" alt="이전 페이지로 이동" />
                </button>
            </li>
            <>
                {currentPageArray?.map((i) => (
                    <li key={i + 1}>
                        <button
                            onClick={() => setPage(i + 1)}
                            className={page === i + 1 ? "active" : null}
                        >
                            {i + 1}
                        </button>
                    </li>
                ))}
            </>
            <li key="next" className="next">
                <button
                    onClick={() => {
                        if ((totalPage - limit < firstPage(page) && totalPage >= page) || totalPage === 0 )
                            return null;
                        else
                            setPage(firstPage(page + limit));
                    }}
                    className={(totalPage - limit < firstPage(page) && totalPage >= page) || totalPage === 0 ? "disabled" : null}>
                    <img src="/images/btn/next.svg" alt="다음 페이지로 이동" />
                </button>
            </li>
            <li key="last" className="last">
                <button
                    onClick={() => {
                        if ((totalPage - limit < firstPage(page) && totalPage >= page) || totalPage === 0 )
                            return null;
                        else
                            setPage(firstPage(totalPage));
                    }}
                    className={(totalPage - limit < firstPage(page) && totalPage >= page) || totalPage === 0 ?  "disabled" : null}>
                    <img src="/images/btn/last.svg" alt="맨 뒤로 가기" />
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
