import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import Pagination from 'components/Pagination';
import * as CommonFunc from 'common/js/func';
import { useRecoilValue } from 'recoil';
import loginInfoState from 'store/State';

import 'common/css/report/recommend.css';

const Recommend = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    // const { pnidx, unm, token, rmcd, isLogin, loginExpiration } = loginInfoValue;
    const {token} = loginInfoValue;
    const decodeInfoValue = CommonFunc.decodedToken(token);
    const { pnidx} = decodeInfoValue;

   const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }
    //카운트 관련
    /****************************************************/
    const [totalCnt, setTotalCnt] = useState(0);
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호

    /****************************************************/    
    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));

    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);

        onList();
    }, [paraGetPage])
    
    // 페이지 이동
    const changePages = (page) => {
        // setPageNum(page);
        movePage(`?page=${page}`);
    }
    //usequry 검색용
    const [isSearch, setIsSearch] = useState(true);
    const [isList, setIsList] = useState(true);
 
    //리스트 검색
    const onList = () => {
        setIsList(!isList);
    };
    
    // 총카운트
    const [listTotal, setListTotal] = useState({
        tt_r_cnt: 0,
        tt_s_fee: 0,
        tt_pn_fee: 0,
    })
        
    /*********************/
	//리스트
	const [listData, setListData] = useState([]);
     // 총카운트
    const [listUseDate, setListUseDate] = useState({
        cg_c_fee:0,
    })
    
     useQuery(['performMemUseDate', isSearch],
        async () => {
            //console.log(tabTp);
            const req = {
                params: { partnerIdx: pnidx }
            };
            const res = await api.get(`/api/v1/Perform/performMemUseDate`, req);
            //console.log(res);
            if (res.status === 200) {
                setListUseDate(res.data);
            }
        });

 
     useQuery(['recommendCnt'],
        async () => {
            const req = {
                params: { partnerIdx:pnidx }
            };
            //console.log(req);
            const res = await api.get(`/api/v1/Perform/recommendListTotal`, req);
            //console.log(res);
            if (res.status === 200) {
                setListTotal(res.data);
            }
        });

 
 	useQuery(['recommendList', isList],
		async () => {
         
			const req = {
				params: {partnerIdx:pnidx, page: pageNum - 1, size: pageRowCnt }
			}
			
			const res = await api.get(`/api/v1/Perform/recommendList`, req);

			 if (res.status === 200) {
                setListData(res.data.content);
                setTotalCnt(res.data.totalElements);
            }
		});	
		
    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])
    		
    //엑셀 다운로드
    const Excelinput = async () => {
//		console.log("excel");
        
		const requestData = {
			//params: {sdate: sMonthDate, edate: eMonthDate, pnidx:pnidx, page: pageNum - 1, size: pageRowCnt }
			partnerIdx:pnidx, page: 0, size: 99999
		}
			
        const res = await api.get(`/api/v1/Perform/excel/recommendList`,{
            responseType: 'blob', // 바이너리 데이터로 응답 받기
            params: requestData
        });

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '추천인 리포트.xlsx'); // 실적 내역
        document.body.appendChild(link);
        link.click();
    }   		   
       
    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/report/Performance')}>리포트</span>
                    <span onClick={() => pageMoveA('/report/Recommend')}>추천인 리포트</span>
                </div>
            </div>
            <section className="subCont recommend" id="recommend">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>추천인 리포트</h4>
                    <p>아임인 파트너스 가입 시 내 추천인 코드를 등록한 회원(피추천인)으로부터 내가 받는 보너스 수익입니다.</p>
                        <ul>
                            <li>보너스 적용 기간 : 피추천인의 가입일로부터 30일 간 첫 달에만 주어지는 추가 보너스 적용 기간</li>
                            <li>아임인 이용 금액의 0.1%를 ‘추천인’과 ‘피추천인’ 모두에게 추가 지급</li>
                        </ul>
                </div>
                <div className="excelDown"><a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput} ><img src="../images/icon/download_v.svg" alt="다운로드" />엑셀 다운로드</a></div>
                <ul className="addBox">
                    <li>
                        <p>총 추천인 수</p>
                        <p>{CommonFunc.numberWithCommas(totalCnt)}</p>
                    </li>
                    <li>
                        <p>아임인 이용</p>
                        <p>{CommonFunc.numberWithCommas(listTotal.tt_s_fee + listTotal.tt_pis_fee)}</p>
                    </li>
                    <li>
                        <p>추천인 수익</p>
                        <p>{CommonFunc.numberWithCommas(listTotal.tt_pn_fee)}</p>
                    </li>
                </ul>
                <div className="tableBox">
                    <table className="tableList">
                        <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>피 추천인 아이디</th>
                                <th>보너스 적용 기간</th>
                                <th>아임인 이용</th>
                                <th>추천인 수익</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                 Array.isArray(listData) ? (
                                    totalCnt > 0 ? (
                                        listData.map((item, index) => (
											
                                            <tr key={index} >
                                                <td>{item.id}</td>
												<td>{item.u_s_date + ' ~ ' + item.u_e_date}</td>
												<td>{CommonFunc.numberWithCommas(item.s_fee + item.pi_s_fee)}</td>
												<td>{CommonFunc.numberWithCommas(item.pn_fee)}</td>
                                            </tr>
                                        ))) :
                                        (
                                            <tr><td colSpan="4" className="noData">검색 결과가 없습니다.</td></tr>
                                        )) :
                                    (
                                        null
                                    )
                            }                           
                        </tbody>
                    </table>
                </div>
                {/* <!-- 페이징 --> */}
                <div className="pagingDiv">
                    <Pagination
                        totalPage={totalPage === 0 ? 1 : totalPage}
                        // totalPage={totalPage}
                        limit={pageSize}
                        page={pageNum}
                        setPage={changePages}
                    />
                </div>
            </section>
        </>
    )
}

export default Recommend;
