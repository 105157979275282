import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from 'react-query';
import { api } from 'api/api';
import { useNavigate, useParams } from "react-router-dom";
import Laypop from "components/Laypop";
import Confirmpop from "components/Confirmpop";
import * as CommonFunc from 'common/js/func';
import 'common/css/myinfo/join.css'
// import 'common/css/policy.css'
import ClearInput from "components/InputClear";
import InputPw from "components/InputPw";


const Join = () => {
    
    // 추천인코드로 진입 시, 파라미터 제공
    const { rmCd } = useParams();

    //*************************************/ 알림 팝업


    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => { // 확인
        setPopData(null);
        setPopOpen(false);
        // sendSms.mutate();
        if(closeTp === 1){
            //pageMoveA('/');
            window.location.href = '/';
        }else{
            pageMoveA('/myinfo/Login');
        }
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };

    const [popOpen2, setPopOpen2] = useState(false);
    const [popData2, setPopData2] = useState(null);

    const closePop2 = () => { // config 취소
        setPopData2(null);
        setPopOpen2(false);
        // console.log(23);
        // pageMoveA('/');
    };

    const closePop3 = () => { // config 확인
        setPopData2(null);
        setPopOpen2(false);
        userJoin.mutate();
        // console.log(23);
        // pageMoveA('/');
    };

    const openPop2 = (data) => { // config팝업
        setPopData2(data);
        setPopOpen2(true);
    };
    //*************************************/

    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    //계좌번호
    const [codeData, setCodeData] = useState([]);
    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        codeList();
    }, [])
    const codeList = async () => {
        const req = {
            params: { pt_idx: 1 }
        };
        const res = await api.get(`/api/v1/myinfo/codeSelList`, req);
        // const res2 = await api.get(`/api/v1/myinfo/test`, req);
        // console.log(res);
        if (res.status === 200) {
            setCodeData(res.data);
            // console.log(res.data.length);
        }

    }

    // 회원가입
    /******************************************** */
    //인증 전
    const [idOk, setIdOk] = useState(false); // 아이디 전체 체크
    const [idCheck, setIdCheck] = useState(true); // 아이디 유무 체크
    const [idCheck2, setIdCheck2] = useState(true); // 탈퇴 아이디 유무 체크
    const [idCondition, setIdCondition] = useState(true); // 아이디 정규화
    const [pwCondition, setPwCondition] = useState(true); // 패스워드 조건
    const [pwMatch, setPwMatch] = useState(true); // 패스워드 일치 확인

    //r
    /*/
    const [certiBefore, setCertiBefore] = useState({
        userId: 'test@naver.com',
        userPw: '123123@@',
        userPw2: '123123@@',
    })
    /*/
    const [certiBefore, setCertiBefore] = useState({
        userId: '',
        userPw: '',
        userPw2: '',
    })
    /**/

    const [agreAll, setAgreAll] = useState(false) // 약관전체 동의
    const [agreService, setAgreService] = useState(false) // 서비스 약관
    const [agrePreivate, setAgrePreivate] = useState(false)// 개인정보 동의
    const [agreMarketing, setAgreMarketing] = useState(false)// 마케팅 동의

    const [certification, setCertification] = useState(false); // 본인인증 버튼 활성화
    const [verifiCheck, setVerifiCheck] = useState(false)// 본인인증 체크 완료

    const debounceTimer = useRef(null);

    // 빠른 연속 입력을 제한
    const debounce = (func, delay) => {
        return function (...args) {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
            debounceTimer.current = setTimeout(() => func.apply(this, args), delay);
        };
    };

    // 아이디 빈값, 정규화 체크
    const validateUserId = (userId) => {
        if (!CommonFunc.isEmailTest(userId)) {
            setIdCondition(false);
            setIdOk(false);
            setIdCheck(true);
            setIdCheck2(true);
            return false;
        }

        setIdCondition(true);

        if (userId === '') {
            setIdOk(false);
            setIdCheck(true);
            setIdCheck2(true);
            return false;
        }

        return true;
    };
    
    // 아이디 체크 중복, 유무 체크
    const idUniqueCheck = async (userId) => {
        if (!validateUserId(userId)) {
            return false;
        }
        
        const req = {
            params: { id: userId }
        };
        const res = await api.get(`/api/v1/myinfo/isIdUnique`, req);

        if (!validateUserId(userId)) {
            return false;
        }

        if (res.status === 200) {
            setIdCheck(true);
            setIdCheck2(true);
            setIdOk(true);
        } else {
            if (res.data === 8) {     // 탈퇴한 아이디
                setIdCheck(true);
                setIdCheck2(false);
                setIdOk(false);
            } else {                  // 중복된 아이디
                setIdCheck(false);
                setIdCheck2(true);
                setIdOk(false);
            }
        }
    }

    //체크박스
    const checkBoxClick = (pNum) => {
        if (pNum === 1) {
            if (agreAll) {
                setAgreAll(false);
                setAgreService(false);
                setAgrePreivate(false);
                setAgreMarketing(false);
            } else {
                setAgreAll(true);
                setAgreService(true);
                setAgrePreivate(true);
                setAgreMarketing(true);
            }

        } else if (pNum === 2) {
            setAgreService(!agreService);
        } else if (pNum === 3) {
            setAgrePreivate(!agrePreivate);
        } else {
            setAgreMarketing(!agreMarketing);
        }
    };
    // 체크박스 이벤트 처리
    useEffect(() => {
        if (agreService && agrePreivate && agreMarketing) {
            setAgreAll(true);
        } else {
            setAgreAll(false);
        }
    }, [agreService, agrePreivate, agreMarketing])

    // text 입력처리
    const beforeField = (fieldName) => (e) => {
        const inputValue = e.target.value;

        setCertiBefore((preve) => {
            return {
                ...preve,
                [fieldName]: inputValue,
            };
        });
        if (fieldName === 'userId') {
            // 이메일 형식 체크 , 50글자 제한
            debounce(() => idUniqueCheck(inputValue), 300)();
            // setIdCondition(CommonFunc.isEmailTest(inputValue) && inputValue.length <= 50);
        }
        if (fieldName === 'userPw') { // 패스워드 일치, 조건 확인
            const userPw2 = certiBefore.userPw2;

            // 비밀번호 형식 체크, 20글자 제한
            setPwCondition(CommonFunc.isPwTest(inputValue) && inputValue.length <= 20);

            if (userPw2 === '') {
                setPwMatch(true);
            } else {
                setPwMatch(userPw2 === inputValue);
            }

        } else if (fieldName === 'userPw2') {
            const userPw = certiBefore.userPw;

            if (inputValue === '') {
                setPwMatch(true);
            } else {
                setPwMatch(userPw === inputValue);
            }
        }
    };

    const fieldClean = (fieldName, pTp) => {
        if (pTp === 1) {
            setCertiBefore((preve) => {
                return {
                    ...preve,
                    [fieldName]: '',
                };
            });

            if (fieldName === 'userId') {
                idUniqueCheck('');
            }
        } else {
            setCertiAfter((preve) => {
                return {
                    ...preve,
                    [fieldName]: '',
                };
            });

            if (fieldName === 'recommCd') {
                recommUniqueCheck('');
            }

        }
    }

    // 모든 조건 만족시 본인인증 버튼 활성화
    const certifiActiv = () => {
        const isValid = agreService && agrePreivate && idCheck && idCondition && pwCondition && pwMatch && certiBefore && idOk
            && certiBefore.userId !== '' && certiBefore.userPw !== '' && certiBefore.userPw2 !== ''

        // console.log('123:' +idOk);
        // console.log(isValid);
        setCertification(isValid);
        if (!idCondition) {
            setIdOk(false);
        }
    }

    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        certifiActiv();
    }, [agreService, agrePreivate, idCheck, idCondition, pwCondition, pwMatch, certiBefore, idOk])

    //r
    // 본인인증 체크
    const verification = () => {
        if (certification) {
            // setVerifiCheck(true);
            main(); // 유즈쿼리 실행

        }
    }

    /******************************************** */
    //인증후
    //r
    /*/
    const [certiAfter, setCertiAfter] = useState({
        userNm: '테스트2', // 이름
        mobile: '01012341234', // 휴대폰
        userSNum: '', // 주민 앞
        userENum: '', // 주민 뒷
        bankNm: '2', // 은행선택
        bankNum: '09410520301018', // 은행
        recommCd: 'ICT00001',
    })
    /*/
    const [certiAfter, setCertiAfter] = useState({
        userNm: '', // 이름
        mobile: '', // 휴대폰
        userSNum: '', // 주민 앞
        userENum: '', // 주민 뒷
        bankNm: '', // 은행선택
        bankNum: '', // 은행
        recommCd: rmCd || '',
    })
    /**/
    const [numCheck, setNumCheck] = useState(true); // 주민등록번호 체크
    const [recommendation, setRecommendation] = useState(false); // 추천인 체크
    const [joinCertification, setJoinCertification] = useState(false); // 가입 완료 활성화


    const juminCheck = (juminNum) => {
        let jumin = juminNum.split('');
        let ckarr = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];

        for (let i = 0; i < jumin.length - 1; i++) {
            jumin[i] = jumin[i] * ckarr[i];
        }
        let juminlast = jumin[jumin.length - 1];

        let sum = 0;
        for (let i = 0; i < jumin.length - 1; i++) {
            sum += jumin[i];
        }

        sum = sum % 11;

        sum = 11 - sum;

        if (sum > 9) {
            sum = sum % 10;
        }
        return sum === parseInt(juminlast, 10) && juminNum.length >= 13;
    };

    const afterField = (fieldName) => (e) => {
        let inputValue = e.target.value;

        if (fieldName === 'bankNum') { // 계좌번호 입력 숫자외 아예 입력안되게 변경
            inputValue = CommonFunc.NonNumeric(inputValue);
        }

        // console.log(inputValue);
        setCertiAfter((preve) => {

            return {
                ...preve,
                [fieldName]: inputValue,
            };
        });

        /* 주민등록번호 길이값 체크*/
        if (fieldName === 'userSNum') {
            const userSNum = inputValue;
            const numSLenth = userSNum.length;

            setNumCheck(numSLenth === 0 || numSLenth >= 6);

        } else if (fieldName === 'userENum') {
            const userENum = inputValue;
            setNumCheck(juminCheck(certiAfter.userSNum + userENum));
        }

        if (fieldName === 'recommCd') {
            recommUniqueCheck(inputValue);
        }
    };

    // 모든 조건 만족시 가입완료 버튼 활성화
    const joinActiv = () => {
        const isValid = numCheck
            && certiAfter.userNm !== '' && certiAfter.mobile !== '' && certiAfter.userSNum !== ''
            && certiAfter.userENum !== '' && certiAfter.bankNm !== '' && certiAfter.bankNum !== ''

        setJoinCertification(isValid);
    }

    useEffect(() => {
        if(rmCd){
            recommUniqueCheck(rmCd)
        }
    }, [])

    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        joinActiv();
    }, [numCheck, joinCertification, recommendation, certiAfter])

    //*************************************/
    // 체크박스 이미지
    const checkImg = (check) => {
        return check ? '/images/btn/checked.svg' : '/images/btn/unchecked.svg'
    };

    //*************************************/
    // 약관 팝업
    const [agreementPop, setAgreementPop] = useState(false);
    const [privatePop, setPrivatePop] = useState(false);
    const [marketingPop, setMarketingPop] = useState(false);
    
    // termsTp 1: 서비스 이용약관 , 2: 개인정보, 3:마케팅 정보 수집
    const closeTermsPop = (termsTp) => {
        if (termsTp === 1) {
            setAgreementPop(false);
        } else if (termsTp === 2) {
            setPrivatePop(false);
        } else if (termsTp === 3) {
            setMarketingPop(false);
        }
    };

    const openTermsPop = (termsTp) => {
        if (termsTp === 1) {
            setAgreementPop(true);
        } else if (termsTp === 2) {
            setPrivatePop(true);
        } else if (termsTp === 3) {
            setMarketingPop(true);
        }
    };


    const sendSms = useMutation(async () => {
        const requestData =
        {
            params: {
                szReceiveNum: certiAfter.mobile,
                szMessage: `안녕하세요 회원님\r\n아임인 파트너스 가입을 환영합니다.\r\n\r\n목돈마련 플랫폼 아임인을 홍보하고 혜택 받아세요.\r\n\r\n■ 아임인 파트너스 더 알아보기 : www.iminpartners.kr`,
            }
        };
        // const res = '';
        // console.log(requestData);
        const res = await api.get(`/api/v1/myinfo/sendSms`, requestData);
        // console.log(res);
        return res;
    }, {
        onSuccess: (res) => {
            if (res.status === 200) {
            } else {
                console.log(res.message);
            }
        },
    });



    //*************************************/
    // 본인인증 nice

    const [authRes, setAuthRes] = useState([]);
    const [seqs, setSeqs] = useState('');
    const [closeTp, setCloseTp] = useState(1);

    const { refetch: main } = useQuery(['main', verifiCheck],
        async () => {
            // console.log('본인인증 버튼 클릭')
            const res = await api.get(`/callApi/main`, null);
            // console.log(res);
            if (res.result_cd === 0) {

                // console.log(res);
                // console.log(res.req_seq);
                setSeqs(res.req_seq);
                openCheckPlusPopup(res.sEncData, res.sMessage);
            } else {
                // setSMessage(res.sMessage);
                console.log(res.sMessage);
                // 에러 처리
            }
        },
        {
            enabled: false, // 초기에는 쿼리를 실행하지 않음
        }
    );

    // 본인인증 팝업 띄우기
    const openCheckPlusPopup = (data, message) => {
        /**/
        window.open(
            `/myinfo/PopupPage?data=${data}`,
            'popupChk',
            'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        );
        /*/
        window.open(
            ``,
            'popupChk',
            'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        );
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        form.target = 'popupChk';

        const inputM = document.createElement('input');
        inputM.type = 'hidden';
        inputM.name = 'm';
        inputM.value = 'checkplusService';

        const inputEncodeData = document.createElement('input');
        inputEncodeData.type = 'hidden';
        inputEncodeData.name = 'EncodeData';
        inputEncodeData.value = data; // 여기에 실제 데이터 입력

        form.appendChild(inputM);
        form.appendChild(inputEncodeData);

        document.body.appendChild(form);
        form.submit();
        // document.body.removeChild(form);
        /**/
    };

    //본인 인증 창에서 메세지 전달 시 실행
    useEffect(() => {
        // 메시지 이벤트 리스너 등록
        const handleMessage = (event) => {
            // 여기서 이벤트를 확인하고 데이터를 처리합니다.
            const pVal = event.data.data;
            const pType = event.data.type;
            if (pType === 'popupData') {
                const req = {
                    params: { seq: seqs, encData: decodeURIComponent(pVal) }
                };
                // console.log(req);
                authSuccesc(req);
            }
        };

        window.addEventListener('message', handleMessage);

        // 컴포넌트가 언마운트될 때 리스너 해제
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [seqs]);

    // 본인인증 성공시
    const authSuccesc = async (reqData) => {
        // refetch 메서드 호출 시, 값을 전달
        // console.log('성공 후 서버 (/callApi/success) 에 전달  => ');
        // console.log(reqData);
        const res = await api.get(`/callApi/success`, reqData);

        if (res.result_cd === 0) {
            setVerifiCheck(true);
            setAuthRes(res.dto);
            certiAfter.userNm = res.dto.name;
            certiAfter.mobile = res.dto.phone;
            certiAfter.userSNum = res.dto.birth.substring(2);

            isUserUnique.mutate(res.dto.phone);
        }
    };

    const isUserUnique = useMutation(async (mobile) => {
        const res = await api.get(`/api/v1/myinfo/isUserUnique?mobile=${mobile}`, null);
        //console.log(res);
        return res;
    }, {
        onSuccess: (res) => {
            if (res.status === 200) {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                setCloseTp(1); // 이동경로 설정
            } else {
                setCloseTp(2);
                openPop('이미 가입한 계정이 있습니다.');
            }
        },
    });

    // 추천인 유무무체크
    const recommUniqueCheck = async (pVal) => {
        const req = {
            params: { rm_cd: pVal }
        };
        // console.log(pVal);
        const res = await api.get(`/api/v1/myinfo/isRecommUnique`, req);
        // console.log(res);
        if (res.status === 200) {
            setRecommendation(true);
        } else {
            setRecommendation(false);
            // console.log('userId 길이값=> ' + certiBefore.userId.length);
        }
    }

    /******************************************************* */
    // 가입 완료
    const [isLoding, setIsLoding] = useState(0);

    const handleJoin = () => {
        if (joinCertification) {
            if (!recommendation && certiAfter.recommCd !== '') {
                openPop2('유효하지 않은 추천인 코드입니다.<br />가입 완료 후 수정할 수 없습니다. 가입을 진행하시겠어요?');
                return false;
            }
            setIsLoding(prevIsLoading => prevIsLoading + 1);
            userJoin.mutate();
        }
    }
    const userJoin = useMutation(async () => {
        const requestData = {
            pn_idx: 0,
            id: certiBefore.userId,
            pwd: certiBefore.userPw,
            u_nm: certiAfter.userNm,
            mobile: certiAfter.mobile,
            u_num: certiAfter.userSNum + certiAfter.userENum,
            b_nm: certiAfter.bankNm,
            b_num: certiAfter.bankNum,
            rm_cd: recommendation ? certiAfter.recommCd : '',
            b_cg: authRes.name, //예금주
            status: 1,
            mb_tp: 1,//회원종류
            c_val: authRes.connInfo,
            wdwal: 0,
            ac_agree: 1,
            u_agree: 1,
            mk_agree: agreMarketing ? 1 : 0,
            birth: authRes.birth,
            r_idx: 0,
            r_date: '',
        };
        // const res = '';
        // console.log(res);
        if (isLoding > 0) {
            // setIsLoding(false);
            return false;
        } else{
            const res = await api.post(`/api/v1/myinfo/userJoin`, requestData);
            return res;
        }
    }, {
        onSuccess: (res) => {
            // console.log(certiAfter.recommCd === '');
            if (res.status === 200) {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                openPop('회원가입이 완료되었습니다.');
            } else {
                setTimeout(() => {
                    setIsLoding(0);
                }, 1000);
                console.log(res.message);
            }
        },
    });

    // 포커스 상태관리
    const [isFocus, setIsFocus] = useState({
        userId: false,
        userPw: false,
        userPw2: false,
        userENum: false,
        bankNum: false,
        recommCd: false
    });

    const focusChange = (inputName, isFocused) => {
        setIsFocus(prevState => ({
            ...prevState,
            [inputName]: isFocused
        }));
    };

    return (
        // <!-- 컨텐츠 -->
        <section className="subCont join" id='join'>
            <div className="mobileBox">
                <h4>아임인 파트너스 회원가입</h4>
                {/* <!-- 1단계 --> */}
                <div className="joinStage1" style={{display: verifiCheck ? 'none' : 'block'}}>
                    <ul>
                        <li className="inputBox">
                            <p className="middleTitle">계정 정보</p>
                            <ul>
                                {/* <!-- ****(유효성 체크 외 하단 문장이 없었다면) input 유효성 검사 후 아래 멘트 출력 시 li에 .ibsInfo클래스 추가 ****  --> */}
                                <li className={'ibsInfo' + (certiBefore.userId === '' && !isFocus.userId ? '' : ' labelInput')}>
                                    <ClearInput
                                        value={certiBefore.userId}
                                        onChange={beforeField('userId')}
                                        onClear={() => fieldClean('userId', 1)}
                                        labelNm={'이메일'}
                                        maxLength={50}
                                        onFocusChange={(isFocused) => focusChange('userId', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed" style={{display: idCheck ? 'none' : 'block'}}>이미 가입되어 있는 이메일 입니다.</span>
                                    <span className="colRed" style={{display: idCheck2 ? 'none' : 'block'}}>사용할 수 없는 이메일 입니다.</span>
                                    <span className="colRed" style={{display: idCondition ? 'none' : 'block'}}>이메일 형식이 올바르지 않습니다.</span>
                                    {/* <span className="colPoint" style={{ display: (certiBefore.userId !== '' && idCheck) ? 'block' : 'none' }}>사용 가능한 이메일 입니다.</span> */}
                                    <span className="colPoint" style={{display: (idOk && idCheck) ? 'block' : 'none'}}>사용 가능한 이메일 입니다.</span>

                                </li>
                                <li className={'ibsInfo' + (certiBefore.userPw === '' && !isFocus.userPw ? '' : ' labelInput')}>
                                    <InputPw
                                        value={certiBefore.userPw}
                                        onChange={beforeField('userPw')}
                                        labelNm={'비밀번호'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('userPw', isFocused)} // 포커스 상태 전달
                                    />
                                    {/* <span>영문, 숫자, 특수문자 2자 이상 조합, 8~20자</span> */}
                                    <span className="colRed" style={{display: pwCondition ? 'none' : 'block'}}>숫자, 영문, 특수문자 중 2가지 이상 조합하여 8~20자로 입력해 주세요</span>
                                </li>

                                <li className={'ibsInfo' + (certiBefore.userPw2 === '' && !isFocus.userPw2 ? '' : ' labelInput')}>
                                    <InputPw
                                        value={certiBefore.userPw2}
                                        onChange={beforeField('userPw2')}
                                        labelNm={'비밀번호 재입력'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('userPw2', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed" style={{display: pwMatch ? 'none' : 'block'}}>비밀번호가 일치하지 않습니다.</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className="middleTitle">서비스 이용 동의</p>
                            {/* <!-- 이용동의 --> */}
                            <ul className="consent">
                                <li className="agreeAll imageCheckbox">
                                    <div className="termsBox">
                                        <img src={checkImg(agreAll)} alt="약관 전체 동의 해제됨" className="checkbox"
                                             onClick={() => checkBoxClick(1)}/>
                                        <span data-v-de1df31c="">약관 전체 동의</span>
                                    </div>
                                </li>
                                <li className="agree">
                                    <div className="termsBox">
                                        <div className="imageCheckbox">
                                            <img src={checkImg(agreService)} alt="(필수) 서비스 이용약관 동의 체크박스 해제됨"
                                                 className="checkbox" onClick={() => checkBoxClick(2)}/>
                                            <p data-v-de1df31c="">(필수) 서비스 이용약관 동의</p>
                                        </div>
                                        <a href='#' className="viewTerms" onClick={() => openTermsPop(1)}>보기</a>
                                    </div>
                                </li>
                                <li className="agree">
                                    <div className="termsBox">
                                        <div className="imageCheckbox">
                                            <img src={checkImg(agrePreivate)} alt="(필수) 개인정보 수집 및 이용 체크박스 해제됨"
                                                 className="checkbox" onClick={() => checkBoxClick(3)}/>
                                            <p>(필수) 개인정보 수집 및 이용</p>
                                        </div>
                                        <a href='#' className="viewTerms" onClick={() => openTermsPop(2)}>보기</a>
                                    </div>
                                </li>
                                <li className="agree">
                                    <div className="termsBox">
                                        <div className="imageCheckbox">
                                            <img src={checkImg(agreMarketing)} alt="(필수) 서비스 이용약관 동의 체크박스 해제됨"
                                                 className="checkbox" onClick={() => checkBoxClick(4)}/>
                                            <p>(선택) 마케팅 정보 수신</p>
                                        </div>
                                        <a href='#' className="viewTerms" onClick={() => openTermsPop(3)}>보기</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    {/* <!-- 활성화 시 btnGray > btnPoint로 변경 --> */}
                    <a href="javascript:;" className={'btn btn100 ' + (certification ? 'btnPoint' : 'btnGray')} style={{ cursor: certification ? 'pointer' : 'auto' }} onClick={verification}>본인인증</a>
                </div>
                {/* <!-- 2단계 --> */}
                <div className="joinStage2" style={{display: verifiCheck ? 'block' : 'none'}}>
                    <ul>
                        <li className="inputBox">
                            <p className="middleTitle">개인 정보</p>
                            <ul>
                                <li>
                                    <input type="text" placeholder="이름" disabled
                                           value={certiAfter.userNm} onChange={afterField('userNm')}/></li>
                                <li className="ibsInfo">
                                    <input type="text" placeholder="휴대폰 번호" disabled
                                           maxLength="11" value={CommonFunc.NonNumeric(certiAfter.mobile)}
                                           onChange={afterField('mobile')}/>
                                    <span>"-"없이 입력해주세요.</span>
                                </li>
                                <li className="ibsInfo identity">
                                    <div className="twoInput">
                                        <input type="text" placeholder="주민등록번호 앞자리" disabled
                                               maxLength="8" value={CommonFunc.NonNumeric(certiAfter.userSNum)}
                                               onChange={afterField('userSNum')}/>
                                        <div
                                            className={'ibsInfo' + (certiAfter.userENum === '' && !isFocus.userENum ? '' : ' labelInput')}>
                                            <InputPw
                                                value={CommonFunc.NonNumeric(certiAfter.userENum)}
                                                onChange={afterField('userENum')}
                                                labelNm={'주민등록번호 뒷자리'}
                                                maxLength={7}
                                                onFocusChange={(isFocused) => focusChange('userENum', isFocused)} // 포커스 상태 전달
                                            />
                                        </div>
                                        {/* <input type="password" placeholder="주민등록번호 뒷자리"
                                            maxLength="7" value={CommonFunc.NonNumeric(certiAfter.userENum)} onChange={afterField('userENum')} /> */}
                                    </div>
                                    <span>"-"없이 입력해주세요.</span>
                                    <span className="colRed" style={{display: numCheck ? 'none' : 'block'}}>올바르지 않는 주민등록번호입니다.</span>
                                </li>
                                <li><p>*수익금 지급 시 사업소득 신고용도 용도로만 사용됩니다.<br/>정확히 입력해주세요.</p></li>
                            </ul>
                        </li>
                        <li className="inputBox">
                            <p className="middleTitle">계좌 정보</p>
                            <ul>
                                <li>
                                    <select value={certiAfter.bankNm} onChange={afterField('bankNm')}>
                                        <option value="">은행 선택</option>
                                        {
                                            Array.isArray(codeData) && (
                                                codeData.length === 0 ? (
                                                    <></>
                                                ) : (
                                                    codeData.map((code, index) => (
                                                        <option key={index} value={code.cd_idx}>{code.cd_nm}</option>
                                                    )))
                                            )
                                        }
                                    </select>
                                </li>
                                <li className={'ibsInfo' + (certiAfter.bankNum === '' && !isFocus.bankNum ? '' : ' labelInput')}>
                                    <ClearInput
                                        value={CommonFunc.NonNumeric(certiAfter.bankNum)}
                                        onChange={afterField('bankNum')}
                                        onClear={() => fieldClean('bankNum', 2)}
                                        labelNm={'계좌번호 입력'}
                                        maxLength={16}
                                        onFocusChange={(isFocused) => focusChange('bankNum', isFocused)} // 포커스 상태 전달
                                    />
                                    <span>"-"없이 입력해주세요.</span>
                                </li>
                            </ul>
                        </li>
                        <li className="inputBox">
                            <p className="middleTitle">추천인 코드 (선택)</p>
                            <ul>
                                <li className={'ibsInfo' + (certiAfter.recommCd === '' && !isFocus.recommCd ? '' : ' labelInput')}>
                                    <ClearInput
                                        value={certiAfter.recommCd}
                                        onChange={afterField('recommCd')}
                                        onClear={() => fieldClean('recommCd', 2)}
                                        labelNm={'추천인 코드 입력'}
                                        onFocusChange={(isFocused) => focusChange('recommCd', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed"
                                          style={{display: certiAfter.recommCd === '' || recommendation ? 'none' : 'block'}}>유효하지 않은 추천인 코드입니다.</span>
                                </li>
                                {/* <li className="ibsInfo"><input type="text" placeholder="추천인 코드 입력"
                                    value={certiAfter.recommCd} onChange={afterField('recommCd')} />
                                </li> */}
                            </ul>
                        </li>
                    </ul>
                    {/* <!-- 활성화 시 btnGray > btnPoint로 변경 --> */}
                    <a href="javascript:;" className={'btn btn100 ' + (joinCertification ? 'btnPoint' : 'btnGray')}
                       style={{cursor: joinCertification ? 'pointer' : 'auto'}} onClick={handleJoin}>가입 완료</a>
                </div>
                <div className="stage">
                    <ul>
                        <li className={verifiCheck ? '' : 'active'}><span>1</span></li>
                        <li className={verifiCheck ? 'active' : ''}><span>2</span></li>
                    </ul>
                </div>
            </div>
            {/* <!-- 서비스 이용약관 동의 팝업 --> */}
            <div className="layerPop consentForm" style={{ display: agreementPop ? 'block' : 'none' }}>
                <div className="layerCon">
                    <div className="layerTop">
                        <div className="ltCont">
                            <p>서비스 이용 약관</p>
                            <a href="javascript:;" onClick={() => closeTermsPop(1)}><img src="/images/btn/x.svg" alt="닫기" /></a>
                        </div>
                        <div className="layerCont">
                            <div className="scrollBox policy">
                                    <select name="" id="">
                                        <option value="">2024.07.01</option>
                                    </select>
                                    <h2 style={{marginTop: 0}}>제1조 목적</h2>
                                    <p>
                                        본 이용약관(이하 “약관”이라 합니다)는 (주)티웨이브(이하 “회사”)라 합니다)가 제공하는 성과형 광고 플랫폼 아임인 파트너스(<a
                                        href="https://iminpartners.kr/">iminpartners.kr</a>)의 이용과 관련하여 회사와 회원 사이의 권리ᆞ의무, 책임사항, 서비스 이용절차 및 그 밖의
                                        제반 사항을 명확히 하여 “회원”과 “회사”의 간에 필요한 사항을 규정함을 목적으로 합니다.
                                    </p>
                                    <h2 className="title">제2조 용어의 정의</h2>
                                    <ol className="top-level">
                                        <li>이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 이 약관에서 정의되지 않은 용어는 일반 거래 관행에 따라 정의된 의미를 가집니다.
                                            <ol className="nested">
                                                <li>
                                                    아임인 파트너스 서비스(이하 “아임인 파트너스” 또는 “서비스”라고 합니다)란 회사가 제공하는 서비스 링크를 제3자에게 전달하거나, 회원이 운영하는 미디어에
                                                    게재하여 방문자의 관심에 의해 발생한 클릭을 통해 회사가 얻은 수익을 회사와 회원이 일정한 비율로 홍보에 대한 수수료를 제공하는 서비스를 의미합니다.
                                                </li>
                                                <li>
                                                    “회원”이란 회사가 제공하는 서비스 링크를 제3자에게 전달하거나 자신의 미디어에 게재하고, 아임인 파트너스 내 공유하기 기능을 통해 그 성과에 대한 수익을
                                                    취할 목적으로 이 약관에 동의하고 서비스 가입자로 승인을 받은 자를 의미합니다. 회원은 성과에 대해 회사로부터 약정된 대가를 받게 됩니다.
                                                </li>
                                                <li>“추천인”이란 아임인 파트너스 서비스에 가입 후 이용하고 있는 “기존 회원”을 의미합니다.</li>
                                                <li>“피추천인”이란 함은 “추천인”의 초대 또는 공유 링크에 응답하여 아임인 파트너스에 가입한 “신규 회원”을 의미합니다.</li>
                                                <li>“미디어”란 이 약관에 따라 회사의 광고를 게재하는 매체로 회원이 운영하는 SNS, 블로그, 웹사이트 등을 의미합니다.</li>
                                                <li>“광고매체”란 “회원”가 직접 또는 “플랫폼”을 통하여 제공하는 “광고소재”가 게재되는 영역을 의미하며, PC, 모바일 웹사이트 및 모바일 어플리케이션 등을
                                                    포함합니다.
                                                </li>
                                                <li>“광고소재”란 “회원”이 “회사”의 승인을 받아 광고매체에 게재되는 텍스트, 이미지, 동영상 형태의 제작물 및 클릭 후 연결되는 연결 페이지 주소 등
                                                    “회사”의 광고 내용을 의미합니다.
                                                </li>
                                                <li>“광고매체 이용자”라 함은 광고매체를 이용하는 이용자를 의미하며, 광고매체의 회원 또는 “비회원 모두를 포함합니다.</li>
                                                <li>“수익”이란 회사의 광고를 회원의 미디어에 게재하여 매출이 발생했을 때 회사가 회원에게 지급하게 되는 금액을 의미하며 정산이 완료된 ‘수수료’와 동일한 의미를
                                                    갖습니다.
                                                </li>
                                                <li>“부당 수익”이란 회원이 약관, 운영정책, 또는 관련 법령 등을 위반하거나 방문자가 약관, 운영정책 또는 관련법령에서 금지한 행위를 하여 회원에게 발생한
                                                    수익을 의미합니다.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>본 약관에서 사용하는 용어 중 본 약관에서 정하지 않은 부분은 “회사”와 “회원”간 체결한 서비스 이용약관 및 관계법령에 따릅니다.</li>
                                    </ol>
                                    <h2 className="title">제3조 약관의 명시, 효력 및 개정</h2>
                                    <ol className="top-level">
                                        <li>회사는 약관의 내용을 회원이 쉽게 알 수 있도록 “아임인 파트너스” 초기 화면 또는 연결화면을 통하여 본 약관을 게시합니다.</li>
                                        <li>회사는 이 약관에서 규정되지 않은 세부적인 내용은 별도의 “아임인 파트너스 운영 정책(이하 “운영정책”이라 합니다)으로 규정할 수 있고, 이를 서비스의 관련 화면 등을
                                            통하여 공지하기로 합니다. 또한, 이러한 운영정책은 이 약관의 일부를 구성하며, 이 약관과 동일한 효력이 있습니다.
                                        </li>
                                        <li>회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관(또는 운영정책, 이하 동일함)을 개정할 수 있습니다.</li>
                                        <li>회사가 약관을 개정하는 경우 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관 적용 일자 7일 전 부터 적용일자 전일까지 공지합니다. 다만,
                                            회원에게 불리하게 개정되는 경우 또는 회원에게 중대한 영향을 미칠 수 있는 개정인 경우에는 적용일자 30일 전부터 공지합니다.
                                        </li>
                                        <li>회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
                                            회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                                        </li>
                                        <li>회원은 개정약관에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 회사에 거부 의사를 표시하고 회원탈퇴를 할 수 있습니다. 회원이 거부
                                            의사를 표시한 후 상당한 기간 내에 회원 탈퇴를 하지 않을 경우 회사는 직권 해지 조치를 할 수 있습니다.
                                        </li>
                                    </ol>
                                    <h2 className="title">제4조 약관의 해석</h2>
                                    <ol className="top-level">
                                        <li>회사는 본 약관에 규정되지 않은 세부적인 내용에 대하여 회사가 수시로 개정할 수 있는 아임인 파트너스 운영정책(이하 “운영정책”이라 합니다)을 제정하여 운영할 수 있으며,
                                            해당 내용을 아임인 파트너스 초기화면 또는 연결화면을 통하여 게시하거나 회원에게 사전 안내합니다.<br/> 이때, 해당 운영정책은 이 약관의 일부를 구성하며, 이 약관과 같은 효력이
                                            있습니다. 만약, 운영정책과 이 약관의 내용이 충돌할 때는 이 약관이 우선합니다.
                                        </li>
                                        <li>회사는 필요할 경우 본 약관 이외에 개별약관 또는 추가 약관(이하 “개별약관 등”이라 합니다)을 별도로 제정할 수 있으며, 회원이 개별약관 등에 동의한 경우, 개별약관 등과
                                            본 약관에 상충하는 내용이 있을 때 개별약관 등이 우선으로 적용됩니다.
                                        </li>
                                        <li>이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따릅니다.</li>
                                        <li>본 이용약관에 명시된 한 개 또는 수개의 조항이 법령에 따라 무효로 되는 경우에도 본 이용약관에 명시된 나머지 조항의 효력은 여전히 유효합니다.</li>
                                    </ol>

                                    <h2 className="title">제5조 회원가입</h2>
                                    <ol className="top-level">
                                        <li>회원가입은 서비스를 이용하고자 하는 자가 본 약관의 내용에 동의한 다음, 회사가 정한 절차에 따라 가입 신청을 하고 회사가 이를 승낙함으로써 이루어집니다.</li>
                                        <li>회사는 다음 각호의 사유가 있는 경우에는 가입 승낙을 하지 않을 수 있으며, 가입 이후에도 다음 각호의 사유가 확인될 경우에는 “아임인 파트너스” 이용을 제한하거나 직권
                                            해지 조치를 취할 수 있습니다.
                                            <ol className="nested">
                                                <li>회원 가입을 신청한 자가 본 약관에 의하여 회원 자격을 상실한 적이 있는 경우</li>
                                                <li>본 약관 위반 등의 사유로 “아임인 파트너스” 이용 제한 중에 회원 탈퇴 후 재가입 신청을 하는 경우</li>
                                                <li>실명이 아닌 명의 또는 타인의 명의를 기재한 경우</li>
                                                <li>허위 또는 잘못된 정보를 기재 또는 제공하거나 회사가 정한 신청 요건이 미비한 경우</li>
                                                <li>회사가 제시한 회원가입 승인 요건을 충족하지 않았거나, 위법 또는 부당한 회원가입 신청임이 확인된 경우</li>
                                                <li>만 19세 미만의 자가 가입 신청을 하는 경우</li>
                                                <li>기술적 지장 등으로 이용 신청에 대한 승낙이 어려운 경우</li>
                                                <li>기타 회사가 합리적인 판단에 의하여 “아임인 파트너스” 이용 제한이 필요하다고 인정하는 경우</li>
                                                <li>회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이 있는 경우 등</li>
                                            </ol>
                                        </li>
                                        <li>회원은 회원가입에 필요한 정보를 회사에 제공하는데 동의합니다.</li>
                                        <li>회사는 제1항에 따른 회원가입 신청에 대하여 회원가입 신청 절차 중 실명 확인 또는 본인인증을 요청할 수 있습니다.</li>
                                        <li>회원 가입의 성립 시기는 회사가 회원 가입 완료 사실을 신청 절차 상에 표시한 시점으로 합니다.</li>
                                    </ol>

                                    <h2 className="title">제6조 회원 계정 관리</h2>
                                    <ol className="top-level">
                                        <li>회원 계정의 아이디와 비밀번호에 대한 관리 책임은 회원에게 있으며, 회원은 이를 제삼자가 이용하도록 하여서는 안됩니다.<br/> 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에
                                            대한 책임은 회원에게 있습니다.
                                        </li>
                                        <li>회원은 아이디 또는 비밀번호가 도용되거나 제삼자가 자신의 회원 계정을 이용하고 있음을 인지한 경우에는, 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</li>
                                        <li>회원은 가입신청 시 기재한 회원정보에 변경이 있는 경우, “서비스”에 접속하여 직접 수정하는 등의 방법으로 그 변경 사항을 반영하거나, 고객센터를 통하여 회사에 변경 사항을
                                            즉시 통지하여야 합니다.
                                        </li>
                                        <li>회원이 본 조의 내용을 준수하지 않거나 통지를 지연함으로써 발생한 회원의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.</li>
                                    </ol>

                                    <h2 className="title">제7조 수익 정산 및 지급</h2>
                                    <ol className="top-level">
                                        <li>회사는 서비스를 통하여 측정된 실적과 그에 따라 별도로 정한 수수료의 종류와 배분 기준 및 운영정책에 따라 산정하고, 최종 확정된 수익을 회원에게 지급합니다.</li>
                                        <li>수익 지급은 1개월 단위를 원칙으로 하며, 수익 발생 월의 1일부터 해당월 말일까지의 수익을 ‘지정 지급일’에 일괄 지급합니다.</li>
                                        <li>지급의 최저 금액은 2,000원이며, 월간 수익이 2,000원을 넘지 않을 경우에 정산 금액은 이월처리 됩니다. <br/>금액 미달로 인한 이월 가능 기간은 1개월 단위로 총
                                            5번까지 자동으로 이월되며, 그 이후에는 6개월간 적립된 금액이 일괄 지급됩니다.
                                        </li>
                                        <li>회원이 사업자가 아닌 경우 회사는 수익 지급 금액에 따라 발생하는 원천징수소득세 등을 수익에서 공제하고 지급할 수 있습니다.</li>
                                        <li>지급 과정에서 회원의 은행계좌정보 등이 유효하지 않아 수익을 지급받지 못하는 경우 귀책사유는 회원에게 있으며 회사는 이를 보상하지 않습니다.</li>
                                        <li>전항의 사유로 수익이 지급되지 않은 경우 회사는 제12조에 따라 회원에게 통지하며, 회사는 회원이 은행계좌정보를 수정할 때까지 최대 6개월까지 수익의 지급이 보류됩니다.
                                        </li>
                                        <li>회사는 수익 지급 및 세무처리를 위하여 개인식별정보의 수집 동의, 세금계산서 등 조세 관련법령에서 규정하는 세무적 증빙의 발행을 요청할 수 있으며, 회원이 개인식별정보수집에
                                            동의하지 않거나 회원이 제공한 정보가 유효하지 않은 경우 회사는 수익의 지급을 보류할 수 있습니다.
                                        </li>
                                        <li>회원은 수익 잔액이 0원 미만이 되는 경우, 해당 금액을 회사에 변제하여야 합니다.</li>
                                        <li>회원 또는 회사가 이 약관 해지 의사를 표시한 경우, 회사는 해지 의사를 표시한 날까지의 수익(이하 “잔여수익”이라고 합니다)을 확인하여, 잔여수익이 회사가 정한 지급
                                            기준액 이상이 되는 경우 회원에게 지급합니다. 구체적인 지급 방법 및 시기는 운영정책에서 정합니다.
                                        </li>
                                        <li>이용 제한 상태에 있는 회원에게는 수익의 지급이 제한될 수 있습니다.</li>
                                        <li>그 외 구체적인 기준은 운영정책에서 정합니다.</li>
                                    </ol>

                                    <h2 className="title">제8조 수익 지급 보류</h2>
                                    <ol className="top-level">

                                        <li>회사는 회원에게 다음 각 호의 사유가 발생할 경우 사유가 해소될 때까지 회원에 대한 수익 지급을 보류할 수 있습니다. 이때, 회사는 해당 사유 발생 사실을 지체없이 회원에게
                                            통지합니다.
                                            <ol className="nested">
                                                <li>수익금 지급 과정에서 증빙 서류 불충분 및 회원이 제공한 정보가 유효하지 않은 경우</li>
                                                <li>이용약관의 규정을 위반하거나 위반한 것으로 의심될 만한 타당한 정황으로 발생한 수익이 부당 수익인 것으로 의심되는 경우</li>
                                                <li>회원에 의한 부정거래가 의심되는 경우</li>
                                                <li>회원의 채권자의 신청에 의한 수익금의 가압류 또는 압류 등 법원의 결정이 있을 경우</li>
                                                <li>기타 법령의 규정에 의하거나 합리적인 사유가 있는 경우</li>
                                            </ol>
                                        </li>
                                        <li>지급 보류된 수익금을 청구하고자 할 경우, 회원은 지급 보류 사유가 해소되었음을 확인하기 위하여 회사가 요구하는 서류를 서면으로 제출하여야 합니다.</li>
                                        <li>전항의 수익 지급 보류 기간은 정산일로부터 최대 180일로 하며, 회사의 시정 요구에도 해당 기한 이내에 지급 보류 사유가 해소되지 않는 경우, 해당 수익은 소멸하고 회사는
                                            수익 지급 의무를 면합니다.
                                        </li>
                                        <li>회사는 회사의 고의 또는 과실이 없는 한 본 조에 따른 정산 또는 지급 보류 시 지연에 따른 지체 책임을 부담하지 아니합니다.</li>
                                        <li>회사는 회원에게 가지는 채권을 회원의 정산금 채권과 상계할 수 있습니다. 이때, 상계에 관하여는 민법 등 관련법령이 정하는 바에 따릅니다.</li>
                                    </ol>

                                    <h2 className="title">제9조 수익의 소멸</h2>
                                    <ol className="top-level">
                                        <li>수익은 최초 적립된 시점부터 5개월이 경과한 월의 1일에 먼저 적립된 수익부터 월 단위로 자동 소멸됩니다.</li>
                                        <li>수익은 회사가 정한 유효기간 내에서 서비스 이용 시 뿐 만이 아니라, 미이용(회원의 유효한 계정이 있어 회원자격이 유지되고 있는 상태)시에도 유지되며, 만기일 경과 시 자동
                                            소멸됩니다.
                                        </li>
                                        <li>회사는 회원이 적립한 수수료를 소멸예정일이 도래하여 소멸시키는 경우 수익 소멸과 관련된 내용을 최소 1개월 전에 회사가 지정한 방법(문자메세지, e-mail 등)으로 1회
                                            이상 안내합니다. 단, 특정 이벤트를 통해 지급된 수수료는 이벤트 진행 시 수수료 유효기간 및 소멸일을 별도로 안내하고 소멸 안내 내용에서는 제외될 수 있습니다.
                                        </li>
                                        <li>회원의 활동으로 인해 적립되는 수익이 아닌 회사나 제휴사가 이벤트 등의 마케팅 활동으로 회원에게 추가로 제공한 수수료의 유효기간은 회사나 제휴사의 정책에 따라 별도로 책정될
                                            수 있으며 이는 별도로 공지합니다.
                                        </li>
                                        <li>회사는 회원이 탈퇴나 개인정보 삭제를 요청한 경우 탈퇴 또는 삭제 전 잔여 수익에 대한 정보를 안내하여야 합니다.</li>
                                        <li>본 약관 회원은 제22조 1항에 정해진 방법으로 탈퇴를 하고자 하는 회원이 잔여 수익을 지급받고자 할 경우 회원탈퇴 요청일 전까지 적립된 수익을 본 약관 및 운영정책이
                                            정하는 바에 따라 지급받아야 합니다.
                                        </li>
                                        <li>탈퇴 전까지 지급받지 않은 수익은 제22조 1항에 의거 즉시 자동으로 소멸됩니다.</li>
                                    </ol>

                                    <h2 className="title">제10조 부당 수익의 계산</h2>
                                    <ol className="top-level">
                                        <li>회사는 신고 등에 의해 회원에 대한 수익이 부당 수익인 것으로 의심될 경우 운영 정책에서 정한 절차에 따라 조사, 자료 요청, 지급 보류 등의 조치를 취할 수 있습니다.
                                        </li>
                                        <li>회사에 의해 부당 수익 확인을 위한 조사가 진행되는 경우 회원은 회사의 조사에 협조하여야 합니다.</li>
                                        <li>회사는 회원에 대한 수익이 부당 수익인 것으로 확인된 경우 해당 수익 지급을 거절할 수 있으며 이미 지급된 경우에는 해당 부당 수익 대한 반환을 청구할 수 있습니다. 회원은
                                            회사로부터 해당 청구를 받은 경우 지체없이 이를 반환하여야 합니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제11조 개인정보의 보호</h2>
                                    <p>
                                        회사는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및
                                        사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.
                                    </p>

                                    <h2 className="title">제12조 아이디 및 비밀번호의 관리에 대한 의무</h2>
                                    <ol className="top-level">
                                        <li>아이디와 비밀번호에 대한 모든 관리 책임은 회원에게 있으며, 회원은 이를 제3자가 이용하도록 하여서는 안됩니다.</li>
                                        <li>아이디와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.</li>
                                        <li>회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</li>
                                        <li>회원이 전항을 준수하지 않아 발생하는 회원의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.</li>
                                    </ol>

                                    <h2 className="title">제13조 회원에 대한 통지</h2>
                                    <ol className="top-level">
                                        <li>회사는 회원에 대하여 통지하는 경우 본 약관에 별도의 규정이 없는 한 “회원”이 제공한 전자우편주소, (휴대)전화번호, 카카오톡 메시지, “아임인 파트너스”의 로그인 시
                                            알림창 등의 수단으로 할 수 있습니다.
                                        </li>
                                        <li>회사는 회원 전체 또는 불특정 다수의 “회원”에 대하여 통지를 하는 경우 7일 이상 “서비스” 내 공지사항 게시판에 게시함으로써 제1항의 통지를 갈음할 수 있습니다.
                                        </li>
                                        <li>회원은 회사에 실제로 연락이 가능한 전자우편주소 및 (휴대)전화번호 등의 정보를 제공하고 이를 최신의 상태로 유지하여야 하며 “회사”의 통지를 확인하여야 합니다. 회원이
                                            이러한 의무를 소홀히 하여 발생한 불이익에 대하여는 보호 받지 못합니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제14조 회사의 의무</h2>
                                    <ol className="top-level">
                                        <li>회사는 본 약관 및 회사가 별도로 고지한 이용정책에 따른 기준 및 지급 절차를 준수하여 수익을 회원에게 지급합니다.</li>
                                        <li>회사는 관련법령과 본 약관을 준수하여 계속적이고 안정적으로 “아임인 파트너스” 서비스를 제공하기 위하여 최선을 다하여 노력합니다.</li>
                                        <li>회사는 회원이 안전하게 “아임인 파트너스”를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위하여 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
                                        </li>
                                        <li>회사는 회원의 관련법령, 본 약관 및 운영 정책 등의 준수 여부를 상식적으로 모니터링할 수 있으며, 광고매체 이용자 또는 제3자 등의 의견이나 불만이 제기된 경우
                                            “회원”에게 이에 대한 소명을 요청하는 등의 조치를 취할 수 있습니다.
                                        </li>
                                        <li>회사는 서비스의 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정될 경우 이를 신속하게 처리하여야 하며, “서비스” 내 게시판 또는 회원이 제공한
                                            전자우편주소 등을 통하여 “회원”에게 처리과정 및 결과를 전달할 수 있습니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제15조 회원의 의무</h2>
                                    <ol className="top-level">
                                        <li>회원은 관련 법령, 본 약관의 규정, 회사의 운영 정책 및 회사가 공지하거나 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안
                                            됩니다.
                                        </li>
                                        <li>회원은 계정을 “아임인 파트너스” 서비스를 정상적으로 이용하기 위한 용도(회원정보 관리, 광고 소재 관리, 수익 정산 등)로만 이용하여야 합니다.</li>
                                        <li>회원은 회사의 사전 동의 없이 본 약관상의 권리, 의무를 제3자에게 양도, 이전, 담보제공 등 일체의 처분행위를 할 수 없습니다.</li>
                                        <li>부당하게 서비스, 광고 또는 광고매체에 위해가 되는 제3자의 행위를 인지하는 경우 즉시 해당 사실을 회사에 알리고 만약 해당 제삼자와의 거래 관계가 있는 경우에는 이를 즉시
                                            중단하여야 합니다.
                                        </li>
                                        <li>회원은 어떠한 경우에도 회사의 사전 승낙 없이 다음 각 호의 행위를 하는 에이전트(agent), 로봇(robot), 스크립트(script), 스파이더(spider),
                                            스파이웨어(spyware) 등의 수단을 제작, 배포, 설치하거나 이를 유도하여서는 안 되며, 기타 불법부정한 방법 등을 통해 다음 각 호의 행위를 하거나 그러한 시도를
                                            해서는 안 됩니다.
                                            <ol className="nested">
                                                <li>회사가 제공하지 않은 방법으로 “아임인”에 접속하거나 “아임인 파트너스” 서비스를 이용하는 행위</li>
                                                <li>부정한 방법을 사용하여 광고를 노출하거나 클릭을 반복하여 회사에 손해를 끼치거나 자신 또는 제삼자에게 이익을 주는 행위, 또는 타인에게 그러한 행위를 유도하는
                                                    행위
                                                </li>
                                                <li>회사의 서버 또는 설비에 과도한 부하를 야기하는 행위</li>
                                                <li>광고 노출 수 및 클릭 수 등을 부정하게 생성하거나 조절하는 행위</li>
                                                <li>기타 “아임인” 또는 “아임인 파트너스” 서비스의 정상적 운영을 방해하는 행위</li>
                                            </ol>
                                        </li>
                                        <li>회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
                                            <ol className="nested">
                                                <li>회원가입을 신청하거나 회원정보 변경 신청 시 허위 또는 타인의 정보를 입력하는 행위</li>
                                                <li>회사 또는 제3자의 저작권 등 지식재산권을 침해하는 행위</li>
                                                <li>회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                                                <li>회사 또는 제3자를 가장 또는 사칭하여 허가 받지 않은 게시판, 스팸메일 등의 활용으로 회사의 명성에 해를 끼치는 행위</li>
                                                <li>회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보, 공개되지 말아야 할 기밀정보(클릭 수, CTR 등)를 회사의 사전 서면 승낙 없이 영리
                                                    또는 비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위
                                                </li>
                                                <li>광고의 게재를 유도하기 위해 미디어의 콘텐츠 내용과 관계없이 무의미한 키워드를 반복하여 사용하는 행위</li>
                                                <li>계정을 매매, 양도, 명의변경, 재 판매하거나 질권의 목적으로 사용하는 행위</li>
                                                <li>서비스 이용 권한을 타인에게 공유, 제공, 양도, 중개, 재 판매하는 행위</li>
                                                <li>타인의 계정으로 서비스를 이용하는 행위</li>
                                                <li>서비스 내에 게재된 광고의 내용, 링크, 순서 및 이에 포함된 정보 등을 변경, 조작하는 등의 행위.</li>
                                                <li>회원이 회사와 약속한 방법 이외의 부정한 수익을 추구하여 회사에 고의적인 손실을 입히는 행위.</li>
                                                <li>본 서비스 제공 취지에 반하여 방문자의 관심이 아닌 회원이 발생시킨 광고 클릭이나 본인 및 가족 구매를 통해 수익을 발생시키는 행위</li>
                                                <li>“아임인”이 그 권리를 보유하고 있는 표장 또는 이와 혼동을 일으킬 정도로 유사한 단어와 동일하거나 유사한 상표를 “아임인”과 오인, 혼동을 일으키는 방식으로
                                                    상표/도메인으로써 사용하는 행위
                                                </li>
                                                <li>회원이 미디어에 사실과 다르거나, 과장∙왜곡∙오인의 가능성이 있는 내용을 표시하는 등 표시광고법 기타 관련 법령에 위반되는 내용을 게시하는 행위</li>
                                                <li>회원이 제3자 컨텐츠 등에 해당 컨텐츠의 의도와 상관없는 내용이나 회사의 이미지/사업 등에 손실을 끼치는 내용 등의 댓글을 게시하는 행위</li>
                                                <li>해당 게시물과 관계없는 브랜드 및 제품명 등 제품정보를 언급하여 제3자에게 피해를 끼치거나 끼칠 우려가 있는 행위</li>
                                                <li>“아임인” 또는 “아임인 파트너스”에 공식적으로 공개되지 않은 프로모션 등을 광고하는 행위</li>
                                                <li>서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보를 전송하는 행위</li>
                                                <li>“아임인 파트너스”의 이용 목적 및 기타 서비스의 운영정책에 위배되는 모든 행위</li>
                                                <li>기타 회사의 업무를 방해하는 부당한 행위</li>
                                            </ol>
                                        </li>
                                        <li>회원은 “아임인 파트너스” 서비스 제공 또는 본 조 위반 여부를 확인하기 위하여 회사가 자료 또는 접근권한의 제공 및 관련사실에 대한 소명을 요청하는 경우 이에 성실히
                                            임하여야 합니다.
                                        </li>
                                        <li>회원이 회사의 귀책 없이 관련 법령, 본 약관 또는 운영정책 등을 위반하여 제3자가 회사를 상대로 이의를 제기하거나, 민형사상의 소송 또는 행정 처분 등이 발생하는 경우
                                            회원은 본인의 책임과 비용으로 이를 해결하여야 하며, 회사를 면책시켜야 합니다. 이와 관련하여 회사의 귀책사유 없이 회사에 손해가 발생한 경우 손해 전부를 배상합니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제16조 서비스의 제공 등</h2>
                                    <ol className="top-level">
                                        <li>회사”는 “회원”에게 아래와 같은 서비스를 제공합니다.
                                            <ol className="nested">
                                                <li>홍보 컨텐츠 제공</li>
                                                <li>수익 관련 리포트 서비스</li>
                                                <li>게시판형 서비스(이용문의, 공지사항 등)</li>
                                                <li>기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스</li>
                                            </ol>
                                        </li>
                                        <li>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</li>
                                        <li>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
                                        <li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. <br/>이 경우 회사는
                                            제12조 ‘회원에 대한 통지’에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                                        </li>
                                        <li>회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.</li>
                                    </ol>

                                    <h2 className="title">제17조 서비스의 변경</h2>
                                    <ol className="top-level">
                                        <li>회사는 안정적인 서비스의 제공을 위하여 서비스의 내용, 운영상 또는 기술상 사항을 변경할 수 있습니다.</li>
                                        <li>회사는 서비스 내용을 변경할 경우 변경 내용과 적용 일자를 명시하여 “아임인 파트너스”에 사전 공지합니다. <br/>다만, 회원의 권리나 의무 및 “아임인 파트너스” 이용과 관련되는
                                            실질적인 사항을 변경할 경우 적용 일자 7일 이전에, 회원에게 불리한 변경의 경우 적용 일자 30일 이전에 사전 공지합니다.
                                        </li>
                                        <li>회사는 서비스의 품질 향상 및 효과 증대를 위하여 회원에 대한 별도의 공지 또는 통지 없이 서비스의 일부 또는 전부의 기능 변경, 개선에 대한 테스트를 실시할 수
                                            있습니다.
                                        </li>
                                        <li>회사는 제1항 내지 제3항에 따른 서비스의 변경이나 테스트 등으로 발생한 회원의 손해에 대하여 고의 또는 중과실이 없는 한 책임을 지지 않습니다.</li>
                                        <li>회원”은 제1항의 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 회원 탈퇴를 할 수 있습니다. 회원이 거부 의사를 표시한 후 상당한 기간 내에 회원 탈퇴를
                                            하지 않을 경우 회사는 직권 해지 조치를 할 수 있습니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제18조 서비스의 중단</h2>
                                    <ol className="top-level">
                                        <li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체, 고장, 시스템 장애, 통신두절 또는 기술상, 운영상, 사업상 합리적인 사유가 있는 경우 “아임인 파트너스”의 전부 또는
                                            일부의 서비스 제공을 중단할 수 있습니다.
                                        </li>
                                        <li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.</li>
                                        <li>제1,2항의 경우 회사는 회원에게 사전 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 사후에 통지할 수 있습니다.</li>
                                        <li>본 조에 따른 “아임인 파트너스” 또는 “아임인” 서비스 중단으로 인하여 발생한 회원의 손해에 대하여 회사는 고의 또는 과실이 없는 한 책임을 부담하지 않습니다.</li>
                                    </ol>

                                    <h2 className="title">제19조 회원의 게시물에 대한 권리 및 의무</h2>
                                    <ol className="top-level">
                                        <li>회원은 자신이 운영하는 미디어 및 게재한 게시물의 내용이 회사 또는 제삼자의 권리에 대한 침해나 지식재산권 및 관련 법령에 대한 위반이 되지 않음을 보증하여야 합니다.
                                        </li>
                                        <li>회원이 본 조 제1항을 위반하거나 회사가 규정한 게시물 또는 광고물 규격을 지키지 않는 등이 이 약관을 위반(운영정책에서 정한 제한행위 모두 포함)하는 경우, 회사는
                                            회원에게 이에 대한 시정 요구 등을 할 수 있으며, 회원이 이를 거부하는 경우 회사는 서비스 이용 제한, 회원 자격상실 등의 조치를 임의로 취할 수 있습니다.
                                        </li>
                                        <li>회원이 본 조를 위반하여 제삼자의 권리를 침해하거나 지식재산권 및 관련 법령을 위반하였다는 이유로 회사가 금지 청구, 손해배상청구, 고소 등 법적 조치를 당하면 자기
                                            비용으로 회사를 면책 시켜야 합니다. 이와 관련하여 회사가 제삼자에게 손해배상책임을 부담하는 경우에는 회사의 손해배상액 및 그 배상과 관련된 부대비용 등(이자 또는 변호사
                                            보수 등의 방어비용 포함)을 회원이 전적으로 부담합니다.
                                        </li>
                                        <li>회원은 자신이 운영하는 광고매체 및 미디어에 서비스 추천의 내용이 포함된 게시물을 게재할 경우, 공정거래위원회의 ‘추천보증 등에 관한 표시·광고 심사지침’에 따라
                                            추천·보증인인 회원과 회사의 경제적 이해관계에 대하여 공개하는 등 관련 법령 및 행정청의 가이드에 따른 의무를 준수하여야 합니다.
                                        </li>
                                        <li>본조에도 불구하고 회원은 지식재산권 침해 등 이용약관 및 운영정책을 위반하는 콘텐츠가 포함된 게시물이 발견되는 즉시 책임지고 삭제하여야 합니다.</li>
                                    </ol>

                                    <h2 className="title">제20조 실적 측정</h2>
                                    <ol className="top-level">
                                        <li>회원의 미디어를 통해 발생하는 모든 실적 집계는 회사에서 제공되는 실적 측정을 기준으로 합니다.</li>
                                        <li>회원은 서비스 화면을 통하여 실적을 확인할 수 있으며, 회원은 이에 대해 이의를 제기할 수 없습니다.</li>
                                    </ol>
                                    <h2 className="title">제21조 데이터의 수집 및 이용</h2>
                                    <ol className="top-level">
                                        <li>회사는 서비스를 제공함에 있어 비정상적인 방법의 광고 여부 판단 등을 위하여 OS와 디바이스의 정보를 수집할 수 있습니다. <br/> 회사는 수집하는 데이터의 주요 항목에 대해서는
                                            운영 정책에서 정합니다.
                                        </li>
                                        <li>회사는 전항에 따라 수집한 정보를 회사가 제공하는 서비스 등을 위하여 이용할 수 있으며, 관련 법령에 따라 제삼자에게 제공할 수 있습니다.</li>
                                    </ol>

                                    <h2 className="title">제22조 이용제한</h2>
                                    <ol className="top-level">
                                        <li>회사는 다음 각호에 해당하는 사유가 발생하거나 확인되면 회원의 서비스 이용을 사전에 제한하거나, 수익금 지급 중지, 회원자격 상실 등 단계적으로 이용을 제한할 수 있습니다.
                                            <ol className="nested">
                                                <li>회원이 관련 법령, 이 약관 또는 운영정책상 의무를 위반하거나 기타 서비스의 정상적인 운영을 방해하는 경우</li>
                                                <li>회원이 등록한 광고매체에 등록한 광고소재가 각 목에 해당하는 경우
                                                    <ul className="nested">
                                                        <li>회사 또는 광고매체에 법률적 또는 재산적 위험을 발생시키거나 발생시킬 우려가 있는 경우</li>
                                                        <li>회사 또는 광고매체의 명예, 평판, 신용이나 신뢰도를 훼손하거나 훼손할 우려가 있는 경우</li>
                                                        <li>광고매체 또는 광고매체 이용자가 광고 게재를 합리적인 사유로 거부하거나 광고 게재에 합리적인 이의를 제기하여 “회사”가 이를 인정하는 경우
                                                        </li>
                                                        <li>광고소재의 내용이 사실과 다르거나, 광고매체 이용자를 오인혼동하게 하거나 그러한 우려가 있는 경우</li>
                                                        <li>“아임인 파트너스” 또는 광고매체의 품질을 저하하거나 저하시킬 우려가 있는 경우</li>
                                                        <li>광고소재가 지식재산권, 초상권, 개인정보 등 타인의 권리를 침해하거나 그러한 가능성이 있는 경우</li>
                                                        <li>광고소재가 사회 또는 정치적 논란을 주제로 하는 경우</li>
                                                        <li>광고소재가 공공질서 및 미풍양속에 어긋나거나 그러한 가능성이 있는 경우</li>
                                                        <li>광고소재에 청소년 또는 일반 국민의 정서를 저해할 우려가 있는 정보, 문장, 도형, 이미지, 영상 등이 포함된 경우</li>
                                                        <li>광고소재가 과도한 선정성, 폭력성, 공포 및 혐오 감정 등을 유발하는 경우</li>
                                                        <li>광고소재가 다른 회원 또는 제삼자를 비방하는 내용이거나, 타인의 명예를 훼손할 가능성이 있는 경우</li>
                                                        <li>광고소재가 관련 법령 또는 회사의 약관, 운영 정책 및 회사가 공지하거나 통지한 사항 등에 위반되는 경우</li>
                                                    </ul>
                                                </li>
                                                <li>회원의 “아임인 파트너스” 이용과 관련하여 회사 또는 회원이 행정기관 기타 정부 기관으로부터 경고, 고발 등의 조치를 당하는 경우</li>
                                                <li>회원의 “아임인 파트너스” 이용과 관련하여 회사 또는 회원과 제삼자 사이에 분쟁이 발생하는 경우</li>
                                                <li>제5조 제2항에 따른 승낙 제한 사유가 있는 것을 사후적으로 발견한 경우</li>
                                            </ol>
                                        </li>
                                        <li>회사가 본 조에 따라 회원의 “아임인 파트너스” 이용을 제한하는 경우 제10조에서 정하는 방법으로 “회원”에게 통지합니다. <br/> 이 경우 “회사”는 원칙적으로 이용 제한 전에
                                            회원에게 일정한 기간을 정하여 이의신청을 할 수 있도록 합니다.
                                        </li>
                                        <li>제2항에도 불구하고 관련 법령, 본 약관 또는 “아임인 파트너스” 및 회사가 공지하거나 통지한 사항 등에서 별도로 정하는 사유에 해당하여 이용이 제한되는 경우 회사는 별도의
                                            이의신청 기간을 부여하지 않거나, 이용 제한 조치 이후에 이의신청 기간을 부여할 수 있습니다.
                                        </li>
                                        <li>회원은 본 조에 따른 이용 제한에 대하여 회사가 정하는 절차에 따라 이의신청을 할 수 있습니다. <br/>회사는 회원의 이의가 정당하다고 인정되면 즉시 이용 제한 조치 등을
                                            해제합니다.
                                        </li>
                                        <li>회사는 회원이 본 조에 따른 이용 제한 조치를 받은 경우, 회원이 이용하는 회사의 다른 서비스에 대하여도 이용을 제한하거나 이용계약을 해지할 수 있습니다.</li>
                                        <li>회사는 회원의 관련 법령, 본 약관 또는 운영정책 등의 위반사항이 심각한 것으로 판단되는 경우, 회원 탈퇴 처리 및 향후 회원의 재가입 신청 승인을 거부할 수 있습니다.
                                            이용 제한의 구체적인 기준 등에 대하여는 운영정책에서 정합니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제23조 해지(탈퇴)</h2>
                                    <ol className="top-level">
                                        <li>회원은 언제든지 직접 서비스에 접속하여 이 약관의 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다. <br/> 다만, 탈퇴 신청
                                            시점에회원의 미정산 수익이 있는 경우 해당 수익은 소멸하고 회사는 수익 지급 의무를 면합니다.
                                        </li>
                                        <li>본 조 제1항의 경우 회원은 이 약관의 해지일 이후 재가입 신청을 할 수 있습니다. 또한, 재가입하는 경우에도 회원의 기존의 이용 기록이 승계되지는 않습니다.</li>
                                        <li>회사는 회원에게 다음 각 호의 어느 하나에 해당하는 사유가 있는 경우에는 해당 회원과의 이 약관을 해지할 수 있습니다.<br/> 이 경우 회원에게 의견제출의 기회를 사전에
                                            부여할 수
                                            있습니다.
                                            <ol className="nested">
                                                <li>회사 또는 제3자의 권리, 명예, 신용 및 그 밖의 정당한 이익을 침해하거나 관련 법령 또는 공서 양속에 위배되는 행위를 한 경우</li>
                                                <li>회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우</li>
                                                <li>제4조에 따른 승낙거부사유가 있는 경우</li>
                                                <li>회원이 회사로부터 요청 받은 정보 또는 증빙자료를 제공하지 아니하거나 허위로 제공한 경우</li>
                                                <li>이 약관 등에서 금지 또는 제한행위(등록제한, 이용제한 행위 등 모두 포함)를 한 경우</li>
                                                <li>이 약관에 따른 의무를 위반한 경우</li>
                                                <li>그 밖에 회사가 합리적인 이유로 서비스의 제공을 거부할 수 있는 경우</li>
                                            </ol>
                                        </li>
                                        <li>회사는 회원에게 다음 각 호의 어느 하나에 해당하는 사유로 인하여 이 약관을 정상적으로 이행할 수 없게 된 경우에는 그 상대방에게 별도의 최고 없이 해지의 통지를 함으로써
                                            이 약관을 해지할 수 있습니다.
                                            <ol className="nested">
                                                <li>이용계약에 따른 의무를 위반하고 이를 시정하지 아니한 경우</li>
                                                <li>부도 등 금융기관의 거래정지, 회생 및 파산절차의 개시가 있거나 이에 해당할 상당한 우려가 있는 경우</li>
                                                <li>법률, 법원의 판결이나 명령, 영업정지 및 취소 등의 행정처분, 또는 행정지도 등 정부의 명령 등</li>
                                                <li>가압류, 압류, 가처분, 경매 등의 강제집행 등</li>
                                                <li>주요 자산에 대한 보전처분, 영업양도 및 합병 등</li>
                                            </ol>
                                        </li>
                                        <li>본 조. 제3항 및 제4항에 따라 해지된 회원 또는 이용제한 기간 중 해지한 회원은 재가입이 제한될 수 있습니다.</li>
                                    </ol>

                                    <h2 className="title">제24조 지식재산권 등</h2>
                                    <ol className="top-level">
                                        <li>회원이 작성한 콘텐츠, 게시물 등은 회원의 동의 없이 회사 가 제공하는 서비스 및 관련 프로모션 등에 노출되거나 해당 노출을 위해 필요한 범위 내에서 전부 또는 일부 수정,
                                            복제, 편집 등의 행위를 할 수 있으며 회원은 본 항에 따른 일시적 사용에 대한 무상 사용권을 회사에 부여합니다.
                                        </li>
                                        <li>회사가 제공하는 서비스와 관련하여 회사가 자체적으로 제작한 저작물(회원으로부터 제공받은 콘텐츠를 활용하여 회사가 제작한 2차적 저작물을 포함)에 관한
                                            지식재산권(특허ㆍ실용실안ㆍ디자인ㆍ상표권과 저작권 포함)은 회사에게 있으며, 회원은 회사의 서면 동의 없이 회사의 지식재산권을 이용해서는 아니됩니다.
                                        </li>
                                        <li>본 조의 내용은 이 약관이 종료된 이후에도 유효하게 존속합니다.</li>
                                    </ol>

                                    <h2 className="title">제25조 손해배상</h2>
                                    <ol className="top-level">
                                        <li>관련 법령, 본 약관 또는 운영 정책에서 별도로 규정한 사항을 제외하고, 회사 또는 회원의 고의 또는 과실로 상대방에게 손해가 발생한 경우 귀책사유 있는 당사자는 상대방이
                                            입은 손해를 배상하여야 합니다.
                                        </li>
                                        <li>회사는 회원의 실적이 없는 동안의 서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.</li>
                                    </ol>

                                    <h2 className="title">제26조 책임제한</h2>
                                    <ol className="top-level">
                                        <li>회사는 천재지변, 전쟁 및 분쟁, 정전, 분산 서비스 공격(DDoS 공격), 인터넷 서비스 제공자(ISP)의 네트워크 장애 등 이에 준하는 불가항력적인 사태로 인하여
                                            “서비스”를 제공할 수 없거나 정상적인 광고 집행이 불가한 경우 이에 대한 책임을 지지 않습니다.
                                        </li>
                                        <li>회사는 회원의 귀책사유로 인하여 발생한 “서비스” 이용 장애 및 광고 미집행에 대하여 책임을 지지 않습니다.</li>
                                        <li>권한 설정을 통하여 권한을 위탁하거나 위탁 받은 “회원”간에 분쟁이 발생하는 경우 “회사”는 귀책사유가 없는 한 책임을 지지 않습니다.</li>
                                        <li>회원이 직접 광고매체에 제공한 광고 소재가 지식재산권을 포함한 제3자의 권리를 침해하거나 허위정보 등을 포함함으로써 관련법령 위반 또는 광고매체 이용자에 대한 손해를
                                            발생시킨 경우 회사는 이로부터 면책되며 이에 대한 모든 법적 책임은 회원에게 있습니다.
                                        </li>
                                        <li>회사는 광고매체를 이용하여 게시 또는 전송한 자료 등과 관련하여 회원에게 손해가 발생하거나 자료 등의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도
                                            이로 인한 모든 법적 책임은 회원에게 있습니다.
                                        </li>
                                        <li>회사는 회원이 광고매체에 등록한 광고소재 및 광고소재에 연결된 페이지에서 특정 브라우저나 앱 등 일부 환경에서만 제한적으로 발생하는 오류 또는 장애에 대해서는 책임을 지지
                                            않으며, 이에 대한 사전 확인 의무는 회원에게 있습니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제27조 해외이용</h2>
                                    <ol className="top-level">
                                        <li>회사는 대한민국 내에 설치된 서버를 기반으로 서비스를 제공, 관리하고 있습니다. 따라서, 회사는 회원이 대한민국 영토 이외의 지역에서 서비스를 이용하고자 하는 경우 서비스의
                                            품질 또는 사용성을 보장하지 않습니다.
                                        </li>
                                        <li>회원은 대한민국의 영토 이외의 지역에서 서비스를 이용하고자 하는 경우 스스로의 판단과 책임에 따라서 이용 여부를 결정하여야 하고, 특히 서비스의 이용과정에서 현지 법령을
                                            준수할 책임은 회원에게 있습니다.
                                        </li>
                                    </ol>

                                    <h2 className="title">제28조 약관의 해석</h2>
                                    <ol className="top-level">
                                        <li>본 약관에 대한 동의 이전에 회사가 제공하는 광고 관련 제반 서비스와 관련하여 회원과 회사간 체결한 계약 또는 합의 내용과 본 약관의 내용이 상충하는 경우 본 약관이
                                            우선하여 적용됩니다.
                                        </li>
                                        <li>본 약관에 대한 동의 이후 회원과 회사가 개별적으로 합의한 사항이 본 약관에 정한 사항과 다를 때에는 개별적 합의사항이 본 약관에 우선하여 적용됩니다.</li>
                                        <li>본 약관의 내용 중 관련 법령의 강행규정과 배치되는 부분이 있는 경우, 그 부분에 한하여 본 약관의 해당 규정은 무효로 합니다.</li>
                                        <li>본 약관에 명시적으로 규정되지 않고, 당사자간에 미리 합의되지 않은 사항의 처리에 관하여는 관련 법령 및 상관습에 따릅니다.</li>
                                    </ol>

                                    <h2 className="title">제29조 준거법 및 재판 관할</h2>
                                    <ol className="top-level">
                                        <li>회사와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                                        <li>본 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생할 경우 상호합의에 따라 해결합니다.</li>
                                        <li>전 항에도 불구하고 합의가 되지 않는 경우 진행되는 회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
                                            전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
                                        </li>
                                        <li>해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.</li>
                                    </ol>

                                    <h2 className="title">부칙</h2>
                                    <p>본 약관은 2024년 7월 1일부터 시행합니다.</p>
                                </div>
                            </div>
                    </div>
                    <a href="javascript:;" className="popBtn" onClick={() => closeTermsPop(1)}>확인</a>
                </div>
            </div>
            {/* <!-- 개인정보 수집 및 이용 팝업 --> */}
            <div className="layerPop consentForm" style={{ display: privatePop ? 'block' : 'none' }}>
                <div className="layerCon">
                    <div className="layerTop">
                        <div className="ltCont">
                            <p>개인정보 수집 및 이용</p>
                            <a href="javascript:;" onClick={() => closeTermsPop(2)}><img src="/images/btn/x.svg" alt="닫기" /></a>
                        </div>
                        <div className="layerCont policy">
                            <div className="scrollBox">
                                <select name="" id="">
                                    <option value="">2024.07.01</option>
                                </select>
                                <h2 style={{marginTop: 0}}>개인정보 수집 및 이용에 대한 안내</h2>
                                <p className="firstText">(주)티웨이브(이하 ‘회사’라 함)는 이용자의 개인정보를 중요시하며, ‘개인정보보호법’ 등 관계 법령에 의거하여 개인정보를 관리 및 보호하고 있습니다. 회사는 개인정보의 수집, 이용 등 처리에 있어 아래의 사항을 정보 주체에게 안내합니다.</p>
                                <h2>제 1 조 개인정보의 수집•이용목적</h2>
                                <p className="subText">회사는 수집한 개인정보를 다음의 목적을 위해 이용합니다.</p>
                                <ol className="top-level">
                                    <li>
                                        회원가입 및 회원관리
                                        <ul className="nested">
                                            <li>회원제 서비스 이용에 따른 본인확인, 개인식별, 부정 이용 및 비인가 사용 방지, 중복가입 확인, 분쟁 조정을 위한 기록보존, 불만 처리 등 민원 처리, 고지사항 전달 등</li>
                                        </ul>
                                    </li>
                                    <li>
                                        서비스의 안전성 확보 및 개선
                                        <ul className="nested">
                                            <li>보안, 프라이버시, 안전 측면에서 회원이 안심하고 이용할 수 있는 서비스 이용환경 구축을 위한 활용</li>
                                            <li>사용자 편의를 위한 신규 서비스 요소의 발굴 및 기존 서비스 개선</li>
                                        </ul>
                                    </li>
                                    <li>
                                        수익 정산 및 회계 처리
                                        <ul className="nested">
                                            <li>거래 정보: 생년월일</li>
                                        </ul>
                                    </li>
                                </ol>
                                <h2>제 2 조 수집하는 개인정보 항목</h2>
                                <ol className="top-level">
                                    <li>회사는 원활한 서비스 이용을 위하여 회원 가입 시 다음의 개인정보를 수집합니다.
                                        <ol className="nested">
                                            <li>회원 가입 및 회원 관리: 이메일 주소, 비밀번호, 이름, 이동 통신사 정보, 휴대전화번호, 연계정보(CI), 중복가입확인정보(DI)O</li>
                                            <li>서비스의 안전 및 개선: IP Address, 쿠키, 접속 로그, 방문 일시, 서비스 이용 기록, 불량 이용 기록</li>
                                            <li>수익 정산 및 회계 처리 : 계좌 이체를 위한 은행 명, 생년월일, 계좌번호</li>
                                        </ol>
                                    </li>
                                    <li>회사가 제공하는 추가 서비스 이용 또는 이벤트 응모 및 제휴 서비스 신청 등의 과정에서 개인정보 추가 수집이 발생할 수 있습니다.</li>
                                    <li>개인정보를 추가 수집하는 경우, 별도 동의를 거쳐 개인정보를 수집 및 이용 합니다.</li>
                                </ol>
                                <h2>제 3 조 개인정보의 보유 및 이용기간</h2>
                                <p className="firstText">회사는 회원 탈퇴를 요청하거나 원칙적으로 개인정보 수집 및 이용 목적이 달성된 경우, 수집 및 이용 목적이 달성되거나 보유 기간이 종료될 시에 이용자의 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
                                <ol className="top-level">
                                    <li>회사는 회원의 탈퇴, 해지 요청 등에 의하여 서비스 이용이 종료된 이후에도 서비스 혼선 방지, 불량 회원의 부정한 이용의 재발 및 재가입 방지, 분쟁 해결 등을 위하여 이용계약 해지일로부터 6개월 간 회원의 정보를 보관합니다.</li>
                                    <li>회사는 다음 각 호에 해당하는 개인정보는 다른 개인정보와 분리하여 각 호에서 정한 기간 동안 저장∙관리합니다.
                                        <ol className="nested">
                                            <li>관계 법령 위반에 따른 수사∙조사 등이 진행 중인 경우: 해당 수사∙조사의 종료시까지</li>
                                            <li>서비스 이용 계약에 따른 채권∙채무가 잔존 하는 경우: 해당 채권∙채무관계가 정산될 때까지</li>
                                            <li>
                                                「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시∙광고, 계약내용 및 이행 등 거래에 관한 기록
                                                <ul className="nested">
                                                    <li>표시∙광고에 관한 기록: 6개월</li>
                                                    <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
                                                    <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
                                                    <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
                                                </ul>
                                            </li>
                                            <li>「통신비밀보호법」에 따른 웹사이트 방문 기록: 3개월</li>
                                            <li>전화 상담에 의한 문의처리 및 분쟁해결 등의 기록관리, 서비스 개선, 감정 노동자 보호: 녹취일로부터 3년</li>
                                            <li>기타 관련 법령에 따르 보관되는 항목 및 기간</li>
                                        </ol>
                                    </li>
                                </ol>
                                <p style={{margin:'16px 0 8px 0'}}>※ 동의를 거부할 권리 및 동의 거부에 대한 불이익 :아임인 파트너스 서비스 이용을 위한 개인정보의 수집・이용에 대한 동의를 거부할 수 있습니다. 단, 동의를 거부한 경우 회사가 제공하는 서비스 가입 및 이용이 어려울 수 있음을 알려 드립니다.</p>
                                <p>그 밖의 사항은 아임인 파트너스 개인정보처리방침에 따릅니다.</p>
                            </div>
                        </div>
                    </div>
                    <a href="javascript:;" className="popBtn" onClick={() => closeTermsPop(2)}>확인</a>
                </div>
            </div>
            {/* <!-- 마케팅 정보 수집 팝업 --> */}
            <div className="layerPop consentForm" style={{ display: marketingPop ? 'block' : 'none' }}>
                <div className="layerCon">
                    <div className="layerTop">
                        <div className="ltCont">
                            <p>마케팅 정보 수집</p>
                            <a href="javascript:;" onClick={() => closeTermsPop(3)}><img src="/images/btn/x.svg" alt="닫기" /></a>
                        </div>
                        <div className="layerCont policy">
                            <div className="scrollBox">
                                <select name="" id="">
                                    <option value="">2024.07.01</option>
                                </select>
                                <h2 style={{marginTop: 0}}>제1조 목적</h2>
                                <p className="subText mgB8">
                                (주)티웨이브(이하 ‘회사')에서 운영하는 ‘아임인 파트너스’ (이하 ‘서비스')는 개인정보보호법 및 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계 법령에 따라 광고성 정보를 전송하기 위해 이용자의 사전 수신 동의를 받고 있습니다.</p>
                                <p className="subText">개인정보보호법 제22조 제4항에 의해 선택 정보 사항에 대해서는 동의하지 않아도 서비스를 이용할 수 있습니다.</p>
                                <h2>1. 전송 방법</h2>
                                <p className="subText">회사는 서비스를 운용함에 있어 각종 정보를 서비스 화면, 전화,  E-mail, 문자메세지 등의 방법으로 제공합니다.</p>
                                <h2>2. 전송 내용</h2>
                                <p className="subText">
                                발송되는 마케팅 정보는 수신자에게 회사에서 제공하는 혜택(포인트, 쿠폰 등) 정보, 각종 이벤트, 신규 서비스 관련 소식 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 단, 광고성 정보 이외에 의무적으로 안내 되어야 하는 정보성 내용은 수신 동의 여부와 무관하게 제공됩니다.
                                </p>
                                <h2>3. 수집 항목</h2>
                                <ul className="nested">
                                    <li>이메일, 이름, 전화번호</li>
                                </ul>
                                <h2>4. 이용 목적</h2>
                                <ul className="nested">
                                    <li>서비스 제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공</li>
                                    <li>인구통계학적 특성에 따른 서비스 제공 및 광고 게재</li>
                                    <li>서비스의 유효성 확인, 접속 빈도 파악 또는 서비스 이용에 대한 통계</li>
                                    <li>고객 맞춤 마케팅/판촉 등 관련 이메일 및 SMS 등 발송</li>
                                </ul>
                                <h2>5. 이용 목적</h2>
                                <p className="subText">수신 동의 이후에라도 언제든지 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용하실 수 있습니다. 다만, 수신 거부할 경우 신규 서비스나 상품 관련 소식 등의 마케팅 정보를 제공받지 못할 수 있습니다.</p>
                                <h2>6. 수신 동의 변경 및 보유기간</h2>
                                <ul className="nested">
                                    <li>보유 기간 : 마케팅 정보 수신 동의로부터 2년, 기간 초과 시 동의 절차 재진행 (미동의 시, 즉시 파기)</li>
                                    <li>아임인 파트너스 정보수정 페이지에서 마케팅 수신 동의를 변경(동의/철회)할 수 있으며, 동의일로부터 회원 탈퇴 혹은 마케팅 수신 동의 해제 시까지 광고성 정보 전달을 위하여 보유ㆍ이용 됩니다.</li>
                                </ul>
                                <h2>[부칙]</h2>
                                <p className="subText">본 약관은 2024년 7월 1일부터 시행합니다.</p>
                            </div>
                        </div>
                    </div>
                    <a href="javascript:;" className="popBtn" onClick={() => closeTermsPop(3)}>확인</a>
                </div>
            </div>            
            {/* <!-- 알림 팝업 --> */}
            {popOpen && (
                <Laypop data={popData} closePop={closePop}/>
            )}
            {popOpen2 && (
                <Confirmpop data={popData2} closePop={closePop3} closePop2={closePop2}/>
            )}
        </section>
    )
}

export default Join;

