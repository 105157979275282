import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
import Pagination from 'components/Pagination';
import * as CommonFunc from 'common/js/func';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import loginInfoState from 'store/State';
import { startOfMonth, startOfWeek, endOfMonth, endOfWeek, format } from "date-fns"

import 'common/css/report/performanceView.css';

const PerformanceView = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    // const { pnidx} = loginInfoValue;
    const { token } = loginInfoValue;
    const decodeInfoValue = CommonFunc.decodedToken(token);
    const { pnidx } = decodeInfoValue;
    
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }
    const movePageParams = useLocation();
	const viewDate = movePageParams.state.date;
	const viewTabtp = movePageParams.state.tabTp;
    // console.log(viewDate);
    //카운트 관련
    /****************************************************/
    const [totalCnt, setTotalCnt] = useState(0);
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호

    /****************************************************/
    const [tabTp, setTabTp] = useState(3);
    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    const paraGetTab = parseInt(urlParams.get("tab"));

    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
        !paraGetTab ? setTabTp(3) : setTabTp(paraGetTab);
        // console.log(paraGetTab);
        onList();
    }, [paraGetPage, paraGetTab])

    // 페이지 이동
    const changePages = (page) => {
        // setPageNum(page);
        movePage(`?page=${page}&tab=${tabTp}`, { state: { date: viewDate } });
    }

    // 탭 선택
    const changeTab = (key) => {
        movePage(`?page=${1}&tab=${key}`, { state: { date: viewDate } });
    };

    /*********************/
    //usequry 검색용
    const [isSearch, setIsSearch] = useState(true);
    const [isList, setIsList] = useState(true);

    // Enter 입력이 되면 클릭 이벤트 실행
    const handleOnKeyDown = e => {
        if (e.key === 'Enter') {
            performReload(); 
        }
    };

    const performReload = () => {
        onList();
        setIsSearch(!isSearch);
    }
    
    //리스트 검색
    const onList = () => {
        setIsList(!isList);
    };

    /*********************** */
    /* 검색 날짜 */
    
	const lastDate = CommonFunc.formatDate(endOfMonth(new Date(viewDate)), 'YYYY-MM-DD');

    const [startDate, setStartDate] = useState(new Date(viewDate)); // default 이번달 1일
    const [endDate, setEndDate] = useState(new Date(viewTabtp == 1 ? viewDate : lastDate));

    const selStartDate = (date) => {
        setStartDate(date);
        //setEndDate(date);
    };

    // 총카운트  
    const [PartnerTotal, setPartnerTotal] = useState({
        partner_pn_cnt: 0,
        partner_pn_fee: 0,
    })    
    const [IminTotal, setIminTotal] = useState({
        imin_mb_cnt: 0,
        imin_pn_fee: 0,
    })
    const [StageTotal, setStageTotal] = useState({
		stage_mb_cnt: 0,
        stage_s_fee: 0,
        stage_pn_fee: 0,
    })
    const [listData, setListData] = useState([]);

    useQuery(['performPartnerCnt', isSearch],
        async () => {
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const req = {
                // 일별실적:(sDate, eDate), 월별실적 : (sMonthDate, eMonthDate)
                params: { sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp }
            };
            /**/
            //console.log(req);
            const res = await api.get(`/api/v1/Perform/performPartnerTotal`, req);
            //  console.log(res);
            if (res.status === 200) {
                setPartnerTotal(res.data);
            }
            /**/
        });


    useQuery(['performIminCnt', isSearch],
        async () => {
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const req = {
                // 일별실적:(sDate, eDate), 월별실적 : (sMonthDate, eMonthDate)
                params: { sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp }
            };
            /**/
            const res = await api.get(`/api/v1/Perform/performIminTotal`, req);
            //  console.log(res);
            if (res.status === 200) {
                setIminTotal(res.data);
            }
            /**/
        });

    useQuery(['performStageCnt', isSearch],
        async () => {
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const req = {
                // 일별실적:(sDate, eDate), 월별실적 : (sMonthDate, eMonthDate)
                params: { sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp }
            };
            /**/
            const res = await api.get(`/api/v1/Perform/performStageTotal`, req);
            //  console.log(res);
            if (res.status === 200) {
                setStageTotal(res.data);
            }
            /**/
        });

    useQuery(['performList', isList],
        async () => {
            setListData([]);
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const req = {
                // 일별실적:(sDate, eDate), 월별실적 : (sMonthDate, eMonthDate)
                params: { sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp, page: pageNum - 1, size: pageRowCnt }
            };
            /**/
            //console.log(req);
            const res = await api.get(`/api/v1/Perform/performIminList`, req);
            //console.log(res.data.content);
            if (res.status === 200) {
                setListData(res.data.content);
                setTotalCnt(res.data.totalElements);
            }
        });

    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])

    // 가입일, 이용일 변환
    const RDateFormat = (pDate) => {
        // console.log(pDate);
        if (!pDate) {
            return (
                <>
                </>
            )
        } else {
            pDate = pDate.split(' ');
            return (
                <>
                    {pDate[0].replaceAll('-', '. ')}<br /><span>{pDate[1]}</span>
                </>
            )
        }
    }
    //엑셀 다운로드
    const Excelinput1 = async () => {
		//console.log("excel");
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const requestData = {
                 sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp, page: 0, size: 99999 
            };
        // console.log(requestData);
        const res = await api.get(`/api/v1/Perform/excel/performPartnerList`,{
            responseType: 'blob', // 바이너리 데이터로 응답 받기
            params: requestData
        });
        //console.log(res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '파트너스 가입.xlsx'); // 실적 내역
        document.body.appendChild(link);
        link.click();
    } 
    const Excelinput2 = async () => {
		//console.log("excel");
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const requestData = {
                 sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp, page: 0, size: 99999 
            };
        // console.log(requestData);
        const res = await api.get(`/api/v1/Perform/excel/performIminList`,{
            responseType: 'blob', // 바이너리 데이터로 응답 받기
            params: requestData
        });
        //console.log(res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '아임인 가입.xlsx'); // 실적 내역
        document.body.appendChild(link);
        link.click();
    }     
    const Excelinput3 = async () => {
		//console.log("excel");
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
            const requestData = {
                 sdate: sDate, edate: eDate, partnerIdx: pnidx, pftp: tabTp, page: 0, size: 99999 
            };
        const res = await api.get(`/api/v1/Perform/excel/performStageList`,{
            responseType: 'blob', // 바이너리 데이터로 응답 받기
            params: requestData
        });
        //console.log(res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '아임인 이용.xlsx'); // 실적 내역
        document.body.appendChild(link);
        link.click();
    }      
    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/report/Performance')}>리포트</span>
                    <span onClick={() => pageMoveA('/report/Performance')}>실적 리포트</span>
                    <span onClick={() => movePage('/report/PerformanceView', { state: { date: viewDate, tabTp: viewTabtp } })}>실적 상세 리포트</span>
                </div>
            </div>
            <section className="subCont promotionView" id="promotionView">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>실적 상세 리포트</h4>
                    <p>회원님의 홍보하기 활동을 통해 발생한 수익을 상세하게 확인해 보세요.</p>
                </div>
                <div className="tabBox">
                    <ul>
                        <li className={tabTp === 3 ? 'active' : ''}><a href="javascript:;" onClick={() => changeTab(3)}>파트너스 가입</a></li>
                        <li className={tabTp === 4 ? 'active' : ''}><a href="javascript:;" onClick={() => changeTab(4)}>아임인 가입</a></li>
                        <li className={tabTp === 5 ? 'active' : ''}><a href="javascript:;" onClick={() => changeTab(5)}>아임인 이용</a></li>
                    </ul>
                </div>
                <div>
                {/* 파트너스 가입 */}
                <div className="joinPromotion" style={{ display: tabTp === 3 ? 'block' : 'none' }}>
                    <div className="excelDown">
                        <div className="dateBox">
                            <div className="cal">
                                <DatePicker
                                    locale={ko}
                                    selected={startDate}
                                    onChange={date => selStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="시작일"
                                    onKeyDown={handleOnKeyDown}
                                    popperPlacement="bottom-start"
                                />
                            </div>
                            <span>-</span>
                            <div className="cal">
                                <DatePicker
                                    locale={ko}
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    className="cal"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="종료일"
                                    onKeyDown={handleOnKeyDown}
                                    popperPlacement="bottom-start"
                                />
                            </div>
                            <a href="javascript:;" className="btn btnS btnGray2" onClick={() => performReload()}>검색</a>
                        </div>
                            <a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput1}><img src="../images/icon/download_v.svg" alt="다운로드" />엑셀 다운로드</a>
                    </div>
                        <ul className="addBox">
                            <li>
                                <p>파트너스 가입</p>
                                <p>{CommonFunc.numberWithCommas(PartnerTotal.partner_pn_cnt)}</p>
                            </li>

                            <li>
                                <p>수익</p>
                                <p>{CommonFunc.numberWithCommas(PartnerTotal.partner_pn_fee)}</p>
                            </li>
                        </ul>
                        <div className="">
                            <table className="tableList">
                                <colgroup>
                                    <col style={{ width: "33.333%" }} />
                                    <col style={{ width: "33.333%" }} />
                                    <col style={{ width: "33.333%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>가입일</th>
                                        <th>아이디</th>
                                        <th>수익</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tabTp === 3 && Array.isArray(listData) ? (
                                            totalCnt > 0 ? (
                                                listData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{RDateFormat(item.u_reg_date)}</td>
                                                        <td>{(item.id == null ? "-" :CommonFunc.numberWithCommas(item.id))}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.pn_fee)}</td>
                                                    </tr>
                                                ))) :
                                                (
                                                    <tr><td colSpan="3" className="noData">검색 결과가 없습니다.</td></tr>
                                                )) :
                                            (
                                                null
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- 아임인 가입 --> */}
                    <div className="joinPromotion" style={{ display: tabTp === 4 ? 'block' : 'none' }}>
                        <div className="excelDown">
                            <div className="dateBox">
                                <div className="cal">
                                    <DatePicker
                                        locale={ko}
                                        selected={startDate}
                                        onChange={date => selStartDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="시작일"
                                        onKeyDown={handleOnKeyDown}
                                        popperPlacement="bottom-start"
                                    />
                                </div>
                                <span>-</span>
                                <div className="cal">
                                    <DatePicker
                                        locale={ko}
                                        selected={endDate}
                                        onChange={date => setEndDate(date)}
                                        className="cal"
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="종료일"
                                        onKeyDown={handleOnKeyDown}
                                        popperPlacement="bottom-start"
                                    />
                                </div>
                                <a href="javascript:;" className="btn btnS btnGray2" onClick={() => performReload()}>검색</a>
                            </div>
                            <a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput2}><img src="../images/icon/download_v.svg" alt="다운로드" />엑셀 다운로드</a>
                        </div>
                        <ul className="addBox">
                            <li>
                                <p>아임인 가입</p>
                                <p>{CommonFunc.numberWithCommas(IminTotal.imin_mb_cnt)}</p>
                            </li>

                            <li>
                                <p>수익</p>
                                <p>{CommonFunc.numberWithCommas(IminTotal.imin_pn_fee)}</p>
                            </li>
                        </ul>
                        <div className="">
                            <table className="tableList">
                                <colgroup>
                                    <col style={{ width: "33.333%" }} />
                                    <col style={{ width: "33.333%" }} />
                                    <col style={{ width: "33.333%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>가입일</th>
                                        <th>닉네임</th>
                                        <th>수익</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tabTp === 4 && Array.isArray(listData) ? (
                                            totalCnt > 0 ? (
                                                listData.map((item, index) => (
                                                    <tr key={index}>
                                                        {/* <td>{CommonFunc.MonthDate('day', item.r_date)}</td> */}
                                                        <td>{RDateFormat(item.u_reg_date)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.n_nm)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.pn_fee)}</td>
                                                    </tr>
                                                ))) :
                                                (
                                                    <tr><td colSpan="3" className="noData">검색 결과가 없습니다.</td></tr>
                                                )) :
                                            (
                                                null
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- 아임인 이용 --> */}
                    <div className="stagePromotion" style={{ display: tabTp === 5 ? 'block' : 'none' }}>
                        <div className="excelDown">
                            <div className="dateBox">
                                <div className="cal">
                                    <DatePicker
                                        locale={ko}
                                        selected={startDate}
                                        onChange={date => selStartDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="시작일"
                                        onKeyDown={handleOnKeyDown}
                                        popperPlacement="bottom-start"
                                    />
                                </div>
                                <span>-</span>
                                <div className="cal">
                                    <DatePicker
                                        locale={ko}
                                        selected={endDate}
                                        onChange={date => setEndDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="종료일"
                                        onKeyDown={handleOnKeyDown}
                                        popperPlacement="bottom-start"
                                    />
                                </div>
                                <a href="javascript:;" className="btn btnS btnGray2" onClick={() => performReload()}>검색</a>
                            </div>
                            <a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput3}><img src="../images/icon/download_v.svg" alt="다운로드" />엑셀 다운로드</a>
                        </div>
                        <ul className="addBox">
                            <li>
                                <p>아임인 이용</p>
                                <p>{CommonFunc.numberWithCommas(StageTotal.stage_s_fee)}</p>
                            </li>
                            <li>
                                <p>수익</p>
                                <p>{CommonFunc.numberWithCommas(StageTotal.stage_pn_fee)}</p>
                            </li>
                        </ul>
                        <div className="tableBox">
                            <table className="tableList">
                                <colgroup>
                                    <col style={{ width: "160px" }} />
                                    <col style={{ width: "200px" }} />
                                    <col style={{ width: "300px" }} />
                                    <col style={{ width: "300px" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>아임인 이용일</th>
                                        <th>닉네임</th>
                                        <th>아임인 이용</th>
                                        <th>수익</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tabTp === 5 && Array.isArray(listData) ? (
                                            totalCnt > 0 ? (
                                                listData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{RDateFormat(item.sg_u_date)}</td>
                                                        <td>{(item.n_nm)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.s_fee)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.pn_fee)}</td>
                                                    </tr>
                                                ))) :
                                                (
                                                    <tr><td colSpan="4" className="noData">검색 결과가 없습니다.</td></tr>
                                                )) :
                                            (
                                                null
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <!-- 페이징 --> */}
                <div className="pagingDiv">
                    <Pagination
                        totalPage={totalPage === 0 ? 1 : totalPage}
                        limit={pageSize}
                        page={pageNum}
                        setPage={changePages}
                    />
                </div>
            </section>
        </>
    )
}

export default PerformanceView;
