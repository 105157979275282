import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import Pagination from 'components/Pagination';
import * as CommonFunc from 'common/js/func';
import 'common/css/report/calculate.css';

import { useRecoilValue } from 'recoil';
import loginInfoState from 'store/State';

const Calculate = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    const {token} = loginInfoValue;
    const decodeInfoValue = CommonFunc.decodedToken(token);
    const { pnidx} = decodeInfoValue;
	
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    //레이어창 띄우기
    const [infoOC, setInfoOC] = useState(false);
    const infoView = async () => {
        setInfoOC(true);
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
    `;
    }
    const infoClose = async () => {
        setInfoOC(false);
        document.body.style.cssText = ``;
    }  
    
    //카운트 관련
    /****************************************************/
    const [totalCnt, setTotalCnt] = useState(0);
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호

    /****************************************************/
    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    
    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
        // console.log(paraGetTab);
        onList();
    }, [paraGetPage])

    // 페이지 이동
    const changePages = (page) => {
        // setPageNum(page);
        movePage(`?page=${page}`);
    }
      
    /*********************/
    //usequry 검색용
    const [isSearch, setIsSearch] = useState(true);
    const [isList, setIsList] = useState(true);
    
    // Enter 입력이 되면 클릭 이벤트 실행
    const handleOnKeyDown = e => {
        if (e.key === 'Enter') {
            calculateReload(); 
        }
    };

    const calculateReload = () => {
        onList();
        setIsSearch(!isSearch);
    }
    
    //리스트 검색
    const onList = () => {
        setIsList(!isList);
    };
    
    const [startDate, setStartDate] = useState(new Date('2024-01-01')); // default 이번달 1일
    const [endDate, setEndDate] = useState(new Date('2024-12-29'));
	const [monthDate, setMonthDate] = useState(''); 	
	
	useEffect(() => {
        calculateReload();
    }, [monthDate])

	//리스트
	const [listData, setListData] = useState([]);

	useQuery(['calculateList', isList],
		async () => {
           // const MonthDate = monthDate;
            const sMonthDate = (!monthDate ? '': monthDate + '-01-01');
            const eMonthDate = (!monthDate ? '' : monthDate + '-12-31');           
			const req = {
				//params: {sdate: sMonthDate, edate: eMonthDate, pnidx:pnidx, page: pageNum - 1, size: pageRowCnt }
				params: {sdate: sMonthDate, edate: eMonthDate, partnerIdx:pnidx, page: pageNum - 1, size: pageRowCnt }
			}
			//console.log(req);
			const res = await api.get(`/api/v1/Perform/calculateList`, req);

			 if (res.status === 200) {
                setListData(res.data.content);
                setTotalCnt(res.data.totalElements);
            }
		});	
		
    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])
    		
		
    // 년도 SelBOX
    const YearSel = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: currentYear - 2024 + 1 }, (_, index) => currentYear - index);
        return (
            <>
            	<option value="">전체현황</option>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}년
                    </option>
                ))}
            </>
        )
    }	
    // 지급 여부
    const RDateFormat = (pType, pDate, pIssue) => {
        if (pType === 2) {
	        return (
	            <>
	                {'지급완료'}
	            </>
	        )
        }
        else if (pType === 1) {
			return (
	            <>
	                {'지급보류'}
	            </>
	        )	
		}
        else if (pType === 3) {
			return (
	            <>
	                {'지급불가'}
	            </>
	        )	
		}				
        else { 
	        return (
	            <>
	                {'미지급'}
	            </>
	        )     
        }   
    }    
   
    //엑셀 다운로드
    const Excelinput = async () => {
        //		console.log("excel");
        const sMonthDate = (!monthDate ? '' : monthDate + '-01-01');
        const eMonthDate = (!monthDate ? '' : monthDate + '-12-31');
        const requestData = {
            //params: {sdate: sMonthDate, edate: eMonthDate, pnidx:pnidx, page: pageNum - 1, size: pageRowCnt }
            sdate: sMonthDate, edate: eMonthDate, partnerIdx: pnidx, page: 0, size: 99999
        }
//		console.log(requestData);
        const res = await api.get(`/api/v1/Perform/excel/calculateList`, {
            responseType: 'blob', // 바이너리 데이터로 응답 받기
            params: requestData
        });

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '정산 리포트.xlsx'); // 실적 내역
        document.body.appendChild(link);
        link.click();
    }
    
    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/report/Performance')}>리포트</span>
                    <span onClick={() => pageMoveA('/report/Calculate')}>정산 리포트</span>
                </div>
            </div>
            <section className="subCont" id="calculate">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>정산 리포트</h4>
                    <div className="helpBox">
                        <a href="javascript:;" onClick={() => infoView()}><img src="/images/icon/help-circle.svg" alt="정산 리포트에 대해 궁금하다면 눌러보세요" /></a>
                        <img src="/images/png/help.png" alt="정산 리포트에 대해 궁금하다면 눌러보세요" />
                    </div>
                    {/* <!-- 정산 리포트 팝업 --> */}
					<div className="layerPop" style={{ display: infoOC ? 'block' : 'none' }}>
						<div className="layerCon">
                            <div className="layerTop">
                                <div className="ltCont">
                                    <p>정산 리포트</p>
                                    <a href="javascript:;" onClick={() => infoClose()}><img src="/images/btn/x.svg" alt="닫기" /></a>
                                </div>
                                <div className="layerCont">
                                    <div className="scrollBox">
                                        <p className="firstText">정산 리포트에서는 아임인 파트너스 활동으로 발생한 수익을 한 눈에 확인할 수 있습니다.</p>
                                        <p className="prTitle">정산 월</p>
                                        <p className="subText">수익이 발생한 월</p>
                                        <p className="prTitle">가입 수익</p>
                                        <p className="subText mgB8">다른 사람이 내 추천인 코드로 신규 회원가입 시 발생하는 수익</p>
                                        <ul className="subList">
                                            <li>파트너스 : 내 추천인 코드로 아임인 파트너스 신규 회원가입 (피추천인)</li>
                                            <li>아임인 : 내 추천인 코드로 아임인 앱 신규 회원가입</li>
                                        </ul>
                                        <p className="prTitle">아임인 이용수익</p>
                                        <p className="subText">아임인 앱의 스테이지 최초 이용 시 발생하는 수익</p>
                                        <p className="prTitle">추천인 수익</p>
                                        <p className="subText">피추천인의 가입일로부터 30일 간 첫 달에만 주어지는 추가 보너스 수익이며, 아임인 이용 금액의 0.1%를 ‘추천인’에게 추가 지급</p>
                                        <p className="prTitle">프로모션 수익</p>
                                        <p className="subText">아임인 파트너스에서 운영하는 프로모션으로 받은 수익</p>
                                        <p className="prTitle">미정산 수익</p>
                                        <p className="subText">전월에 지급되지 않은 수익</p>
                                        <p className="prTitle">총 수익</p>
                                        <p className="subText">내가 받은 수익의 총 합계</p>
                                        <p className="prTitle">지급 상태</p>
                                        <p className="subText mgB8">총 수익의 지급 상태</p>
                                        <ul className="subList">
                                            <li>지급완료 : 총 수익이 지급완료 된 상태</li>
                                            <li>지급보류 : 최소 지급 기준 미만이거나 기타 사유로 지급이 보류된 상태</li>
                                            <li>지급불가 : 운영 정책을 위반하거나 기타 사유로 지급이 불가한 상태</li>
                                        </ul>
                                        <p className="subText">※회사 운영 정책에 의해 수익 지급 조건 및 금액은 변동될 수 있습니다.</p>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:;" className="popBtn" onClick={() => infoClose()}>확인</a>
                        </div>
                    </div>
                    <p>총 수익금은 최소 2,000원 이상인 경우에만 지급되며, 매월 자동으로 정산됩니다.</p>
                    <ul>
                        <li>정산 주기는 매월이며, 당월 1일부터 말일까지 발생한 수익을 기준으로 익월 25일에 지급됩니다.</li>
                        <li>총 수익금에서 원천징수소득세 3.3%를 제외하고 입력한 본인 계좌로 지급됩니다.</li>
                    </ul>
                </div>
                <div className="excelDown">
                    <select value={monthDate} onChange={(e) => setMonthDate(e.target.value)}>
                        <YearSel />
                    </select>
                    <a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput}><img src="../images/icon/download_v.svg" alt="다운로드"  />엑셀 다운로드</a>
                </div>
                <div className="tableBox">
                    <table className="tableList">
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "9%" }} />
                            <col style={{ width: "12%" }} />
                            <col style={{ width: "9%" }} />
                            <col style={{ width: "9%" }} />
                            <col style={{ width: "9%" }} />
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "12%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowSpan={2}>정산 월</th>
                                <th colSpan={2} style={{ padding: '4px 0' }}>가입 수익</th>
                                <th rowSpan={2}>아임인 이용 수익</th>
                                <th rowSpan={2}>추천인 수익</th>
                                <th rowSpan={2}>프로모션 수익</th>
                                <th rowSpan={2}>미정산 수익</th>
                                <th rowSpan={2}>소멸 수익</th>
                                <th rowSpan={2}>총 수익</th>
                                <th rowSpan={2}>지급 상태</th>
                            </tr>
                            <tr>
                                <th style={{ padding: '4px 0' }}>파트너스</th>
                                <th style={{ padding: '4px 0' }}>아임인</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(listData) ? (
                                    totalCnt > 0 ? (
                                        listData.map((item, index) => (

                                            <tr key={index} >
                                                <td>{item.r_date}</td>
                                                <td>{CommonFunc.numberWithCommas(item.partner_fee)}</td>
                                                <td>{CommonFunc.numberWithCommas(item.imin_fee)}</td>
                                                <td>{CommonFunc.numberWithCommas(item.stage_fee + item.bonus_fee)}</td>
                                                <td>{CommonFunc.numberWithCommas(item.recom_fee)}</td>
                                                <td>{CommonFunc.numberWithCommas(item.promotion_fee)}</td>
                                                <td>{CommonFunc.numberWithCommas(item.non_cal_fee)}</td>
                                                <td>{CommonFunc.numberWithCommas(item.extinct_fee)}</td>
                                                <td className="ftBold">{CommonFunc.numberWithCommas(item.partner_fee + item.imin_fee + item.stage_fee + item.recom_fee + item.bonus_fee + item.promotion_fee + item.non_cal_fee - item.extinct_fee)}</td>
                                                <td>{RDateFormat(item.ps_sts, item.ps_date, item.ps_issue)}</td>
                                            </tr>
                                        ))) :
                                        (
                                            <tr><td colSpan="10" className="noData">검색 결과가 없습니다.</td></tr>
                                        )) :
                                    (
                                        null
                                    )
                            }
                        </tbody>
                    </table>
                </div>
                {/* <!-- 페이징 --> */}
                <div className="pagingDiv">
                    <Pagination
                        totalPage={totalPage === 0 ? 1 : totalPage}
                        // totalPage={totalPage}
                        limit={pageSize}
                        page={pageNum}
                        setPage={changePages}
                    />
                </div>
            </section>
        </>
    )
}

export default Calculate;
