
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from "react-router-dom";
import loginInfoState from 'store/State';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'common/css/main.css'
const Main = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    const { isLogin} = loginInfoValue;

    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    const [currentSlide, setCurrentSlide] = useState(0);

    const Dots = () => {
        const totalSlides = 3; // 이미지 개수에 따라 조절하세요.
        const dots = [];
        for (let i = 0; i < totalSlides; i++) {
          dots.push(
            <li key={i} className={`dot ${currentSlide === i ? 'active' : ''}`}>
              <button onClick={() => handleDotClick(i)}>
                <span></span>
              </button>
            </li>
          );
        }
        return dots;
    };

    // 도트 이동
    const handleDotClick = (index) => {
        setCurrentSlide(index);
        slider.current.slickGoTo(index);
    };

    const settings = {
        dots: false,        // 기존 도트
        arrows: false,      // 화살표 버튼 여부
        infinite: true,     // 슬라이드 반복
        speed: 1000,        // 이동속도
        slidesToShow: 1,    // 한번에 볼수있는
        slidesToScroll: 1,  // 넘어가는 수
        fade: true,
        autoplay: true,     // 자동 재생 할 것인지
        autoplaySpeed: 5000,// 자동 재생 간격
        cssEase: 'linear',  // 슬라이드 이동 스타일
        pauseOnHover: true, // 마우스 호버시 슬라이드 멈춤
        beforeChange: (current, next) => setCurrentSlide(next),
        responsive: [{      // 반응형 옵션
            breakpoint: 767,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    const slider = React.useRef(null);

    return (
        <div className="partners" id='Main'>
            {/* <!-- 컨텐츠 --> */}

            <div className="banner">
                <div className="bgBox">
                    <Slider ref={slider} {...settings}>
                        <ul>
                            <li>
                                <div className="txtBox">
                                    <p className="txtTitle">아임인 파트너스와 함께 <br /><span> 더 높은 수익</span>을 만들어 보세요.</p>
                                    <p className={`txtSub ${isLogin ? 'mainLogin' : ''}`}>아임인 파트너스는 고수익 창출이 가능해요!</p>
                                    <div style={{ display: isLogin ? 'none' : 'block' }}>
                                        <a onClick={() => pageMoveA('/myinfo/Join')}>회원가입 하기</a>
                                        <a onClick={() => pageMoveA('/myinfo/Login')}>로그인 하기</a>
                                    </div>
                                </div>
                                <img src="images/png/banner_01.png" alt="banner" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="txtBox">
                                    <p className="txtTitle">아임인 파트너스 런칭 프로모션 <br />1달 간 <span>1,000</span>원 수익 적립</p>
                                    <p className={`txtSub ${isLogin ? 'mainLogin' : ''}`}>딱 1달 동안만 1,000원 그냥 드려요!</p>
                                    <div style={{ display: isLogin ? 'none' : 'block' }}>
                                        <a onClick={() => pageMoveA('/myinfo/Join')}>회원가입 하기</a>
                                        <a onClick={() => pageMoveA('/myinfo/Login')}>로그인 하기</a>
                                    </div>
                                </div>
                                <img src="images/png/banner_02.png" alt="banner" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="txtBox">
                                    <p className="txtTitle"><span>정보</span>를 나누고 <br /><span>수익</span>은 더하고</p>
                                    <p className={`txtSub ${isLogin ? 'mainLogin' : ''}`}>아직 아임인을 모르는 사람에게 알려주세요.</p>
                                    <div style={{ display: isLogin ? 'none' : 'block' }}>
                                        <a onClick={() => pageMoveA('/myinfo/Join')}>회원가입 하기</a>
                                        <a onClick={() => pageMoveA('/myinfo/Login')}>로그인 하기</a>
                                    </div>
                                </div>
                                <img src="images/png/banner_03.png" alt="banner" />
                            </li>
                        </ul>
                    </Slider>
                </div>
                <ul className="dots"><Dots /></ul>
            </div>

            <div className="partnersCon_01 partnersCon">
                <img src="images/png/img_section01.png" alt="imin" />
                <div className="txtBox">
                    <p className="txtTitle">아임인 파트너스는 무엇인가요?</p>
                    <p className="txtSub">
                    소셜 핀테크 서비스 ‘아임인’을 홍보하고 수익을 창출할 수 있는 제휴마케팅 플랫폼입니다. <br />
                    아임인 파트너스에서 제공하는 콘텐츠를 활용해 SNS, 카페, 블로그 등 다양한 채널에 <br />
                    홍보하고 신규 가입 또는 서비스 이용으로 전환되는 즉시 수익이 발생합니다.</p>
                    <a className="btnLink" href="javascript:;" onClick={() => pageMoveA('/usage/Guide')}>이용가이드 자세히 보기</a>
                </div>
            </div>
            <div className="partnersCon_02 partnersCon">
                <div className="txtBox">
                    <p className="txtTitle">어떻게 사용하나요?</p>
                    <p className="txtSub">제휴마케팅 플랫폼, 아임인 파트너스 이렇게 이용해 보세요.</p>
                </div>
                <ul>
                    <li>
                        <div>
                            <div>
                                <img src="/images/png/parCon_2-1.png" alt="간편 회원가입" />
                            </div>
                        </div>
                        <p>간편 회원가입</p>
                        <p>간단하게 회원가입만 하고 <br />아임인 파트너스 활동을 할 수 있어요.</p>
                    </li>
                    <li>
                        <div>
                            <div>
                                <img src="/images/png/parCon_2-2.png" alt="홍보 콘텐츠 제작하기" />
                            </div>
                        </div>
                        <p>홍보 콘텐츠 제작하기</p>
                        <p>아임인 파트너스에서 제공하는 콘텐츠로 <br />나만의 포스팅을 만들어보세요.</p>
                    </li>
                    <li>
                        <div>
                            <div>
                                <img src="/images/png/parCon_2-3.png" alt="홍보 콘텐츠 등록하기" />
                            </div>
                        </div>
                        <p>홍보 콘텐츠 등록하기</p>
                        <p>나의 SNS, 카페, 블로그 등에 <br />홍보 콘텐츠를 등록하세요.</p>
                    </li>
                    <li>
                        <div>
                            <div>
                                <img src="/images/png/introduction_01.png" alt="수익 창출" />
                            </div>
                        </div>
                        <p>수익 창출</p>
                        <p>나의 전용링크를 통해 전환되면 즉시 <br />수익이 생겨요.</p>
                    </li>
                </ul>
            </div>
            <div className="partnersCon_03">
                <div className="bgBox">
                    <div className="txtBox">
                        <p className="txtTitle">내가 홍보할 <span>'아임인'</span>은 <br />어떤 서비스인가요?</p>
                        <p className="txtSub">아임인은 사람들이 함께 돈을 모아 매달 정한 순번대로 목돈을 <br />
                        수령하는 온라인 계모임 방식의 소셜 핀테크 서비스입니다.</p>
                        <a className="btnLink" href="javascript:;" onClick={() => pageMoveA('/usage/Introduction')}>서비스 자세히 보기</a>
                    </div>
                    <img src="/images/png/img_device.png" alt="imin" />
                </div>
            </div>
            <div className="partnersCon_04 partnersCon">
                <div className="txtBox">
                    <p className="txtTitle">목돈이 필요한 모든 순간, <br /><span>아임인</span>을 이용하세요!</p>
                </div>
                <ul>
                    <li>
                        <img src="/images/png/ico_f_coin.png" alt="앞 순번 회원" />
                        <div>
                            <p>앞 순번 회원</p>
                            <p>신용점수 영향없이 <br />대출이 필요한 분들에게 추천</p>
                        </div>
                    </li>
                    <li>
                        <img src="/images/png/ico_m_coin.png" alt="중간 순번 회원" />
                        <div>
                            <p>중간 순번 회원</p>
                            <p>소비계획에 맞춰 미리 <br />목돈 마련할 분들에게 추천</p>
                        </div>
                    </li>
                    <li>
                        <img src="/images/png/ico_b_coin.png" alt="뒷 순번 회원" />
                        <div>
                            <p>뒷 순번 회원</p>
                            <p>꾸준하고 안전한 투자 방법을 <br />찾으시는 분들에게 추천</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Main;
