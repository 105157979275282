import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from 'react-query';
import { api } from 'api/api';
import 'common/css/usage/askView.css'
import Laypop from 'components/Laypop';
import Confirmpop from "components/Confirmpop";
import { useRecoilValue } from 'recoil';
import loginInfoState from 'store/State';
import {decodedToken} from 'common/js/func';

const AskView = () => {

    // 로그인 정보 불러오기
    const loginInfoValue = useRecoilValue(loginInfoState);
    const { token} = loginInfoValue;
    const decodeInfoValue = decodedToken(token);
    const { pnidx} = decodeInfoValue;
    // const pnIdx = loginInfoValue.pnidx;
    // const uNm  = loginInfoValue.uNm;

    // 페이지 이동
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
        boardDelUpdate.mutate()
    };
    const closePop2 = () => {
        setPopData(null);
        setPopOpen(false);
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };

    // boardIdx
    const urlParams = new URLSearchParams(window.location.search);
    const idx = urlParams.get('no');
    useEffect(() => {

    }, []);



    /************************
     * 1:1 문의 상세
     ************************/
    const [askData, setAskData] = useState([]);
    useQuery(['askDetail'], async () => {
        const params = {
            params: { boardTp: "P", regIdx: pnidx, showTp: 1 }
        };

        const res = await api.get(`/api/v1/boardAskView/${idx}`, params);
        if (res.status === 200) {
            setAskData(res.data);
        }
    });

    // 수정
    const handlerUpdateClick = () => {
        if(idx != null) {
            pageMoveA(`/usage/AskWrite?no=${idx}`);
        }
    }

    // 삭제
    const handleDeleteClick = () => {
        openPop("삭제하시겠습니까?");
    };
    const boardDelUpdate = useMutation(
        async() => {
            const res = await api.post(`/api/v1/boardDetail/${idx}/boardDelUpdate`, { regIdx: pnidx, delIdx: pnidx })

            // console.log(JSON.stringify(updateBoardData))
            console.log(JSON.stringify(res))

            if (res.status === 200) {
                if(res.data > 0) {
                    pageMoveA('/usage/Ask');
                }
            }
        }
    )

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 문의</span>
                    <span onClick={() => pageMoveA('/usage/Ask')}>1:1문의</span>
                </div>
            </div>
            <section className="subCont askView" id='askView'>
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>나의 문의</h4>
                </div>
                <div className="askViewCont">
                    <div className="askTop">
                        <div><p className="askTitle">{ askData.subj }</p></div>
                        {
                            askData.f_modDate ? (
                                <p className="date">{ askData.f_modDate }</p>
                            ) : (
                                <p className="date">{ askData.f_regDate }</p>
                            )
                        }
                    </div>
                    <div className="askBottom">
                        <p>
                            <p className='askBottomSpan'>내용</p>
                            <span style={{ whiteSpace: 'pre-line' }}>{askData.conts}</span>
                        </p>
                        { askData.answer !== null && (
                            <div className="answer">
                                <div className="answerInfo">
                                    <span>답변</span>
                                    <p className="date">{ askData.answerRegDate }</p>
                                </div>
                                <span className="editorView ck-content" dangerouslySetInnerHTML={{ __html: askData.answer }} />
                            </div>
                        )}
                    </div>
                    <div className="askBtnBox">
                        <a className="btn btnWhite" onClick={() => pageMoveA('/usage/Ask')}>목록으로</a>
                        { (askData.answerStatus === 2 || askData.answerStatus === null) && (
                            <div>
                                <a href="javascript:;" className="btn btnPointLine" onClick={handlerUpdateClick}>수정</a>
                                <a href="javascript:;" className="btn btnRedLine" onClick={handleDeleteClick}>삭제</a>
                            </div>
                        )}
                    </div>
                </div>
                {/* <!-- 알림 팝업 --> */}
                {popOpen && (
                    <Confirmpop data={popData} closePop={closePop} closePop2={closePop2} />
                )}
            </section>
        </>
    )
}

export default AskView;
