import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from 'api/api';
import { useQuery, useMutation } from 'react-query';
import Laypop from "components/Laypop";
import * as CommonFunc from 'common/js/func';
import 'common/css/myinfo/pwInquiry.css'

import ClearInput from "components/InputClear";
const PwInquiry = () => {
    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }
    //전송회수
    const [sendCnt, setSendCnt] = useState(0);
    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
        //  console.log(sendCnt);

        // 전송 횟수가 4번째라면 정보 초기화
        if (sendCnt === 4) {
            window.location.reload('/myinfo/IdInquiry');
        }
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };
    /************************************************** */
    // 인증번호 시간 체크
    const [seconds, setSeconds] = useState(600);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 1) {
                    clearInterval(intervalId);
                    // 추가로 필요한 작업을 수행할 수 있습니다.
                }
                return prevSeconds - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [sendCnt]);

    // 초를 분과 초로 변환
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // 시간을 '09:59' 형식으로 표시
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

    /************************************* */
    /*/
    const [user, setUser] = useState({
        id: 'test4@naver.com',
        nm: '테스트4',
        birth: '19901212',
        mobile: '01012341234'
    })
    /**/
    const [user, setUser] = useState({
        id: '',
        nm: '',
        birth: '',
        mobile: ''
    })
    /**/
    const [authNum, setAuthNum] = useState('');

    const afterField = (fieldName) => (e) => {
        const inputValue = e.target.value;
        // console.log(inputValue);

        setUser((preve) => {

            return {
                ...preve,
                [fieldName]: inputValue,
            };
        });

    };

    const sendMessage = () => {
        // console.log(sendCnt);
        if (!CommonFunc.isValidation('string', user.id)) {
            openPop('아이디를 입력해 주세요.');
            return false;
        }

        if (!CommonFunc.isValidation('string', user.nm)) {
            openPop('이름을 입력해 주세요.');
            return false;
        }
        if (!CommonFunc.isValidation('string', user.mobile)) {
            openPop('휴대폰 번호를 입력해 주세요.');
            return false;
        }

        const requestData = {
            nm: user.nm,
            mobile: user.mobile,
            id: user.id,
        };
        idFind.mutate(requestData);
    }

    const [certNum, setCertNum] = useState([]);

    //인증번호 전송
    const idFind = useMutation(async (requestData) => {
        let res = ""
        if (sendCnt < 3) {
            res = await api.post(`/api/v1/myinfo/isInquiry`, requestData);
        }else{
            res = 999;
        }
        return res;
    }, {
        onSuccess: (res) => {
            if (res.status === 200) {
                openPop('입력하신 휴대폰 번호로 인증번호가 발송되었습니다.');
                //전송시 인증번호 시간 초기화
                setSeconds(600);
                // setSeconds(10);
                setSendCnt(sendCnt + 1);
                setCertNum(res.data);
            }else if (res === 999){
                setSeconds(600);
                openPop('인증번호 재전송 횟수를 초과했습니다.<br />처음부터 다시 시도해 주세요.');
                setSendCnt(sendCnt + 1);
            } else {
                openPop('일치하는 회원정보가 없습니다.');
                // console.log(res.message);
            }
        },
    });

    //비밀번호 찾기
    const inquiry = useMutation(async () => {
        if (seconds < 1 || authNum === '') return false;

        const req = {
            params: { ct_num: authNum, pn_idx: certNum.pn_idx }
        };

        // const res = await api.get(`/api/v1/myinfo/isCert?ctnum=${authNum}&pnidx=${certNum.pn_idx}`, null);
        const res = await api.get(`/api/v1/myinfo/isCert`, req);
        // console.log(res);
        return res;
    }, {
        onSuccess: (res) => {
            if (res.status === 200) {
                // openPop('성공!'); // 임시 ->  //
                pageMoveA(`/myinfo/Result?pnidx=${res.data.pn_idx}&rstp=2`);
            } else {
                if (seconds < 1 || authNum === '') return false;
                openPop('인증번호가 올바르지 않습니다. 확인 후 다시 입력해 주세요.');
            }
        },
    });

    /************************************ */
    const fieldClean = (fieldName, pTp) => {
        if (pTp === 1) {
            setUser((preve) => {
                return {
                    ...preve,
                    [fieldName]: '',
                };
            });
        } else {
        }
    }

    // 포커스 상태관리
    const [isFocus, setIsFocus] = useState({
        nm: false,
        id: false,
        mobile: false,
        auth : false
    });

    const focusChange = (inputName, isFocused) => {
        setIsFocus(prevState => ({
            ...prevState,
            [inputName]: isFocused
        }));
    };

    return (
        <section className="subCont pwInquiry" id='pwInquiry'>
            {/* <!-- 컨텐츠 --> */}
            <div className="mobileBox">
                <h4>비밀번호 찾기</h4>
                <ul>
                    <li className="inputBox">
                        <ul>
                            {/* <!-- ****(유효성 체크 외 하단 문장이 없었다면) input 유효성 검사 후 아래 멘트 출력 시 li에 .ibsInfo클래스 추가 ****  --> */}
                            <li className={'ibsInfo' + (user.id === '' && !isFocus.id ? '' : ' labelInput')}>
                                <ClearInput
                                    value={user.id}
                                    onChange={afterField('id')}
                                    onClear={() => fieldClean('id', 1)}
                                    labelNm={'이메일 (아이디)'}
                                    disableCheck={sendCnt !== 0}
                                    onFocusChange={(isFocused) => focusChange('id', isFocused)} // 포커스 상태 전달
                                />
                            </li>
                            <li className={'ibsInfo' + (user.nm === '' && !isFocus.nm ? '' : ' labelInput')}>
                                <ClearInput
                                    value={user.nm}
                                    onChange={afterField('nm')}
                                    onClear={() => fieldClean('nm', 1)}
                                    labelNm={'이름'}
                                    disableCheck={sendCnt !== 0}
                                    onFocusChange={(isFocused) => focusChange('nm', isFocused)} // 포커스 상태 전달
                                />
                            </li>
                            {/* <li><input type="text" placeholder="이메일 (아이디)" value={user.id} onChange={afterField('id')} disabled={sendCnt !== 0} /></li> */}
                            {/* <li><input type="text" placeholder="이름" value={user.nm} onChange={afterField('nm')} disabled={sendCnt !== 0} /></li> */}
                            <li className="ibsInfo">
                                <div className="phonBox">
                                    <div className={'ibsInfo' + (user.mobile === '' && !isFocus.mobile ? '' : ' labelInput')}>
                                        <ClearInput
                                            value={user.mobile}
                                            onChange={afterField('mobile')}
                                            onClear={() => fieldClean('mobile', 1)}
                                            labelNm={'휴대폰 번호'}
                                            disableCheck={sendCnt !== 0}
                                            onFocusChange={(isFocused) => focusChange('mobile', isFocused)} // 포커스 상태 전달
                                        />
                                    </div>
                                    {/* <input type="text" placeholder="휴대폰 번호" value={user.mobile} onChange={afterField('mobile')} disabled={sendCnt !== 0} /> */}
                                    {sendCnt === 0 ? (
                                        <a href="javascript:;" className="btn btnPointLine" onClick={sendMessage}>인증번호 받기</a>
                                    ) : (
                                        <a href="javascript:;" className="btn btnPointLine" onClick={sendMessage}>재전송 <span>({sendCnt}/3)</span></a>
                                    )}
                                </div>
                                <span className="">"-"없이 입력해주세요.</span>
                            </li>
                            <li className="ibsInfo" style={{ display: sendCnt === 0 ? 'none' : 'block' }}>
                                <div className={'ibsInfo' + (authNum === '' && !isFocus.auth  ? '' : ' labelInput')}>
                                    <ClearInput
                                        value={authNum}
                                        onChange={(e) => setAuthNum(e.target.value)}
                                        onClear={() => setAuthNum('')}
                                        labelNm={'인증번호'}
                                        onFocusChange={(isFocused) => focusChange('auth', isFocused)} // 포커스 상태 전달
                                    />
                                </div>
                                {/* <input type="text" placeholder="인증번호" value={authNum} onChange={(e) => setAuthNum(e.target.value)} /> */}
                                <span>입력하신 휴대폰 번호로 인증번호가 발송 되었습니다.<br />인증번호 6자를 정확히 입력해주세요.</span>
                                <span className="timeLimit">{formattedTime}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                {/* <!-- 활성화 시 btnGray > btnPoint로 변경 --> */}
                <a href="javascript:;" className={"btn btn100 " + (seconds > 0 && authNum !== '' ? "btnPoint" : "btnGray")} style={{ cursor: seconds > 0 && authNum !== '' ? 'pointer' : 'auto' }}
                    onClick={() => inquiry.mutate()}>다음</a>
                <ul className="joinBox">
                    <li><a onClick={() => pageMoveA('/myinfo/IdInquiry')}>아이디 찾기</a></li>
                    <li><a className="join" onClick={() => pageMoveA('/myinfo/Join')}>회원가입</a></li>
                </ul>
            </div>
            {/* <!-- 알림 팝업 --> */}
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        </section>
    )
}

export default PwInquiry;
