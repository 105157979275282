import React, { useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';


const Auth = () => {
    // console.log('PopupPage 팝업');
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const data = params.get('data');
        submitForm(data);
    }, []);
    const submitForm = (data) => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        // form.target = 'popup';

        const inputM = document.createElement('input');
        inputM.type = 'hidden';
        inputM.name = 'm';
        inputM.value = 'checkplusService';

        const recvMethodTp = document.createElement('input');
        recvMethodTp.type = 'hidden';
        recvMethodTp.name = 'recvMethodType';
        recvMethodTp.value = 'get';

        const inputEncodeData = document.createElement('input');
        inputEncodeData.type = 'hidden';
        inputEncodeData.name = 'EncodeData';
        inputEncodeData.value = data; // 여기에 실제 데이터 입력

        form.appendChild(inputM);
        form.appendChild(recvMethodTp);
        form.appendChild(inputEncodeData);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    };

    return (
        <div>
            <h2>인증 진행 중...</h2>
        </div>
    )
}

export default Auth;
