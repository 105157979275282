import React, { useEffect, useCallback } from "react";

const Auth = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const EncodeData = urlParams.get('EncodeData');
    // console.log("부모 창에서 받은 값:", EncodeData);
    const sendToParent = () => {
        //   console.log(EncodeData);
        window.opener.postMessage({ type: 'failData', data: EncodeData }, '*');
    };

    const handleLoad = useCallback(() => {
        // ...기타 필요 작업
        sendToParent();
        window.self.close();
    }, []);

    useEffect(() => {
        handleLoad(); // 정상적인 루트로 진입한 경우 실행된다. 
    }, []);

    return (
        <>
        </>
    )
}

export default Auth;
