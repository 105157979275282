import React from 'react';
import 'common/css/private/private.css';
import 'common/css/policy.css';

const OperationalPolicy = () => {
    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span>홈</span>
                    <span>운영정책</span>
                </div>
            </div>
            <section className="subCont privte policy" id="privte">
                <h4>운영정책</h4>
                <select name="" id="">
                    <option value="">2024.07.01</option>
                </select>
                <h2 style={{marginTop: 0}}>1. 기본 정책</h2>
                <ol className="top-level">
                    <li>본 운영정책은 (주) 티웨이브(이하 “회사”)가 제공하는 아임인 마케팅 제휴 프로그램인 아임인 파트너스(이하 “서비스”)를 운영함에 있어, 아임인 파트너스 이용약관에 따라
                        안전하고 건전한 서비스를 제공하기 위해 서비스 운영 기준과 이용자(이하 “회원”)가 준수해야 할 세부적인 사항을 규정합니다.
                    </li>
                    <li>본 운영정책에서 별도로 정의하지 않은 용어는 “아임인 파트너스” 이용약관에서 정한 용어의 정의에 따릅니다.</li>
                    <li>회사는 더 나은 서비스 제공을 위해 수시로 운영 정책을 변경할 수 있으며, 변경되는 내용을 회원이 쉽게 알 수 있는 방법(홈페이지 공지, 문자메시지 등)으로 최소 7일 전에
                        고지합니다.
                    </li>
                    <li>운영정책에서 적시되지 않은 사항은 회사의 서비스 이용약관과 관계 법령 및 일반적인 상관습에 따라 처리됩니다.</li>
                </ol>

                <h2 className="title">2. 운영 기준 및 특수 정책</h2>
                <ol className="top-level">
                    <li>회사는 약관 및 운영정책에 위배되는 행위를 막고, 원활한 서비스를 제공하기 위하여 최선을 다합니다.</li>
                    <li>회사는 중립적인 입장에서 회원 간 분쟁에 개입하지 않습니다. 다만, 회원 간 분쟁에 약관 및 운영정책을 위반하는 행위나 정상적인 서비스 운영을 방해하는 행위가 연관된 경우
                        중립적인 입장에서 사안에 대해 검토한 후 필요한 조치를 취할 수 있습니다.
                    </li>
                    <li>회사는 법령 및 개인정보취급방침에서 정한 바에 따라 회원의 개인정보 및 신용정보를 안전하게 취급합니다. 회사 및 임직원, 운영자는 약관 및 개인정보취급방침에서 정한 경우를
                        제외하고는 회원의 개인정보나 계좌 정보, 비밀번호 등을 요청하거나 공개하지 않습니다.
                    </li>
                    <li>회사는 고객센터 및 커뮤니티 사이트 등을 통해 서비스 내 발생하는 버그 및 오류에 대한 제보를 받으며, 이를 신속하게 처리하기 위해 노력합니다.</li>
                    <li>회사 및 회사의 임직원, 운영자는 업데이트에 대한 사항, 개발 방향 및 기타와 같은 서비스 특성상 공개가 불가능한 내용에 대해서는 제한적인 답변을 드리거나, 답변을 드리지
                        않을 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">3. 회원 정책</h2>
                <p>“아임인 파트너스”는 다음의 기준에 따라 가입 및 제한, 정보 변경, 탈퇴, 재가입을 할 수 있습니다.</p>

                <h2 className="title">3.1. 가입</h2>
                <ol className="top-level">
                    <li>개인회원(사업자가 아닌 회원)이라면 누구나 아임인 파트너스 서비스 신청이 가능합니다.</li>
                    <li>미성년자나 비영리법인, 면세사업자, 외국인의 경우 신청이 거절될 수 있습니다.</li>
                    <li>가입 신청자의 실명확인 절차를 거쳐 가입할 수 있습니다.</li>
                    <li>이름, 주민등록번호, 휴대전화번호, 은행, 계좌번호는 필수로 입력하여야 합니다.</li>
                    <li>가입 시 추천인코드 입력은 선택 사항이며, 유효한 추천인코드는 1회만 입력할 수 있습니다.</li>
                    <li>1인당 1개의 계정 생성이 가능하며, 주민등록번호 기준 중복가입이 불가합니다.</li>
                    <li>계좌 소유자와 회원의 이름은 일치해야 합니다.</li>
                    <li>가입 완료 시 회원의 추천인 코드가 발급됩니다.</li>
                </ol>

                <h2 className="title">3.2. 가입제한</h2>
                <p className='mgB8'>“아임인 파트너스”는 안정적인 서비스 운영 및 회원의 이익 보호를 위해 다음의 경우 가입을 탈퇴 또는 직권 해지일로부터 일정 기간 동안 제한할 수 있습니다.</p>
                <ol className="top-level">
                    <li>가입신청자가 아임인 파트너스 약관 및 운영정책에 어긋나는 행동을 한 후 자진하여 탈퇴한 적이 있는 경우</li>
                    <li>가입신청자가 아임인 파트너스 약관 및 운영정책에 어긋나는 행동을 하여 직권 해지 된 적이 있는 경우</li>
                </ol>

                <h2 className="title">3.3. 정보변경</h2>
                <p className='mgB8'>회원은 정보변경 페이지에서 본인의 정보를 열람하고 변경할 수 있습니다. 단, 다음에 해당하는 항목은 변경이 제한됩니다.</p>
                <ol className="top-level">
                    <li>아이디, 이름, 주민등록번호는 변경할 수 없습니다.</li>
                    <li>특별한 경우 혹은 개명 및 기타의 사유로 개인 정보를 변경하고자 하는 경우 회사가 요구하는 증빙 제출 및 심사를 통해 정보변경이 가능합니다.</li>
                </ol>

                <h2 className="title">4. 수익 배분</h2>
                <p className='mgB8'>“아임인 파트너스”에서 발생하는 수익은 서비스 이용약관 및 운영정책에 근거하여 적법하게 광고매체에 노출된 광고의 성과에 따라서 회사가 별도로 정한 수익 배분 기준에 따라 회원에게
                    분배됩니다.</p>
                <p>회사는 적립된 수익이 아래에 규정한 조건을 충족하는 경우 회원에게 현금으로 지급함을 원칙으로 하며, 지급 조건 및 지급 시기는 다음과 같습니다.</p>

                <h2 className="title">4.1 수익의 종류</h2>
                <p className='mgB8'>회사는 매체에 노출된 광고 유형에 따른 성과를 측정하며, 이에 따라 배분된 수수료가 회원의 아임인 파트너스 계정에 수익으로 누적됩니다. 이러한 수익은 아래와 같이 분류됩니다.</p>
                <ol className="top-level">
                    <li>추천 수수료: 피추천인(신규가입자)이 추천인(기존가입자) 코드를 넣고 “아임인 파트너스” 또는 “아임인” 서비스에 신규 가입하는 경우 추천인에게 지급하는 수수료를
                        의미합니다.
                    </li>
                    <li>추천인은 별도의 기간 및 횟수 제한이 없이 신규 피추천인이 가입하는 건수마다 지급합니다.</li>
                    <li>아임인 서비스에 신규 가입 시 본인의 아임인 파트너스 추천인 코드를 입력하여 가입하는 경우 추천 수수료가 지급되지 않습니다.</li>
                    <li>아임인 파트너스 서비스 플랫폼에서는 “회원가입 수익”과 동일한 의미를 가집니다.</li>
                    <li>이용 수수료: 본인 이용과 무관하게 회원이 생성한 URL을 통해 아임인 서비스 가입한 신규 계정에서 발생한 아임인 스테이지 최초 이용 금액에 대해 수수료를 의미합니다.
                        <ol className="nested">
                            <li>이용 수수료는 아임인 스테이지 약정금액의 0.2%로 합니다.</li>
                            <li>이용 수수료는 적용 기간은 아임인 신규 계정의 가입일로부터 1년까지만 적용됩니다.</li>
                            <li>등록한 광고매체 URL과 무관하며, 아임인 파트너스 추천인의 코드를 넣고 가입한 아임인 신규 계정에 한합니다.</li>
                            <li>추천인 본인의 링크를 통한 아임인 신규 가입 후 발생한 활동에 대한 이용 수수료는 지급되지 않습니다.</li>
                            <li>이용 수수료는 “아임인”에 신규로 가입한 회원이 참여한 첫 번째 스테이지 효력 개시 후 1회차 입금이 완료된 시점에 지급합니다.</li>
                            <li>아임인 파트너스 서비스 플랫폼에서는 “스테이지 이용 수익”과 동일한 의미를 가집니다.</li>
                        </ol>
                    </li>
                    <li>보너스 수수료: 보너스 적용기간 동안 “추천인” 과 “피추천인” 관계 형성이 되어 있는 경우 이용 수수료 외 추가로 수수료를 의미합니다.
                        <ol className="nested">
                            <li>아임인 파트너스 신규 가입 시 추천인 코드를 입력 후 가입하는 경우에만 ‘기존 회원’과 ‘신규 회원’ 사이에 “추천인”-“피추천인” 관계가 형성되며, 시스템
                                상 관계 형성이 되어 있는 경우에만 보너스 수수료가 지급됩니다.
                            </li>
                            <li>추천인 보너스 수수료: 피추천인이 생성한 URL을 통해 아임인 서비스 가입한 신규 계정에서 발생한 아임인 스테이지 최초 이용 금액의 0.1%를 추가
                                지급합니다.
                            </li>
                            <li>피추천인 보너스 수수료: 회원이 생성한 URL을 통해 아임인 서비스 가입한 신규 계정에서 발생한 아임인 스테이지 최초 이용 금액의 0.1%를 추가 지급합니다.
                            </li>
                            <li>보너스 적용 기간은 피추천인의 가입일로부터 30일까지로 하며, 해당 기간이 종료된 후에는 보너스 수수료가 지급되지 않습니다.</li>
                            <li>보너스 수수료는 “아임인”에 신규로 가입한 회원이 참여한 첫 번째 스테이지 효력 개시 후 1회차 입금이 완료된 시점에 지급합니다.</li>
                            <li>아임인 파트너스 서비스 플랫폼에서는 “추천인 수익”과 동일한 의미를 가집니다.</li>
                        </ol>
                    </li>
                    <li>기타 수수료: 회사와 제휴사에서 주최하는 단/장기 이벤트, 추가 적립 서비스 등 마케팅 활동 등과 관련하여 추가로 지급하는 수수료를 의미합니다.
                        <ol className="nested">
                            <li>아임인 파트너스 서비스 플랫폼에서는 “프로모션 수익”과 동일한 의미를 가집니다.</li>
                        </ol>
                    </li>
                </ol>

                <h2 className="title">4.2 수익의 적립 및 지급</h2>
                <p className='mgB8'>아임인 파트너스 회원의 계정에서 일정 금액 이상의 수익이 발생한 경우, “회사”는 해당 수익을 회원에게 지급하기 위해 아임인 심사를 진행합니다. 심사 결과 이상이 없는 경우 회사는
                    정산된 수익을 확정하고 “회원”에게 지급합니다.</p>
                <ol className="top-level">
                    <li>최소 지급액: 수익 지급이 가능한 최소 금액은 2,000원입니다.
                        <ol className="nested">
                            <li>회원의 계정에 적립된 수익이 해당 금액 미만인 경우에는 정산 및 지급이 진행되지 않습니다.</li>
                            <li>관련 법령에서 정해진 바에 따라 개인회원의 확정된 적립 수익은 원천징수소득세 3.3%가 징수된 후의 금액으로 지급됩니다.</li>
                        </ol>
                    </li>
                    <li>수익 정산 기간: 매월 1일부터 말 일까지 기간 동안 발생한 수익에 대해 정산하며, 매월 수익 정산은 총 1회 진행됩니다.</li>
                    <li>지급 심사: 회사는 회원의 적립 수익에 대하여 지급 여부를 심사합니다. 아래 사유에 해당될 경우에는 수익 지급이 이월될 수 있으며, 차후 도래하는 정기 지급일에 재심사가
                        가능합니다.
                        <ol className="nested">
                            <li>실제 지급 과정에서 유효하지 않은 계좌 정보, 증빙 서류 불충분 등으로 지급이 실패한 경우</li>
                            <li>수익 지급에 요구되는 정보의 확인을 위한 인증 정보가 부족하거나, 회원명과 예금주명이 일치하지 않는 경우</li>
                            <li>정산 받을 수익이 2,000원 미만인 경우</li>
                        </ol>
                    </li>
                    <li>정기 지급일: 지급 심사가 완료되면, 최종 정산된 수익 금액을 도래하는 익월 25일에 회원이 지정한 계좌로 지급합니다.
                        <ol className="nested">
                            <li>‘정기 지급일’이 주말 또는 공휴일일 경우 그 이후에 도래하는 영업일에 지급됩니다.</li>
                            <li>명절 등 장기 휴일로 인해 지급일 변동이 불가피할 경우 회원들에게 공지 후 변경될 수 있습니다.</li>
                        </ol>
                    </li>
                    <li>지급 형태: 개인회원이 아임인 파트너스 서비스 플랫폼의 회원정보에 입력한 계좌에 현금을 입금하여 지급합니다.</li>
                    <li>수익의 만기: 회원에게 적립된 수익의 만기가 도래할 때까지 지급 완료되지 않은 경우, “회사”는 해당 금액을 소멸시킬 수 있습니다. 수익의 만기는 확정 수익 발생 월을
                        포함하여 6개월입니다.
                    </li>
                    <li>“아임인 파트너스” 회원의 홍보활동으로 인하여 “아임인”에 가입하는 회원 중 기존 계정 탈퇴 후 재가입 하는 경우 ‘아임인 신규 회원’으로 분류되지 않으며, 이에 따라 수익
                        정산 시 수수료를 지급받을 수 없습니다.
                    </li>
                    <li>수수료 지급은 측정된 실적 기준일의 당일 자동 적립되나, 회사 및 일부 제휴사의 요구하는 방법에 의해 추후에 적립될 수도 있습니다. 이 경우 회사는 당사 및 제휴사 서비스
                        홈페이지 등에서 확인이 가능하도록 안내합니다.
                    </li>
                    <li>수수료 지급은 활동 실적이 확인되는 즉시 적립하는 것을 원칙으로 하나, 회사 및 제휴사가 공식적으로 허용한 기한이 있는 경우 회원은 본인의 활동내역에 한하여 그 기간내
                        활동이 이루어진 활동 기록을 제시함으로써 소급하여 적립을 청구할 수 있습니다. 회사나 제휴사는 내부정책에 따라 수수료 소급 적립 청구 기간 변경이 가능하고 경우에 따라
                        회원의 소급 청구를 거부할 수 있습니다.
                    </li>
                    <li>서비스 이용에 따라 산정 부여되거나 프로모션으로 인해 추가 적립되는 포인트 중 소수점 이하의 단위는 절사되고, 당사나 제휴사가 별도로 정한 방식에 따라 별도의 수수료
                        지급율이 적용되거나 수수료가 지급되지 않을 수 있습니다. 이 경우 수수료가 적립되지 않는 방식 혹은 수단임을 별도로 서비스 이용 시 안내합니다.
                    </li>
                    <li>수수료 지급율(액) 및 기준은 회사에서 정한 기준에 따르며, 회사의내부 방침에 따라 임의로 변경될 수 있습니다.</li>
                </ol>

                <h2 className="title">5. 부당 수익 지급 보류ㆍ거절 및 회수</h2>
                <ol className="top-level">
                    <li>회원이 아임인 파트너스 약관, 운영정책 또는 관계법령 등을 위반하거나 미디어의 방문자가 약관, 운영정책 또는 관계법령에서 금지하는 행위를 하여 회원에게 발생한 수익을 '부당
                        수익'으로 판단합니다.
                    </li>
                    <li>아임인 파트너스는 회사의 자체적인 점검 등을 통해 부당 수익으로 의심되는 정황이 있는 경우 확인 시까지 지급을 보류할 수 있으며 보류 후 부당한 수익이 발생한 사실이 확인된
                        경우 회원에 대한 수익의 지급을 거절할 수 있습니다. 또한 해당 부당 수익(이미 지급된 수익 포함)을 회원으로부터 회수할 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">6. 회원의 의무와 권리</h2>
                <ol className="top-level">
                    <li>회원은 회사의 서비스 이용약관 및 본 운영정책을 지켜야 하며, 이를 위반할 시 서비스 이용 제한을 받을 수 있습니다.</li>
                    <li>회원은 가입 시 본인의 정보로 가입을 해야 하며, 타인의 개인정보를 도용하거나 허위 정보를 등록하여 발생하는 피해에 대해 회사로부터 보호를 받을 수 없습니다. 또한,
                        개인정보 도용으로 인한 문제에 책임져야 합니다.
                    </li>
                    <li>회원은 회사의 사전 승낙 없이 서비스가 제공하는 콘텐츠를 이용하여 영리행위를 하실 수 없습니다.</li>
                    <li>회원은 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사/복제/변경/번역/출판/방송 등의 기타 방법으로 사용하거나, 이를 타인에게 제공할 수 없습니다.
                    </li>
                    <li>서비스 취약점 (버그, 계정 도용, 불법 프로그램 등)을 발견했을 경우 회원센터(1:1 문의 등)를 통해 신고 및 제보해 주셔야 하며, 이를 이용, 악용하거나 타인에게
                        공유하는 등의 방법을 통해 이득을 얻는 경우에는 본 운영정책에서 정하는 제재를 받을 수 있습니다.
                    </li>
                    <li>회원이 회사가 공식적으로 제공하지 않는 프로그램을 사용하여 자신에게 문제가 발생한 경우, 그 결과에 대해 책임져야 합니다</li>
                    <li>회원은 서비스 이용 중 부당한 대우 또는 문제가 발생했다고 판단하는 경우, 회원센터 등의 공식 경로를 통해 문의하고 이의를 제기할 수 있습니다.</li>
                    <li>회원의 이의제기가합리적이라고 판단될 경우 회사는 즉시 회원에게 적용된 제재를 해제하고 조정된 수익 등을 조정 복구합니다.</li>
                </ol>

                <h2 className="title">7. 운영자 역할과 활동</h2>
                <ol className="top-level">
                    <li>운영자는 (주)티웨이브 및 “아임인 파트너스” 개인정보처리방침 및 관계 법령을 준수하며, 회원의 개인정보(계정, 비밀번호 등)을 묻지 않습니다.</li>
                    <li>운영자는 서비스 내 특정 회원에게 혜택을 제공하지 않으며, 공개적으로 알려진 서비스 관련 사항 외에 업데이트 혹은 이벤트 등의 정보를 제공하지 않습니다.</li>
                    <li>운영자는 회원의 정상적인 활동이나 회원 간의 사적인 분쟁에 개입하지 않는 것을 원칙으로 합니다. 단, 아래와 같은 경우에는 해당 행위를 경고하거나 서비스 이용을 제한할 수
                        있습니다.
                        <ol className="nested">
                            <li>비인가 프로그램 사용, 타인의 서비스 이용방해 등 원활한 서비스 이용에 부정적 영향을 주는 경우</li>
                            <li>사적인 분쟁이라고 하더라도 불특정 다수의 회원에게 피해를 입히는 경우</li>
                            <li>회원 간의 분쟁으로 게임 질서를 어지럽히거나 실정법을 위반하는 행동을 하는 경우</li>
                            <li>플랫폼 경제 등 선량한 회원들이 공유하는 플랫폼 질서를 파괴하는 행위</li>
                        </ol>
                    </li>
                    <li>운영자는 도용이나 해킹 시도가 감지될 경우 회원의 계정 또는 서비스 데이터 손상을 방지하기 위해 사전 안내 없이 필요한 조치를 적용하여 계정과 서비스 데이터를
                        보호합니다.
                    </li>
                    <li>운영자는 고객센터로 접수된 오류, 버그뿐 만 아니라 서비스 내 존재하는 모든 항목을 확인하고 수정하기 위해 노력합니다.</li>
                    <li>운영자는 서비스 내/외적으로 비정상적인 현상이 발생할 경우, 서비스의 일부 혹은 전부를 일시적으로 중단하거나 접근을 제한할 수 있습니다.<br/> 이 경우, 운영자는 공식
                        홈페이지
                        게시판 등 서비스 공지를 통해 신속하게 안내하며, 회원이 입을 수 있는 피해를 예방하기 위해 최선을 다합니다.
                    </li>
                    <li>운영자는 위에서 언급되지 않은 경우라도 서비스 운영과 회원의 서비스 이용을 방해하는 상황이 발생한다면, 운영자과 회원이 공유하는 일반적인 질서와 규범에 따라 적절한 조치를
                        취하여 원활한 서비스 환경과 회원을 보호하기 위해 최선을 다합니다.
                    </li>
                </ol>

                <h2 className="title">8. 이용제한</h2>
                <ol className="top-level">
                    <li>아임인 파트너스는 원활한 서비스 운영을 방해하거나 서비스 환경을 파괴하는 모든 행위를, 회원과 운영자가 공유하는 일반적인 질서와 규범에 따라 신속하게 대응하여 다른 회원을
                        보호하기 위해 적절히 조치할 수 있습니다.
                    </li>
                    <li>아임인 파트너스는 회원의 미디어가 서비스 이용약관 또는 운영정책에 위반되는 사항이 없는지 주기적으로 확인하며, 위반 사항이 확인되는 경우 이용제한 정책에 따라 회원의 서비스
                        이용을 일시적 또는 영구적으로 제한하고 있습니다.
                    </li>
                    <li>아임인 파트너스는 대한민국을 대상으로 서비스를 제공하고 있으며, 비인가프로그램, 해킹 등의 우려가 있다면 대한민국 이외 국가에서의 접속과 계정 생성, 일부 콘텐츠 이용을
                        제한할 수 있습니다.
                    </li>
                    <li>운영정책에 명시된 기준을 위반하는 경우 서비스 이용에 제한을 받을 수 있으며, 회사는 이용제한 조치를 하는 경우 회원에게 사전 안내합니다.<br/>다만, 원활한 서비스
                        운영을
                        방해하거나 서비스 환경을 파괴하는 등 긴급히 조치할 필요가 있는 경우에는 사후에 안내할 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">8.1. 회원 계정에 대한 이용 제한</h2>
                <ol className="top-level">
                    <li>계정의 생성이나 이용 과정에서 관련 법령, 관련 약관 또는 운영정책 위반 사항이 발견된 경우, 계정의 생성 이용·탈퇴가 제한될 수 있습니다</li>
                    <li>회원이 등록한 미디어에서 이용 제한 사유가 지속적으로 발생하거나, 서비스 운영에 악영향을 미칠 경우 회원의 계정에 대해 서비스 이용을 제한할 수 있습니다.</li>
                    <li>운영정책에 구체적으로 해당하지 않는 사항이라고 하더라도 건전한 서비스 환경 제공에 악영향을 끼치거나 다른 이용자에게 불편을 끼치는 행위도 이용 제한될 수 있습니다.</li>
                    <li>계정을 비정상적으로 생성이용·탈퇴하려는 시도가 발견되어 제3자에 의한 계정 도용 및 악용 피해가 발생할 가능성이 있다고 판단되는 경우, 회사는 해당 계정에 보호 조치를
                        적용하여 다음과 같은 조치가 취해질 수 있습니다.
                        <ol className="nested">
                            <li>추가적인 본인 확인 절차를 거치도록 함</li>
                            <li>해당 계정의 이용 및 탈퇴를 제함</li>
                            <li>해당 계정의 비정상적 생성이 계속될 수 없도록 계정 생성 제한</li>
                            <li>필요할 경우 해당 계정을 삭제하고 재가입을 제한하는 등의 기타 적절한 제한</li>
                        </ol>
                    </li>
                    <li>이용 제한은 한시적 제한에서 영구적 제한으로 단계적으로 제한되지만, 즉시 영구적으로 제한을 할 수 있습니다.</li>
                    <li>회원의 계정이 '이용 제한' 이 되면 회원이 등록한 모든 미디어에 대한 광고의 노출 및 누적 수익의 지급이 중지됩니다.<br/> 명확히 하자면, '이용 제한'된 회원과
                        사실상 동일한
                        회원으로 볼 수 있는 계정이 존재하는 경우, 연관 계정 전체에 대해 서비스 이용 제한 등 제반 조치를 취할 수 있습니다.
                    </li>
                    <li>이용 제한 해제는 '이용 제한' 사유를 확인 또는 수정하신 후 '이용 제한 해제 요청'을 하시면, 운영정책에 따라 이용 제한 해제 가능 여부를 검토하여 처리합니다.</li>
                    <li>단, 다음의 내용이 확인될 경우, 회원의 계정은 '영구정지' 상태가 되며, 전체 게시물에 대한 광고의 게재 및 누적 수익의 지급이 영구히 중지됩니다.
                        <ol className="nested">
                            <li>회원의 계정이 '이용 제한' 상태에서 탈퇴하는 경우</li>
                            <li>아임인의 관계자임을 사칭하는 행위를 하거나 시도한 경우</li>
                            <li>반복적인 버그 악용 및 자동화된 프로그램을 통해 인위적으로 클릭을 발생시키는 경우</li>
                            <li>허가되지 않은 스팸메일 발송, 쿠키 및 IP 조작, 불법 또는 사전에 협의되지 않은 S/W 활용, 유사/오타 도메인을 사용하는 행위 등</li>
                            <li>기타 선의의 이용자와 회사에 심각한 피해를 끼치거나 끼칠 우려가 있는 경우</li>
                        </ol>
                    </li>
                    <li>제7조에 의거 이용이 제한된 이력이 있는 회원의 계정은 온타임/점검 보상, 리워드 및 아이템 지급, 이벤트 당첨 등에서 제외될 수 있으며, 이용제한 기간동안 지급받지 못한
                        수익이나 서비스 이용과 관련된 피해에 대해서는 도움 드리지 않습니다.
                    </li>
                    <li>이용제한 기준과는 별개로, 반복적이거나 과도한 정책 위반으로 원활한 서비스 운영 및 질서를 위해 필요하다고 판단되는 경우에는 ‘영구정지’ 등 적절한 조치를 취할 수
                        있습니다.
                    </li>
                    <li>제3자의 주민등록번호, 계정 등을 도용하는 행위는 관련 법률에 의거하여 형사처벌의 대상이 될 수 있습니다.</li>
                    <li>회사는 회원의 개인정보(주민등록번호, 신상정보 등)를 요구하지 않습니다. 운영자 및 회사 관계자를 사칭한 개인정보 유출에 주의하시기 바랍니다.</li>
                    <li>회원은 본인의 계정을 스스로 관리해야 하며, 타인이 회원 계정의 비밀번호를 주기적으로 변경하여 계정 도용 등 타인의 부정한 행위로부터 회원의 정보를 보호할 수 있습니다.
                        비밀번호 관리를 소홀히 하여 발생하는 불이익은 회사가 책임지지 않습니다.
                    </li>
                </ol>

                <h2 className="title">8.2 제재 종류 및 제재기준</h2>
                <p>[표1] 이용제한(제재) 기준에 대한 용어 정의</p>
                <table style={{border: '1px solid black',}}>
                    <thead>
                    <tr style={{border: '1px solid black',}}>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>유형</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>상세 설명</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>통합계정 영구정지</td>
                        <td style={{border: '1px solid black',}}>아임인 파트너스 서비스뿐만 아니라 회사가 제공하는 모든 서비스의 이용을 영구적으로 제한합니다.
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>서비스 영구정지</td>
                        <td style={{border: '1px solid black',}}>영구적으로 아임인 파트너스 서비스 및 관련 서비스의 이용을 제한하는 제재입니다.</td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>서비스 임시정지</td>
                        <td style={{border: '1px solid black',}}>일정 기간 동안 아임인 파트너스 서비스 및 관련 서비스의 이용을 제한하는 제재입니다.</td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>계정 보호조치</td>
                        <td style={{border: '1px solid black',}}>비정상 접속/서비스 이용 의심 내역이 확인된 계정의 보호를 위해 확인 완료 시점까지 이용이 임시로
                            이용을 제한하는 제재입니다.
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>경고</td>
                        <td style={{border: '1px solid black',}}>서비스 임시 접속 제한, 광고 및 수익 제한, 운영자 주의, 권고 등 일시적으로 이루어지는
                            제재입니다. <br/>서비스 내 의사소통을 이용하여 주의 사항 또는 금지 사항을
                            안내합니다.
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>포인트 등 조정</td>
                        <td style={{border: '1px solid black',}}>약관위반 행위와 관련된 계정 정보∙포인트∙유료콘텐츠 등을 삭제하거나 수량∙성질을 변경하는
                            제재입니다.
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>IP 차단</td>
                        <td style={{border: '1px solid black',}}>특정 IP에서의 서비스 접속 및 이용을 제한하는 제재입니다.</td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>디바이스 이용제한</td>
                        <td style={{border: '1px solid black',}}>사용 중인 디바이스(기기)에서의 접속을 일정 기간 동안 제한하는 제재입니다.</td>
                    </tr>
                    </tbody>
                </table>
                <ol className="top-level">
                    <li style={{marginTop: 10}}>제재 사유 및 제재 내용에 대해서는 제7조 3항의 이용제한 사유 항목을 따릅니다.
                        <ol className="nested">
                            <li>제재기준표가 정한 제제 사유가 발생하더라도, 약관위반 행위 당시의 구체적 상황이나 서비스에 미치는 영향 정도를 고려하여 제재하지 않거나 제재기준보다 더 약하게
                                제재할 수 있습니다.
                            </li>
                            <li>동시에 2가지 이상의 운영정책 위반 행위가 확인된 경우, 이용제한 수위가 높은 항목이 적용되거나 각 행위에 해당하는 제재를 아울러 매길 수 있습니다.</li>
                        </ol>
                    </li>
                    <li>회원 계정이 ‘이용제한’이 되면 제재 종류에 따라 등록한 모든 미디어에 대한 광고의 노출 및 누적 수익의 지급이 중지될 수 있습니다.</li>
                    <li>회사가 회원 계정에 대하여 제재를 할 때 약관위반행위와 관련된 이익(홍보수익, 포인트 등)을 회수하거나 조정할 수 있으며, 버그 악용, 불법 프로그램 사용 등 관련 이익
                        산정이 어려운 약관위반행위에 대해서는 서비스 이용정보를 초기화하거나 일부 콘텐츠 이용 및 접속 환경을 제한할 수 있습니다.
                    </li>
                    <li>회원의 귀책 사유로 인한 계정도용 또는 계정공유 중에 발생한 약관위반행위에 대한 모든 책임은 약관위반행위 당사자 및 계정 명의자인 회원이 함께 부담하며, 양 당사자 모두의
                        계정에 제재가 적용될 수 있습니다.
                    </li>
                    <li>회사는 다른 회원의 약관위반 행위에 가공하거나 공모한 회원 또는 약관위반 행위를 돕거나 그로 인해 부당한 이득을 취한 회원에 대해서도 해당 약관위반 행위에 대한 제재를
                        적용할 수 있습니다. 다른 회원이 약관위반 행위로 획득한 이익을 그 사정을 알면서도 취득할 경우 또는 그 사정을 알지 못하고 무상으로 취득할 경우 회사는 관련 이익을 회수할
                        수 있습니다.
                    </li>
                    <li>제7조의 이용제한에 기재되지 않은 사항이라 하더라도 약관 및 관계 법령에 위배되는 행위에 대해서는 그 경중에 따라 제재가 적용될 수 있습니다.</li>
                    <li>회원과 서비스의 보호를 위해 제재 누적 차수 또는 각 위반 항목의 경중에 따라 가중 적용 및 사법 조치가 함께 진행될 수 있습니다.</li>
                </ol>

                <h2 className="title">8.3. 제한 사유</h2>

                <h2>8.3.1. 기술적 금지 행위</h2>
                <p className='mgB8'>서비스의 안정적인 운영을 위해 어떠한 경우에도 다음의 행위를 금지하고 있습니다.</p>
                <ol className="top-level">
                    <li>자사의 서버에 부하를 가하는 행위</li>
                    <li>자사가 전송하는 광고의 링크, 형태, 갱신 주기 및 이에 포함된 정보에 대한 별도의 조작 행위</li>
                    <li>자사가 서비스 운영을 위해 수집하는 정보를 변경 또는 변형하는 행위</li>
                    <li>자사가 지정한 가이드를 따르지 않거나 기타 부정한 방법으로 서비스 운영에 악영향을 미치는 행위</li>
                    <li>불법프로그램 자체나 그 사용법을 특정인 혹은 불특정 다수를 대상으로 널리 알리거나 배급, 알선하는 행위</li>
                </ol>

                <h2>8.3.2. 경제적 손해를 끼치는 행위</h2>
                <p className='mgB8'>아임인 파트너스 광고에 대한 클릭은 방문자의 관심으로 발생한 것을 전제로 합니다.</p>
                <p className='mgB8'>광고의 효과와 관계없이, 맹목적으로 광고 콘텐츠를 클릭하게 하거나 모바일 애플리케이션을 설치 실행하게 하고, 회원가입을 하게 함으로써, 아임인의 광고비를 소진시켜 경제적 손해를 끼칠
                    수 있는 아래의 행위들을 포함합니다.</p>
                <ol className="top-level">
                    <li>회원 본인 또는 다른 회원의 미디어에 게재된 광고를 지속하여 반복적으로 클릭하는 행위</li>
                    <li>회원 본인 또는 다른 회원의 미디어에 게재된 광고를 방문자가 클릭하도록 유도하기 위한 문구, 도형, 이미지 등을 미디어 내에 포함하는 행위
                        <ol className="nested">
                            <li>"돈 벌수 있도록 도와주세요" 등의 문구 삽입</li>
                            <li>방문자의 성적 호기심을 불러일으킬 수 있는 선정적이거나 자극적인 이미지 등을 사용하여 광고에 대한 비정상적인 호기심을 발생시키는 경우</li>
                            <li>콘텐츠에 맹목적 관심을 유발할 목적으로, 잘못된 정보를 포함한 선정적이거나 자극적인 문구 또는 이미지를 사용하는 경우</li>
                        </ol>
                    </li>
                    <li>메일, 메신저, SMS 등을 이용하여 클릭을 요청하는 메시지를 발송하는 행위</li>
                    <li>로봇, 자동화된 클릭 및 노출 생성 도구, 자동 웹 브라우징, 클릭 교환 프로그램 등을 사용하여 인위적으로 클릭을 발생시키는 행위</li>
                    <li>자사가 제공하는 광고URL를 사이트 또는 앱의 백그라운드에 숨겨 호출하는 행위</li>
                    <li>방문자의 액션을 유도하는 버튼 등을 광고 영역과 매우 근접하게 위치시키거나 위젯, 레이어 등을 이용하여 방문자의 오클릭을 유도하는 행위</li>
                    <li>유료 제품 또는 서비스를 무료체험이 가능한 것으로 오인시켜서 무의미한 클릭설치·참여를 유발하는 행위</li>
                    <li>기타 다양한 부정한 방법을 사용하여 광고에 대한 클릭을 반복시키는 행위</li>
                    <li>기타 영리 목적을 위하여 개인 혹은 다수의 계정 또는 조직적으로 회사 및 서비스 프로세스를 악용하는 행위</li>
                </ol>

                <h2>8.3.3. 특정 콘텐츠 노출 및 게재를 유도하는 행위</h2>
                <p className='mgB8'>인위적으로 특정 광고를 노출시키거나 게재를 유도하는 다음과 같은 행위는 이용제한 사유로 규정하고 있습니다.</p>
                <ol className="top-level">
                    <li>광고 유도를 목적으로 미디어의 내용과 관계 없는 무의미한 키워드를 삽입하는 행위</li>
                    <li>“아임인” 혹은 “아임인 파트너스” 콘텐츠 내용과 전혀 상관없는 별개 콘텐츠로 검색 또는 SNS 노출량을 극대화한 뒤 내용을 변경하는 방식의 행위</li>
                    <li>콘텐츠 내용과 전혀 상관없는 동영상으로 SNS 노출량을 극대화하면서, 합당한 효과를 일으키지 않는 무의미한 클릭설치·참여를 유발하는 행위</li>
                    <li>프로그램을 이용하여 인위적으로 특정 광고를 노출시키거나 노출을 유도하는 행위</li>
                    <li>해당 게시물과 관계없는 브랜드 및 제품명을 언급하여 제3자에게 피해를 끼치거나 끼칠 우려가 있는 행위</li>
                    <li>기타 다양한 부정한 방법을 통해 광고의 노출 또는 게재를 유도하는 행위</li>
                </ol>

                <h2>8.3.4. 부적합 콘텐츠의 게시 행위</h2>
                <p className='mgB8'>회원은 회원이 운영하는 미디어에 다음에 명시하고 있는 부적합 콘텐츠를 포함하거나 관련 콘텐츠가 포함된 링크를 게재해서는 안되며,<br/> 이러한 콘텐츠에 광고가 노출되지 않도록 항시 주의하여
                    관리하여야 합니다. <br/>브랜드 가치 훼손이 동반되는 부적합 콘텐츠가 확인되는 경우 이용약관 제21조에 따라 서비스 이용을 즉시 제한하고 있으며, 이를 책임지고 삭제하여야
                    합니다.</p>
                <p>[표2] 부적합 콘텐츠 종류</p>
                <table style={{border: '1px solid black',}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>상세 유형</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>상세 설명</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>개인정보 노출 게시물</td>
                        <td style={{border: '1px solid black',}}>타인의 개인정보(성명, 주민등록번호, 주소, 전화번호, 사진, 영상 등에 의하여 특정한 개인을
                            알아볼 수 있는 정보)를 본인의 동의 없이 게시하는 내용
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>지식재산권 위반 게시물</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>아임인, 아임인 파트너스를 사칭하여 하는 모든 활동</li>
                                <li>아임인, 아임인 파트너스 키워드를 활용한 검색광고, 도메인 이름 등록, 보유, 사용</li>
                                <li>사전승인 없이 아임인 로고를 무단 사용하는 경우</li>
                                <li>저작권자의 동의 없이 방송, 음원, 영화, 소설, 게임, 만화 등 저작물을 복제, 배포, 전송하는 경우</li>
                                <li>음원 첨부/스트리밍, 방송사 로고가 포함되어 있는 동영상 등을 포함한 게시물</li>
                                <li>불법 다운로드 사이트로의 링크를 제공하는 게시물</li>
                                <li>복제 S/W 판매, 프리서버 홍보 등</li>
                                <li>기타 타인의 상표권, 디자인권 등 지식 재산권을 무단으로 침해하는 경우</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>영리추구/홍보성 게시물</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>전문적/상설적으로 금전적 거래를 발생시키거나 이를 알선하는 내용</li>
                                <li>현금이나 상품, 또는 비금전적 무형의 혜택을 대가로 지급하는 내용</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>불법성 게시물</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>범죄 관련 내용을 미화, 권유, 조장하는 내용</li>
                                <li>살인을 청탁하거나 이를 권유, 유도 및 매개하는 내용</li>
                                <li>주민등록번호생성기, 카드번호생성기, 아이템생성기 등 불법자료를 언급, 이용, 배포하는 경우</li>
                                <li>불법 제품을 이용자에게 판매하거나 소개하는 경우</li>
                                <li>불법적인 해킹 정보를 유통하는 경우</li>
                                <li>불법 다단계 영업행위, 유사 수신 등 허위사실이나 불법적 내용을 게시하는 경우</li>
                                <li>불법성 게시물을 게재하고 있는 타 사이트로 링크를 제공하는 경우</li>
                                <li>자살을 미화, 권유하거나 자살방법을 적시하는 내용, 동반자살을 유도하는 내용</li>
                                <li>도박 등의 사행심을 조장하는 내용</li>
                                <li>악성코드, 바이러스 등 이용자의 서비스 환경을 저해하는 프로그램 및 검색 어뷰징 등 회사의 업무를 방해하는 프로그램을 수록하거나 설치를 유도, 배포하는
                                    경우
                                </li>
                                <li>시체 사진 등 혐오감을 주는 이미지를 수록하거나 해당 이미지로 링크시키는 경우</li>
                                <li>존속에 대한 상해 폭행 살인 등 전통적인 가족윤리를 훼손할 우려가 있는 내용</li>
                                <li>성폭력, 마약복용 등 퇴폐적 행위를 자극하거나 미화하는 내용</li>
                                <li>성폭력, 강간, 윤간 등 성범죄를 구체적/사실적으로 묘사하거나 미화한 내용</li>
                                <li>매춘, 사이버섹스, 노골적인 성적 대화 등 성적 유희 대상을 찾거나 매개하는 내용</li>
                                <li>음란정보 또는 퇴폐업소가 있는 장소를 안내 또는 매개하는 내용</li>
                                <li>타인의 신체를 본인의 의사에 반해 촬영 및 게시하여 성적 수치심을 유발하는 경우</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>음란성 게시물 및 기타 사회 윤리적으로 허용될 수 없는 게시물</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>남녀의 성기, 국부, 음모 또는 항문이 노출되거나 투명한 의상으로 속살이 비치는 내용</li>
                                <li>착의 상태라도 남녀의 성기 등이 지나치게 강조되거나 근접 촬영되어 윤곽 또는 굴곡이 드러난 내용</li>
                                <li>이성 또는 동성간의 정사, 구강성교, 애무, 자위행위 등 성행위를 직/간접적으로 묘사한 내용</li>
                                <li>수간, 혼음, 성고문 등 변태성욕을 묘사한 내용</li>
                                <li>어린이 또는 청소년을 성 유희의 대상으로 묘사한 내용</li>
                                <li>남녀의 성기를 저속하게 표현한 내용</li>
                                <li>불륜관계, 근친상간 등 반인륜적 성행위를 자세히 소개하거나 흥미 위주로 묘사한 내용</li>
                                <li>매춘 등 불법 성행위를 정당하게 묘사한 내용</li>
                                <li>음란하거나 선정적인 게시물을 게재하고 있는 타 사이트로 링크를 제공하는 경우</li>
                                <li>스와핑 등 사회 윤리적으로 용납되지 않은 행위를 매개하는 경우</li>
                                <li>청소년 유해약물 등의 효능 및 제조방법 등을 구체적으로 기술하여 그 복용 제조 및 사용을 조장하거나 이를 매개하는 내용</li>
                                <li>청소년에게 불건전한 교제를 조장할 우려가 있거나 이를 매개하는 내용</li>
                                <li>지나치게 선정적이거나 혐오스러운 내용</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>기타</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>특정 단어/문구를 반복 삽입하는 등의 비정상적인 게시물</li>
                                <li>타인의 개인정보 노출 유도, 관리자를 사칭하는 내용</li>
                                <li>사실과 다르거나, 과장∙왜곡∙오인의 가능성이 있는 내용을 표시하는 등 표시광고법 기타 관련 법령에 위반되는 내용</li>
                                <li>가상화폐 관련 내용</li>
                                <li>행운의 편지글, 스팸글, 홍보글 등의 도배성 내용</li>
                                <li>기타 관련 법령에 위반되거나 건전한 미풍양속을 저해하는 내용</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h2>8.3.5. 다른 사용자들에게 피해를 주는 행위</h2>
                <ol className="top-level">
                    <li>기만적인 수단을 사용하여 “아임인 파트너스” 또는 “아임인”의 다른 사용자를 속이거나 잘못된 정보를 제공함으로써 피해를 주는 일체의 행위</li>
                    <li>다른 회원이 정성 들여 작성한 홍보 문구를 무단으로 도용해 자신의 광고에 사용함으로써 다른 사용자를 불쾌하게 하는 행위</li>
                </ol>
                <h2>8.3.6. 서비스 운영을 방해하는 행위</h2>
                <ol className="top-level">
                    <li>회사, 회사의 직원이나 관계자 또는 운영자를 사칭하는 행위</li>
                    <li>“아임인 파트너스” 또는 “아임인” 사업을 방해할 목적으로, 아임인 파트너스 개인정보처리 시스템, 홍보하기 시스템 등 사업과 관련한 일체의 시스템을 교란하거나 공격하는
                        일체의 행위
                    </li>
                    <li>“아임인 파트너스” 또는 “아임인”의 브랜드 가치를 훼손하는 소란이나 논란을 야기하는 행위</li>
                    <li>아임인 파트너스와 사전에 협의되지 않은 내용으로 개인정보를 수집하여 수익을 발생시키는 행위</li>
                    <li>블로그, 페이스북 페이지, 인스타그램 등에 타 사용자들이 “아임인 파트너스” 공식 홈페이지 또는 SNS 채널로 오인할 사이트를 구축해 운영하는 행위</li>
                    <li>아임인 파트너스의 ‘광고 콘텐츠’ 유효성 검증을 위해 필요한 각종 자료의 요청에 대해 불응하거나 허위의 자료를 제공하는 행위</li>
                    <li>아임인 파트너스의 운영 또는 광고 효과 유지를 위해 연락을 하거나 협조를 요청할 시 뚜렷한 사유 없이 불응하거나 협조하지 않는 행위</li>
                    <li>광고 매체에서 금지한 단어를 사용하거나 게시를 금지한 사이트에 콘텐츠를 올리는 등 홍보 콘텐츠 등록 시 아임인 파트너스의 요청 사항들을 지키지 않는 행위</li>
                    <li>게시판이나 고객상담서비스를 악용하여 다른 이용자의 정상적인 게임 이용에 지장을 초래하거나 회사의 정상적인 서비스 운영을 방해하는 행위</li>
                    <li>아임인 파트너스 운영자에 의해서 블랙리스트 유저로 분류되어 활동이 정지되었음에도 불구하고, 기만적인 수단을 통하여 아임인 파트너스의 사용자로 활동하는 행위</li>
                </ol>

                <h2>8.3.7. 브랜드 가치를 훼손하는 행위</h2>
                <ol className="top-level">
                    <li>타인에 대한 욕설ㆍ비방으로 인해 방문자에게 피해를 주거나 불쾌감을 줄 수 있는 내용</li>
                    <li>인스타그램, 유튜브, 페이스북, 블로그 등 각종 사이트 및 SNS 상에서 타인을 사칭하여 홍보하는 게시물</li>
                    <li>“아임인” 경쟁 브랜드 커뮤니티에 홍보를 하여 브랜드 명성을 저하시키거나 피해를 입히는 내용 및 게시물</li>
                    <li>홈페이지, 블로그, 페이스북 페이지, 인스타그램 등 각종 사이트 및 SNS 채널을 위조해 브랜드 가치를 훼손하는 하는 경우</li>
                    <li>불필요한 논란을 일으키거나 브랜드 및 서비스에 대하여 잘못된 정보를 제공함으로써 브랜드 가치를 훼손시키는 일체의 행위</li>
                    <li>“아임인 파트너스” 또는 “아임인” 브랜드 가치를 훼손하는 문구 및 이미지를 이용하여 광고 콘텐츠를 등록하는 행위</li>
                </ol>

                <h2>8.3.8. 광고 매체로써의 기능 저하</h2>
                <p className='mgB8'>아임인 파트너스 회원은 자신의 미디어가 광고매체로서 기능을 다하도록 꾸준히 관리하고 노력하여야 합니다. <br/>회원의 미디어가 광고매체로서의 기능이 현저히 떨어진다고 판단되면 서비스 이용을
                    제한할 수 있습니다. 단, 회원 스스로 광고 게재를 중지한 경우는 제외합니다.</p>
                <ol className="top-level">
                    <li>해당 미디어에서 무효 클릭이 일정 기준 이상으로 지속적으로 발생하는 경우</li>
                    <li>언론 매체, 댓글 또는 이용후기 등에서 미디어 또는 회원에 대한 다수의 부정적인 여론이 확인되는 경우
                        <ol className="nested">
                            <li>예시1) 커뮤니티에 무분별한 홍보를 시도함으로써 해당 커뮤니티 관리자 또는 회원들로부터 항의를 받는 경우</li>
                            <li>예시2) 뉴스 또는 커뮤니티 사이트 등에서 내용상 전혀 상관없는 콘텐츠에 무분별하게 댓글을 달아 타 사용자들을 불쾌하게 하는 경우</li>
                        </ol>
                    </li>
                    <li>콘텐츠가 업데이트 되지 않거나 관리가 되지 않아 서비스 이용가치가 심각하게 저하된 경우</li>
                    <li>정상적인 미디어 접근 및 서비스 이용이 불가능한 경우(블로그 초기화, 호스팅 만료, 기능 이상 등)</li>
                    <li>미디어 고유의 콘텐츠보다 “아임인 파트너스” 또는 “아임인” 광고를 포함한 기타 광고성 콘텐츠의 비중이 압도적으로 큰 경우</li>
                    <li>기타 이용자 관련 분쟁 등으로 인해 홍보 콘텐츠 노출이 부적절한 경우</li>
                </ol>

                <h2>8.3.9. 광고 미디어 권한의 재판매 행위</h2>
                <p>회원은 회사로부터 부여 받은 광고 미디어로서의 권한을 회사의 서면 동의 없이 타인 또는 타 매체에 공유, 제공, 양도, 중개, 재 판매 등을 할 수 없습니다.</p>

                <h2 className="title">9. 제재 신고</h2>
                <p className='mgB8'>약관 위반 행위에 대한 제재∙신고 절차는 아래와 같습니다</p>
                <ol className="top-level">
                    <li>약관 위반행위 → (신고) → 운영자 조사 → (사전고지) → 제재 → 이의 → 이의 처리 결과 통보</li>
                    <li>신고후 운영자 조사에는 영업일 기준 약 7일이 소요되며, 이의에 대한 재조사는 최대 2주의 기간이 소요됩니다.
                        <ul style={{paddingLeft: 15}}>
                            <li>단, ‘계정도용’은 영업일 기준 평균 15일이 소요될 수 있습니다.</li>
                        </ul>
                    </li>
                    <li>회사는 이용자의 신고를 접수한 후 48시간 내에 진행 상황에 대한 답변을 드리기 위해 노력합니다. <br/>단, 사실 관계 파악에 시간이 소요될 경우 답변 기한은 영업일
                        기준 최대
                        7일까지 연장될 수 있습니다.
                    </li>
                    <li>회사는 제재 시 제재 내용, 제재 사유, 이의 절차 등에 대하여 이메일, 문자메시지 등을 통하여 사전 고지합니다. <br/>단, 약관에서 별도로 정한 일부 행위에 대해서는
                        사후고지로
                        대체할 수 있습니다.
                    </li>
                    <li>계정도용 신고는 계정도용 피해 발생일로부터 영업일 기준 15일까지 가능합니다.</li>
                </ol>

                <h2>9.1. 신고∙문의 방법 및 이의제기</h2>
                <ol className="top-level">
                    <li>신고∙문의 및 이의제기 방법</li>
                    <li>1:1 문의: 회원은 이용자는 서비스 페이지의 1:1 문의를 통해 아임인 파트너스 서비스를 포함하여 계정과 관련한 각종 문의∙요청∙건의, 제재와 관련된 이의제기를 할 수
                        있습니다.
                    </li>
                    <li>전화/채팅 상담:
                        <ol className="nested">
                            <li>운영 시간: 평일 08:00 ~ 17:00 (점심시간 12:00 ~ 13:30 / 주말&공휴일 제외)</li>
                            <li>전화 상담: 02-583-95442</li>
                            <li>채팅 상담: <a style={{color: 'blue'}}
                                          href={"https://imin-partners.channel.io"}>imin-partners.channel.io</a></li>
                        </ol>
                    </li>
                    <li>회사는 회원의 문의, 건의 등에 대하여 성실하게 답변해 드리고자 노력합니다. 다만, 접수된 문의의 양과 내용에 따라 부득이하게 답변이 지연될 수 있음을 양해 바랍니다.
                    </li>
                </ol>

                <h2 className="title">10. 보증 및 책임</h2>

                <h2>10.1. 보증범위</h2>
                <ol className="top-level">
                    <li>아임인 파트너스 서비스는 관련 법률이 허용하는 한도 내에서 어떠한 사항의 보증도 없이 ‘있는 그대로’ 또는 ‘이용 가능한 상태로’ 제공됩니다.</li>
                    <li>예를 들어 (주)티웨이브는 “아임인 파트너스” 서비스에 관해 어떠한 상업성, 양호한 품질, 숙련된 작업 능력, 특정 목적 적합성, 신뢰성, 정확성, 바이러스 부재, 조용한
                        환경 유지, 제3자 권리나 기타 권리에 대한 비침해 등을 포함한 구체적인 약정이나 법률적인 보증을 하지 않습니다. 단, 관련 법률에 따라 묵시적 보증의 배제 또는 제한이
                        허용되지 않는 경우는 제외됩니다.
                    </li>
                </ol>
                <h2>10.2. 책임범위</h2>
                <ol className="top-level">
                    <li>회원이 “아임인 파트너스” 서비스에서 제공되는 콘텐츠나 기능을 사용하면서 발생한 손실은 (주)티웨이브의 고의 또는 중대한 과실로 인한 경우가 아니라면 책임이 없습니다.
                    </li>
                    <li>손실의 범위는 특별한 사유가 없이 발생한 오류, 누락, 중단, 결함, 작동이나 전송의 지연, 컴퓨터 바이러스, 연결 실패, 네트워크 비용 발생 등이며, 이로 인한 기타 모든
                        직접적, 간접적, 특별한, 우발적, 징벌적, 결과적 손해 등을 포함합니다. 하지만, 관련 법률에 따라 손실의 배제 또는 제한을 허용하지 않는 경우는 제외됩니다.
                    </li>
                </ol>

                <h2>10.2.1. 회사의 귀책사유로 인한 손실</h2>
                <ol className="top-level">
                    <li>서비스의 기술상 오류로 계정 및 리워드가 소실되거나 정보가 변경된 경우, 현재 정보 통신서비스의 기술상 서비스 내 기록 확인이 가능하다면 서비스에 무리를 주지 않는 한도
                        내에서 복구하여 드립니다.
                    </li>
                    <li>회사는 서비스 기획이나 운영상 판단을 통해 회원에게 사전 고지 후 회원 계정 및 리워드 정보를 수정∙변경∙삭제할 수 있으며 개별 복구는 불가능합니다.
                        <ol className="nested">
                            <li>다만, 약관 제15조 제4항 단서에 해당하는 사유가 있는 경우 사후에 공지할 수 있습니다.</li>
                            <li>유료 콘텐츠의 경우도 서비스 기획이나, 운영상 필요하다고 판단될 경우(리워드 금액 등) 수정, 변경, 삭제될 수 있고, 이때에 회사는 서비스 내에서 사용할 수
                                있는 비슷한 가치의 다른 리워드로 회원에게 보상하여야 하며, 회원은 그러한 회사의 조치와 관련하여 별도의 손해배상 청구를 할 수 없습니다.
                            </li>
                        </ol>
                    </li>
                    <li>회사의 귀책사유로 인하여 이용자가 4시간 이상 유료서비스를 이용할 수 없었을 경우 회사는 이용할 수 없었던 정액상품과 기간 정량 상품 이용시간의 3배에 해당하는 서비스
                        이용시간을 무료로 연장해 드리며, 기간제 유료 콘텐츠를 이용할 수 없었던 경우 그 기간만큼의 기간제 유료 콘텐츠 이용시간을 연장해 드립니다.
                        <ol className="nested">
                            <li>단, 회원의 귀책사유도 일부 있는 경우 및 본 제재 적용 전 임시제한의 경우 1배에 해당하는 서비스 이용시간 연장. 회원이 유료서비스를 이용할 수 없었던
                                기간이 3일 이상이거나 1개월 동안 72시간을 초과하는 경우 이용자는 계약을 해지하고 잔여 기간제 서비스 이용요금을 환급 받을 수 있습니다.
                            </li>
                        </ol>
                    </li>
                </ol>

                <h2>10.2.2. 회원의 과실로 인한 손실</h2>
                <ol className="top-level">
                    <li>회원 스스로의 과실이나 서비스 시스템, 운영 정책에 명시된 내용, 서비스 내 공지한 내용, 홈페이지에 공지한 내용 등을 숙지하지 못해 발생하는 손실은 원칙적으로 복구나
                        손해배상 대상이 아닙니다.
                    </li>
                    <li>사기 등 상대방의 약관위반 행위에 의한 리워드/계정 손실에 대해서도 회원 스스로의 과실이 결합된 경우 리워드/계정 복구는 불가능합니다.</li>
                </ol>

                <h2 className="title">11. 복구정책</h2>
                <ol className="top-level">
                    <li>아임인 파트너스 운영자 및 임직원은 회사의 귀책사유로 인하여 서비스 내 수익 및 콘텐츠가 유실된 경우 복구를 위해 최선의 노력을 다합니다.</li>
                    <li>회원은 서비스 이용 중 직접 선택 및 진행하여 유실된 계정, 수익, 리워드, 콘텐츠 등은 별도로 복구가 불가능합니다.</li>
                    <li>피해 복구 접수 후, 서비스 데이터에 대한 복구는 조사가 완료되어 피해로 판단될 경우 피해 계정을 대상으로 복구가 이루어 집니다.</li>
                    <li>서비스 기술상의 오류가 아닌 상황의 피해, 회원의 과실이나 고의에 의한 손실은 복구가 불가능하며, 아래 각 호에 해당하는 내용을 포함하여 계정 또는 그 외 데이터가 확인되지
                        않을 경우에는 피해에 대한 복구를 지원받을 수 없습니다.
                        <ol className="nested">
                            <li>회원의 환경에 의해 발생하는 문제나 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 발생한 피해</li>
                            <li>운영정책, 공식 홈페이지 및 커뮤니티 공지, 서비스 내 공지 등을 통해서 안내된 내용에 대하여 숙지하지 못하여 발생한 피해</li>
                            <li>피해 회원의 부주의나 관리 소홀로 인하여 계정 상의 문제가 발생하였을 경우</li>
                            <li>서비스 제공자의 과실에 대하여 서비스 이용 회원의 데이터에 기반한 객관적인 자료 확보가 되지 않는 경우</li>
                        </ol>
                    </li>
                    <li>계정도용으로 인해 수익 및 리워드의 손실이 발생한 경우, 고객센터 문의 접수를 통해 일부 항목의 복구가 가능할 수도 있으며 아래 내용에 따라 복구 가능 여부 확인이
                        진행됩니다.
                        <ol className="nested">
                            <li>복구 가능 여부 확인을 위해 피해 발생 15일 이내에 문의 접수가 진행되어야 하며, 피해 발생 15일이 지난 후 문의가 접수된 경우에는 복구가
                                불가능합니다.
                            </li>
                            <li>계정 도용 피해 조사 및 복구 가능 여부 확인이 진행될 때 계정의 임시 제한 조치가 진행될 수 있으며, 조사가 완료된 이후 복구 가능한 항목에 대해 안내가
                                진행됩니다.
                            </li>
                        </ol>
                    </li>
                    <li>피해 복구 접수 후, 서비스 데이터에 대한 복구는 조사가 완료되어 피해로 판단될 경우 피해 계정을 대상으로 복구가 이루어 집니다.</li>
                    <li>서비스 이용 기록을 통해 객관적인 자료가 확보된 후 진행됩니다. 만일, 회원이 유실되었다고 신고한 데이터가 서비스 기록 내에 존재하지 않거나, 데이터 등 객관적인 자료를
                        통해 확인되지 않는 경우 복구가 불가능합니다.
                    </li>
                    <li>계정 복구는 계정 유실로 인한 회원의 불편함, 상실감을 최소화 하고 서비스 이용 만족도를 높이는 데 그 목적이 있습니다. 따라서, 제공되는 복구 접수 및 서비스를 고의적으로
                        악용하여 이득을 취하려는 의도가 확인될 경우 복구 서비스는 중단되며 서비스 이용이 영구제한 될 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">12. 장기 미접속 계정</h2>
                <ol className="top-level">
                    <li>아래 조건들을 모두 만족하는 계정을 ‘장기 미접속 계정’이라 정의 합니다.
                        <ol className="nested">
                            <li>마지막 아임인 파트너스 접속일로부터 1년 이상 경과</li>
                        </ol>
                    </li>
                    <li>회사는 서비스 개선과 원활한 서비스 운영을 위하여 ‘장기 미접속 계정’ 내 잔여 수익이 없는 계정 정보를 삭제할 수 있습니다.</li>
                    <li>장기 미접속 계정 내 정보 삭제가 이루어질 경우 회사는 해당 일정 및 내용을 사전 고지합니다.</li>
                </ol>

                <h2 className="title">13. 회원탈퇴</h2>
                <ol className="top-level">
                    <li>회원은 원하는 경우 언제든 아임인 파트너스 서비스의 탈퇴를 신청하실 수 있습니다. 단, 정산이 완료되지 않은 수익이 있는 경우 정산 절차가 완료된 후 탈퇴 처리가
                        완료됩니다. 다만, 회원은 잔여 수익 및 전환 예정 금액 상당을 포기함으로써 즉시 탈퇴를 요청할 수 있으며, 회원이 잔여 수익 및 전환 예정 금액 상당을 포기하더라도 회원의
                        탈퇴 요청 시점에 따라 수익의 지급 여부가 변동될 수 있습니다.
                    </li>
                    <li>회원이 탈퇴를 신청하면 신청 즉시 광고의 게재가 중지되며, 탈퇴 신청일까지의 수익 잔액이 존재하는 경우 지급하지 않습니다.</li>
                    <li>부당한 수익의 회수로 인해 회원의 수익 잔액이 0원 미만이 되는 경우 탈퇴 시 해당 금액을 회사에 변제해야 합니다.</li>
                    <li>자진탈퇴를 하는 경우 탈퇴 완료부터 언제든지 재가입이 가능합니다.</li>
                    <li>관련법령 및 “아임인 이용약관” 위반으로 “아임인” 계정이 탈퇴 처리되는 경우 “아임인 파트너스”도 즉시 탈퇴 처리됩니다. 이 경우, “아임인 파트너스”는 자진탈퇴로
                        처리되며, 수익지급 및 재가입, 이용제한 기록 승계에 대한 기준은 자진탈퇴와 동일한 기준이 적용됩니다.
                    </li>
                    <li>“아임인 파트너스” 회원탈퇴는 “아임인” 탈퇴와는 무관합니다.</li>
                    <li>단, 타인의 명의를 도용하여 탈퇴 처리되는 경우에는 재가입 및 서비스 이용제한 기록의 승계 기준이 적용되지 않고, 수익 잔액이 지급되지 않으므로 타인의 명의를 도용하여
                        가입하는 일이 없도록 해야 합니다.
                    </li>
                </ol>

                <h2 className="title">14. 상담사의 인권보호 및 업무방해</h2>
                <p>[표3] 상담사 인권침패 및 업무방해 유형</p>
                <table style={{border: '1px solid black',}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>유형</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>유형 상 내용</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>이용제재 기간(일)</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>1차</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>2차</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>3차</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>4차</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>불건전 언어 사용 (비매너 행위)</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>a. 상담 시 게임과 무관한 내용으로 욕설, 성적 단어 사용, 인격침해, 성희롱, 위협적 표현을 사용하는 경우</li>
                                <li>b. 상담 시 지속적인 욕설, 성적 단어 사용, 인격침해, 성희롱, 위협적 표현을 사용하는 경우</li>
                                <li>c. 상담 시 성적 수치심이나 혐오감을 줄 수 있는 이미지, 동영상, 링크를 보내는 경우</li>
                                <li>d. 상담 시 사적 만남을 요구하거나 성적 농담을 포함하는 경우</li>
                                <li>e. 상담 시 추가 안내가 불가한 상황이나 안내 자체가 불가한 내용에 대해 지속적인 상담 요구를 통해 업무를 방해하는 경우</li>
                            </ul>
                            <p>※ 상담은 '1:1 문의, 전화/채팅 상담'을 모두 포함합니다.</p>
                            <p>※ 위 행위를 포함하지 않는 문의 상담은 불편이 없도록 정상적으로 진행합니다.</p>
                        </td>
                        <td style={{border: '1px solid black',}}>경고 및 상담 중단</td>
                        <td style={{border: '1px solid black',}}>3일</td>
                        <td style={{border: '1px solid black',}}>7일</td>
                        <td style={{border: '1px solid black',}}>15일</td>
                    </tr>
                    </tbody>
                </table>
                <ol className="top-level" style={{marginTop: 10}}>
                    <li>회사는 서비스 문의 상담을 진행하는 과정에서 상담사의 인권보호 및 원활한 서비스 업무 진행을 위해 아래와 같이 경고를 진행하고 서비스 이용제한 조치를 진행할 수 있으며 상담
                        중단 및 상담 제한을 할 수 있습니다.
                    </li>
                    <li>회사는 상담사의 인권 침해나 업무 방해의 경중에 따라 사전 경고 없이 상담을 중단하거나 서비스 이용제한 및 가중된 제재를 진행할 수 있습니다.</li>
                </ol>

                <h2 className="title">[부칙]</h2>
                <p>제1조 (시행일) 본 약관은 2024년 7월 1일부터 시행합니다.</p>

            </section>
        </>
    )
}

export default OperationalPolicy;
