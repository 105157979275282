import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const loginInfoState = atom({
    key: 'loginInfoState',
    default: {
        pnidx: '', // 파트너스 idx
        id: '', // 이메일
        unm: '', // 유저이름
        nnm: '', // 닉네임
        pncd: '', // 파트너스 코드
        imcd: '', // 아임인 코드
        rmcd: '', // 추천인 코드
        rmpnidx: '', // 추천인 idx
        token: '', // 토큰
        isLogin: false, // 로그인 유무
        regDate: '', // 가입일
        loginExpiration: (new Date()).toISOString(), // 로그아웃 시간
    },
    effects_UNSTABLE: [persistAtom],
});

export default loginInfoState;