import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import { ko } from 'date-fns/esm/locale';
import { format, eachDayOfInterval } from 'date-fns';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Pagination from 'components/Pagination';
import * as CommonFunc from 'common/js/func';

import { useRecoilValue } from 'recoil';
import loginInfoState from 'store/State';


import 'common/css/report/performance.css';
import 'react-datepicker/dist/react-datepicker.css';

const Performance = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    //regDate 가입일 
    // const { pnidx, regDate, unm, token, rmcd, isLogin, loginExpiration } = loginInfoValue;
    const {token} = loginInfoValue;
    const decodeInfoValue = CommonFunc.decodedToken(token);
    const { pnidx, regDate} = decodeInfoValue;

    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    //페이지 이동
    const performanceView = async (pVal, pTp) => {
        movePage('/report/PerformanceView', { state: { date: pVal, tabTp: pTp } });
    }

    //카운트 관련
    /****************************************************/
    const [totalCnt, setTotalCnt] = useState(0);
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호

    /****************************************************/
    const [tabTp, setTabTp] = useState(1);
    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    const paraGetTab = parseInt(urlParams.get("tab"));

    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
        !paraGetTab ? setTabTp(1) : setTabTp(paraGetTab);

        onList();
    }, [paraGetPage, paraGetTab])

    // 페이지 이동
    const changePages = (page) => {
        // setPageNum(page);
        movePage(`?page=${page}&tab=${tabTp}`);
    }

    // 탭 선택
    const changeTab = (key) => {	
        movePage(`?page=${1}&tab=${key}`);
    };


    /*********************/
    //usequry 검색용
    const [isSearch, setIsSearch] = useState(true);
    const [isList, setIsList] = useState(true);

    // Enter 입력이 되면 클릭 이벤트 실행
    const handleOnKeyDown = e => {
        if (e.key === 'Enter') {
            performReload(); 
        }
    };

    const performReload = () => {
        movePage(`?page=${1}&tab=${tabTp}`);
        onList();
        setIsSearch(!isSearch);
    }

    //리스트 검색
    const onList = () => {
        setIsList(!isList);
    };

    /*********************** */
    /* 검색 날짜 */
    const [startDate, setStartDate] = useState(new Date(CommonFunc.timeStampCustom(3))); // default 이번달 1일
    const [endDate, setEndDate] = useState(new Date(CommonFunc.timeStampCustom(4)));// today
    const [monthDate, setMonthDate] = useState(''); 

    const selStartDate = (date) => {
        setStartDate(date);
        //setEndDate(date);
    };

    useEffect(() => {
        setIsList(!isList);
    }, [monthDate])

    // 총카운트
    const [listUseDate, setListUseDate] = useState({
        u_s_date: '',
        u_e_date: '',
        cg_c_fee:0,
    })

    // 총카운트
    const [listTotal, setListTotal] = useState({
        tt_r_cnt: 0,
        tt_pn_cnt: 0,
        tt_mb_cnt: 0,
        tt_s_fee: 0,
        tt_pn_fee: 0,
    })

    // 리스트
    const [listData, setListData] = useState([]);
    const [dateRange, setDageRange] = useState([]);

    useQuery(['performCnt', isList],
        async () => {
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
        	const sMonthDate = (!monthDate ? regDate : (monthDate > regDate.substring(0, 4) ? monthDate + '-01-01' : regDate));         
       		const eMonthDate = (!monthDate ? CommonFunc.timeStampCustom(4): (CommonFunc.timeStampCustom(4).substring(0, 4) > monthDate ? monthDate + '-12-31' : CommonFunc.timeStampCustom(4)));
       		
            const req = {
                // 일별실적:(sDate, eDate), 월별실적 : (sMonthDate, eMonthDate)
                params: { sdate: tabTp === 1 ? (sDate > regDate ? sDate : regDate) : sMonthDate, edate: tabTp === 1 ? eDate : eMonthDate, partnerIdx: pnidx, pftp: tabTp }
            };

            const res = await api.get(`/api/v1/Perform/performListTotal`, req);
            // console.log(res.data);
            if (res.status === 200) {
                setListTotal(res.data);
                if (tabTp === 1) {
                    DateListAdd();
                }
            }
        });

    useQuery(['performMemUseDate', isSearch],
        async () => {
            //console.log(tabTp);
            const req = {
                params: { partnerIdx: pnidx }
            };
            const res = await api.get(`/api/v1/Perform/performMemUseDate`, req);
            //console.log(res);
            if (res.status === 200) {
                setListUseDate(res.data);
            }
        });

    useQuery(['performList', isList],
        async () => {
            setListData([]);
            // console.log(tabTp);
            const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
            const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
        	const sMonthDate = (!monthDate ? regDate : (monthDate > regDate.substring(0, 4) ? monthDate + '-01-01' : regDate));
       		const eMonthDate = (!monthDate ? CommonFunc.timeStampCustom(4): (CommonFunc.timeStampCustom(4).substring(0, 4) > monthDate ? monthDate + '-12-31' : CommonFunc.timeStampCustom(4)));
      		
            const req = {
                // 일별실적:(sDate, eDate), 월별실적 : (sMonthDate, eMonthDate)
                params: { sdate: tabTp === 1 ? (sDate > regDate ? sDate : regDate) : sMonthDate, edate: tabTp === 1 ? eDate : eMonthDate, partnerIdx: pnidx, pftp: tabTp, page: pageNum - 1, size: pageRowCnt }
            };
    		// console.log(req);
            const res = await api.get(`/api/v1/Perform/performList`, req);
            // console.log(res.data);
            if (res.status === 200) {
                setListData(res.data.content);
                setTotalCnt(res.data.totalElements);
            }
        });

    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])
    
    // 년도 SelBOX
    const YearSel = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: currentYear - 2024 + 1 }, (_, index) => currentYear - index);
        return (
            <>
                <option value="">전체현황</option>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}년
                    </option>
                ))}
            </>
        )
    }

    // 보너스 젹용 기간
    const BonusDateFormat = (psDate, peDate, pchangefee) => {
		let BonusSdate = CommonFunc.formatDate(psDate, 'YYYYMMDD');
		let BonusEdate = CommonFunc.formatDate(peDate, 'YYYYMMDD');
		let nowDate = CommonFunc.formatDate(new Date(CommonFunc.timeStampCustom(4)), 'YYYYMMDD');	

        if (psDate != '' && peDate != '') {
            // if (true) {
			if (parseInt(nowDate) <= parseInt(BonusEdate) && parseInt(nowDate) >= parseInt(BonusSdate))
            return (
                <>
                    <ul>
                        <li>보너스 적용기간 : {psDate} ~ {peDate}</li>
                        <li>해당 기간에 아임인 이용 금액의 {pchangefee}%(보너스 수익)를 추가 지급합니다.</li>
                    </ul>
                </>
            )
            else {
	            return (
	                <>
	                    {''}
	                </>
	            )				
			}
        }
         else {
            return (
                <>
                    {''}
                </>
            )
        }
    }    
    
    //엑셀 다운로드
    const Excelinput = async () => {
//		console.log("excel");
        const sDate = (!startDate ? '' : CommonFunc.formatDate(startDate, 'YYYY-MM-DD'));
        const eDate = (!endDate ? '' : CommonFunc.formatDate(endDate, 'YYYY-MM-DD'));
        const sMonthDate = (!monthDate ? regDate : (monthDate > regDate.substring(0, 4) ? monthDate + '-01-01' : regDate));          
       	const eMonthDate = (!monthDate ? CommonFunc.timeStampCustom(4): (CommonFunc.timeStampCustom(4).substring(0, 4) > monthDate ? monthDate + '-12-31' : CommonFunc.timeStampCustom(4)));
       		
        const requestData = {
            sdate: (tabTp === 1 ? (sDate > regDate ? sDate : regDate) : sMonthDate), edate: (tabTp === 1 ? eDate : eMonthDate), partnerIdx: pnidx, pftp: tabTp, page: 0, size: 99999 
        }
        // console.log(requestData);
        const res = await api.get(`/api/v1/Perform/excel/performList`,{
            responseType: 'blob', // 바이너리 데이터로 응답 받기
            params: requestData
        });
       // console.log(res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '실적 리포트.xlsx'); // 실적 내역
        document.body.appendChild(link);
        link.click();
    }    
    
    const BonusDate = () => {
        let DateCheck = false;
        const today = new Date(CommonFunc.formatDate('', 'YYYY-MM-DD'));
        if (listUseDate.u_s_date !== '' && listUseDate.u_e_date !== '') {
            DateCheck = today >= new Date(listUseDate.u_s_date);
            DateCheck = today >= new Date(listUseDate.u_s_date);
        }
        return (
            <p>
                보너스 적용기간 : {listUseDate.u_s_date} ~ {listUseDate.u_e_date}
            </p>
        )
        if(DateCheck){
            return (
                <p>
                    보너스 적용기간 : {listUseDate.u_s_date} ~ {listUseDate.u_e_date}
                    <span>해당 기간에 아임인 이용 금액의 0.1%(보너스 수익)를 추가 지급합니다.</span>
                </p>
            )
        }else{
            <></>
        }
    }


    // 가입일, 이용일 변환
    const RDateFormat = (pDate) => {
        // console.log(pDate);
        if (!pDate) {
            return (
                <>
                </>
            )
        } else {
            pDate = pDate.split(' ');
            return (
                <>
                    {pDate[0].replaceAll('-', '. ')}
                </>
            )
        }
    }

    /**
     * 날짜 범위 배열 생성
     */
    const DateListAdd = () => {
        const sDate = moment(!startDate ? CommonFunc.formatDate(CommonFunc.timeStampCustom(3), 'YYYY.MM.DD') : CommonFunc.formatDate(startDate, 'YYYY.MM.DD'));
        const eDate = moment(!endDate ? CommonFunc.formatDate(CommonFunc.timeStampCustom(4), 'YYYY.MM.DD') : CommonFunc.formatDate(endDate, 'YYYY.MM.DD'));
        const dateRange = [];
        let currentDate = sDate.clone();
        while (currentDate.isSameOrBefore(eDate)) {
            dateRange.push(currentDate.format('YYYY.MM.DD')); // 포맷 변경
            currentDate.add(1, 'days');
        }
        setDageRange(dateRange);
    }

    /**
     * 일별 실적현황 리스트
     * */
    const PerfomListData = () => {
        /*/ 
        return (
            <>
                {Array.isArray(listData) ? (
                    listData.length > 0 ? (
                        dateRange.map((date, index) => {
                            const dataForDate = listData.find(item => item.r_date === date);
                            // console.log(dataForDate);
                            return (
                                <tr key={index} onClick={() => performanceView(date, tabTp)}>
                                    <td>{RDateFormat(date)}</td>
                                    <td>{dataForDate ? CommonFunc.numberWithCommas(dataForDate.r_cnt) : 0}</td>
                                    <td>{dataForDate ? CommonFunc.numberWithCommas(dataForDate.mb_cnt) : 0}</td>
                                    <td>{dataForDate ? CommonFunc.numberWithCommas(dataForDate.s_fee) : 0}</td>
                                    <td>{dataForDate ? CommonFunc.numberWithCommas(dataForDate.pn_fee) : 0}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan="6" className="noData">검색 결과가 없습니다.</td></tr>
                    )
                ) : null}
            </>
        );
        /*/
        return (
            <>
                {Array.isArray(listData) ? (
                    totalCnt > 0 ? (
                        listData.map((item, index) => {
                            return (
                                <tr key={index} onClick={() => performanceView(item.r_date, tabTp)}>
                                    <td>{RDateFormat(item.r_date)}</td>
                                    <td>{CommonFunc.numberWithCommas(item.r_cnt)}</td>
                                    <td>{CommonFunc.numberWithCommas(item.pn_cnt)}</td>
                                    <td>{CommonFunc.numberWithCommas(item.mb_cnt)}</td>
                                    <td>{CommonFunc.numberWithCommas(item.s_fee)}</td>
                                    <td>{CommonFunc.numberWithCommas(item.pn_fee)}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan="6" className="noData">검색 결과가 없습니다.</td></tr>
                    )
                ) : null}
            </>
        );
        /**/
    };

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/report/Performance')}>리포트</span>
                    <span onClick={() => pageMoveA('/report/Performance')}>실적 리포트</span>
                </div>
            </div>
            <section className="subCont Performance" id="Performance">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>실적 리포트</h4>
                    <p>회원님의 홍보하기 활동을 통해 발생한 수익을 확인해 보세요. 아래 표를 클릭하면 상세 내역을 확인할수 있어요.</p>
                    <p>{BonusDateFormat(listUseDate.u_s_date, listUseDate.u_e_date, listUseDate.cg_c_fee)}</p>
                </div>
                <div className="tabBox">
                    <ul>
                        <li className={tabTp === 1 ? 'active' : ''}><a href="javascript:;" onClick={() => changeTab(1)}>일별 실적</a></li>
                        <li className={tabTp === 2 ? 'active' : ''}><a href="javascript:;" onClick={() => changeTab(2)}>월별 실적</a></li>
                    </ul>
                </div>
                <div>
                    {/* <!-- 일별실적 --> */}
                        <div className="dayPerformance" style={{ display: tabTp === 1 ? 'block' : 'none' }}>
                            <div className="excelDown">
                                <div className="dateBox">
                                    <div className="cal">
                                        <DatePicker
                                            locale={ko}
                                            selected={startDate}
                                            onChange={date => selStartDate(date)}
                                            dateFormat="yyyy-MM-dd"
                                        placeholderText="시작일"
                                        onKeyDown={handleOnKeyDown}
                                        popperPlacement="bottom-start"
                                    />
                                    </div>
                                    <span>-</span>
                                    <div className="cal">
                                        <DatePicker
                                            locale={ko}
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="종료일"
                                            onKeyDown={handleOnKeyDown}
                                            popperPlacement="bottom-start"
                                        />
                                    </div>
                                    <a href="javascript:;" className="btn btnS btnGray2" onClick={() => performReload()}>검색</a>
                                </div>
                                <a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput}><img src="../images/icon/download_v.svg" alt="다운로드" />엑셀 다운로드</a>
                            </div>
                            <ul className="addBox">
                                <li>
                                    <p>조회</p>
                                    <p>{CommonFunc.numberWithCommas(listTotal.tt_r_cnt)}</p>
                                </li>
                                <li>
                                    <p>파트너스 가입</p>
                                    <p>{CommonFunc.numberWithCommas(listTotal.tt_pn_cnt)}</p>
                                </li>
                                <li>
                                    <p>아임인 가입</p>
                                    <p>{CommonFunc.numberWithCommas(listTotal.tt_mb_cnt)}</p>
                                </li>
                                <li>
                                    <p>아임인 이용</p>
                                    <p>{CommonFunc.numberWithCommas(listTotal.tt_s_fee)}</p>
                                </li>
                                <li>
                                    <p>수익</p>
                                    <p>{CommonFunc.numberWithCommas(listTotal.tt_pn_fee)}</p>
                                </li>
                            </ul>
                            <div className="tableBox">
                                <table className="tableList">
                                    <colgroup>
                                        <col style={{ width: "160px" }} />
                                        <col style={{ width: "120px" }} />
                                        <col style={{ width: "100px" }} />
                                        <col style={{ width: "100px" }} />
                                        <col style={{ width: "232px" }} />
                                        <col style={{ width: "232px" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>날짜</th>
                                            <th rowSpan={2}>조회</th>
                                            <th colSpan={2} style={{padding:'4px 0'}}>가입</th>
                                            <th rowSpan={2}>아임인 이용</th>
                                            <th rowSpan={2}>수익</th>
                                        </tr>
                                        <tr>
                                            <th style={{padding:'4px 0'}}>파트너스</th>
                                            <th style={{padding:'4px 0'}}>아임인</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <PerfomListData/>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    {/* <!-- 월별 실적 --> */}
                    <div className="monthPerformance" style={{ display: tabTp === 2 ? 'block' : 'none' }}>
                        <div className="excelDown">
                            <select value={monthDate} onChange={(e) => setMonthDate(e.target.value)}>
                                <YearSel />
                            </select>
                            <a href="javascript:;" className="btn btnS btnPointLine exelBtn" onClick={Excelinput}><img src="../images/icon/download_v.svg" alt="다운로드"/>엑셀 다운로드</a>
                        </div>
                        <ul className="addBox">
                        	<li>
                                <p>조회</p>
                                <p>{CommonFunc.numberWithCommas(listTotal.tt_r_cnt)}</p>
                            </li>
                            <li>
                                <p>파트너스 가입</p>
                                <p>{CommonFunc.numberWithCommas(listTotal.tt_pn_cnt)}</p>
                            </li>
                            <li>
                                <p>아임인 가입</p>
                                <p>{CommonFunc.numberWithCommas(listTotal.tt_mb_cnt)}</p>
                            </li>
                            <li>
                                <p>아임인 이용</p>
                                <p>{CommonFunc.numberWithCommas(listTotal.tt_s_fee)}</p>
                            </li>
                            <li>
                                <p>수익</p>
                                <p>{CommonFunc.numberWithCommas(listTotal.tt_pn_fee)}</p>
                            </li>
                        </ul>
                        <div className="tableBox">
                            <table className="tableList">
                                <colgroup>
                                    <col style={{ width: "160px" }} />
                                    <col style={{ width: "120px" }} />
                                    <col style={{ width: "100px" }} />
                                    <col style={{ width: "100px" }} />
                                    <col style={{ width: "232px" }} />
                                    <col style={{ width: "232px" }} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th rowSpan={2}>날짜</th>
                                    <th rowSpan={2}>조회</th>
                                    <th colSpan={2} style={{padding:'4px 0'}}>가입</th>
                                    <th rowSpan={2}>아임인 이용</th>
                                    <th rowSpan={2}>수익</th>
                                </tr>
                                <tr>
                                    <th style={{padding:'4px 0'}}>파트너스</th>
                                    <th style={{padding:'4px 0'}}>아임인</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        tabTp === 2 && Array.isArray(listData) ? (
                                            totalCnt > 0 ? (
                                                listData.map((item, index) => (
                                                    <tr key={index} onClick={() => performanceView(item.r_date, tabTp)}>
                                                        <td>{RDateFormat(item.r_date)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.r_cnt)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.pn_cnt)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.mb_cnt)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.s_fee)}</td>
                                                        <td>{CommonFunc.numberWithCommas(item.pn_fee)}</td>
                                                    </tr>
                                                ))) :
                                                (
                                                    <tr><td colSpan="6" className="noData">검색 결과가 없습니다.</td></tr>
                                                )) :
                                            (
                                                null
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <!-- 페이징 --> */}
                <div className="pagingDiv">
                    <Pagination
                        totalPage={totalPage === 0 ? 1 : totalPage}
                        // totalPage={totalPage}
                        limit={pageSize}
                        page={pageNum}
                        setPage={changePages}
                    />
                </div>
            </section>
        </>
    )
}

export default Performance;


