import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import * as CommonFunc from 'common/js/func';
import FooterLeft from "./FooterLeft";
import AppStore from "./AppStore";

const Footer = () => {

    // 페이지 이동
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }
    // 공지사항 최신 한 건 불러오기
    const [noticeData, setNoticeData] = useState([]);
    useQuery(['boardList'],
        async () => {

            const requestData = {
                params: { boardTp: "N", showTp: 1, noticeTp: 1 } // 공지사항인 경우만 출력
            };
            const noticeFooter = await api.get(`/api/v1/boardSelectOne`, requestData);

            if (noticeFooter.status === 200) {
                const ftSubj = CommonFunc.shortenWords(noticeFooter.data.subj, 50)
                noticeFooter.data.subj = ftSubj;

                setNoticeData(noticeFooter.data);
            }
        }
    );

    return (
        <footer>
            <div className="noticeCon">
                <div>
                    <span className="state">{ noticeData.fnoticeTp }</span>
                    <a href="" onClick={() => pageMoveA(`/notice/NoticeView?no=${noticeData.boardIdx}`)}>
                        <span>{ noticeData.subj }</span>
                    </a>
                </div>
            </div>
            <div className="footer">
                <FooterLeft/>
                <div className="rightFooter">
                    <div className="link" style={{fontSize:14}}>
                        <a style={{cursor:'pointer'}} onClick={() => pageMoveA('/private/Agreement')}>서비스 이용약관</a>
                        <span className="empty"></span>
                        <a style={{cursor:'pointer'}} onClick={() => pageMoveA('/private/OperationalPolicy')}>운영정책</a>
                        <span className="empty"></span>
                        <a style={{cursor:'pointer'}} onClick={() => pageMoveA('/private/Private')}>개인정보 처리방침</a>
                    </div>
                    <p>목돈이 필요한 모든 순간, 소셜 핀테크 <b>아임인</b> <br/>지금 바로 다운로드 해보세요.</p>
                    <AppStore/>
                    <p  className="copyright">Copyright © <b>TWAVE</b>. All Rights Reserved. </p>
                </div>
            </div>
        </footer>
    )
}
export default Footer;
