import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import 'common/css/notice/noticeView.css'

const NoticeView = () => {

    // 페이지 이동
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    // boardIdx
    const urlParams = new URLSearchParams(window.location.search);
    const idx = urlParams.get('no');
    useEffect(() => {

    }, []);

    /************************
     * 공지사항 상세
     ************************/
    const [noticeData, setNoticeData] = useState([]);
    useQuery(['boardDetail'], async () => {
        const req = {
            params: { boardTp: "N", showTp: 1 }
        };

        const res = await api.get(`/api/v1/boardView/${idx}`, req);

        if (res.status === 200) {
            setNoticeData(res.data);
        }
    });

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/notice/Notice')}>공지사항</span>
                </div>
            </div>
            <section className="subCont noticeView" id="noticeView">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>공지사항</h4>
                </div>
                <div className="noticeViewCont">
                    <div className="ncTop">
                        <div>
                        { noticeData.noticeTp === 1 ? (
                            <span className="category categoryNoti">{noticeData.fnoticeTp}</span>
                            ) : (
                            <span className="category categoryIssue">{noticeData.fnoticeTp}</span>
                        )}
                            <p className="notiTitle">{noticeData.subj}</p>
                        </div>
                        {
                            noticeData.modData ? (
                                <p className="date">{ noticeData.f_modDate }</p>
                            ) : (
                                <p className="date">{ noticeData.f_regDate }</p>
                            )
                        }
                    </div>
                    <div className="ncBottom editorView ck-content">
                        <p dangerouslySetInnerHTML={{ __html: noticeData.conts }} />
                    </div>
                    <a onClick={() => pageMoveA('/notice/Notice')} className="btn btn100 btnWhite">목록으로</a>
                </div>
            </section>
        </>
    )
}

export default NoticeView;














