import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from 'react-query';
import { api } from 'api/api';
import { useNavigate, useLocation } from "react-router-dom";
import Laypop from "components/Laypop";
import * as CommonFunc from 'common/js/func';
import 'common/css/myinfo/result.css'
import InputPw from "components/InputPw";

const Result = () => {
//     const movePageParams = useLocation();
// const pnidx = movePageParams.state && movePageParams.state.pnidx;
    //     console.log(pnidx);
    const [resultTp, setResultTp] = useState(1); // 아이디,비밀번호 구분
    const urlParams = new URLSearchParams(window.location.search);
    const pnidx = parseInt(urlParams.get('pnidx'));
    const rstp = urlParams.get('rstp');
    useEffect(() => {
        //아이디/비밀번호 구분
        // console.log(rstp);
        setResultTp(rstp);
    }, [])

    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
        pageMoveA('/');
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };

    //enabled 속성을 줘서 처음에만 실행
    const [isSearch, setIsSearch] = useState(true);
    useQuery(['userSelView', isSearch],
        async () => {
            const req = {
                params: { pn_idx: pnidx}
            };
            const res = await api.get(`/api/v1/myinfo/${pnidx}`, req);
            // console.log(res);
            if (res.status === 200) {
                setUser(res.data);
            }
            setIsSearch(false);
        },
        {
            enabled: isSearch, // 마운트될 때만 실행
        });
    /****************************************** */

    const [user, setUser] = useState({
        pn_idx: 0,
        u_nm: '',
        id: '',
        r_date: ''
    });
    /****************************************** */

    const [pwCondition, setPwCondition] = useState(true); // 패스워드 조건
    const [pwMatch, setPwMatch] = useState(true); // 패스워드 일치 확인
    const [certification, setCertification] = useState(false); // 비밀번호 변경 버튼 활성화
    const [pw, setPw] = useState({
        userPw: '',
        userPw2: ''
    })
    // text 입력처리
    const pwField = (fieldName) => (e) => {
        const inputValue = e.target.value;

        setPw((preve) => {
            return {
                ...preve,
                [fieldName]: inputValue,
            };
        });

        if (fieldName === 'userPw') { // 패스워드 일치, 조건 확인
            const userPw2 = pw.userPw2;

            setPwCondition(CommonFunc.isPwTest(inputValue));

            if (userPw2 === '') {
                setPwMatch(true);
            } else {
                setPwMatch(userPw2 === inputValue);
            }

        } else if (fieldName === 'userPw2') {
            const userPw = pw.userPw;

            if (inputValue === '') {
                setPwMatch(true);
            } else {
                setPwMatch(userPw === inputValue);
            }
        }
    };

    // 모든 조건 만족시 본인인증 버튼 활성화
    const certifiActiv = () => {
        const isValid = pwCondition && pwMatch
            && pw.userPwf !== '' && pw.userPw2 !== ''

        setCertification(isValid);
    }

    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        certifiActiv();
    }, [pwMatch])

     //비밀번호 수정
     const userPwUpdate = useMutation(async () => {
        if (!certification) return false;
        const requestData = {
            up_tp : 2,
            pn_idx: pnidx,
            pwd: pw.userPw,
        };
        // console.log(requestData);
        const res = await api.patch(`/api/v1/myinfo/${pnidx}/userPwUpdate`, requestData);
        // console.log(res);
        if(res.status === 200){
            openPop('비밀번호 재설정이 완료되었습니다.');
        }
    })

    // 포커스 상태관리
    const [isFocus, setIsFocus] = useState({
        userPw: false,
        userPw2: false
    });

    const focusChange = (inputName, isFocused) => {
        setIsFocus(prevState => ({
            ...prevState,
            [inputName]: isFocused
        }));
    };

    return (
        <section className="subCont result" id='result'>
            {/* <!-- 컨텐츠 --> */}
            <div className="mobileBox">
                {/* <!-- 아이디 찾기 결과 --> */}
                <div className="idResult" style={{ display: resultTp === '1' ? 'block' : 'none' }}>
                    <h4><span>{user.u_nm}</span> 회원님의<br /> 아이디(이메일)입니다.</h4>
                    <div className="resultBox">
                        <p>{user.id}</p>
                        <p>가입일 : <span> {user.r_date}</span></p>
                    </div>
                    <div className="twoBtnBox">
                        <a className="btn btnPointLine" onClick={() => pageMoveA('/myinfo/PwInquiry')}>비밀번호 찾기</a>
                        <a className="btn btnPoint" onClick={() => pageMoveA('/myinfo/Login')}>로그인</a>
                    </div>
                </div>
                {/* <!-- 비밀번호 재설정 결과 --> */}
                <div className="pwResult" style={{ display: resultTp !== '1' ? 'block' : 'none' }}>
                    <h4>비밀번호 재설정</h4>
                    <ul>
                        <li className="inputBox">
                            <ul>
                                {/* <!-- ****(유효성 체크 외 하단 문장이 없었다면) input 유효성 검사 후 아래 멘트 출력 시 li에 .ibsInfo클래스 추가 ****  --> */}
                                <li className={'ibsInfo' + (pw.userPw === '' && !isFocus.userPw ? '' : ' labelInput')}>
                                    <InputPw
                                        value={pw.userPw}
                                        onChange={pwField('userPw')}
                                        labelNm={'새 비밀번호'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('userPw', isFocused)} // 포커스 상태 전달
                                    />
                                    <span>영문, 숫자, 특수문자 2자 이상 조합, 8~20자</span>
                                    <span className="colRed" style={{ display: pwCondition ? 'none' : 'block' }}>영문, 숫자, 특수문자 중 2가지 이상 조합하여 8~20자로 입력해 주세요.</span>
                                </li>
                                <li className={'ibsInfo' + (pw.userPw2 === '' && !isFocus.userPw2 ? '' : ' labelInput')}>
                                    <InputPw
                                        value={pw.userPw2}
                                        onChange={pwField('userPw2')}
                                        labelNm={'새 비밀번호 재입력'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('userPw2', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed" style={{ display: pwMatch ? 'none' : 'block' }}>비밀번호가 일치하지 않습니다</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <a href="javascript:;" className={'btn btn100 ' + (certification ? 'btnPoint' : 'btnGray')} style={{ cursor: certification ? 'pointer' : 'auto' }}
                        onClick={() => userPwUpdate.mutate()}>비밀번호 변경</a>
                </div>
            </div>
            {/* <!-- 알림 팝업 --> */}
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        </section>
    )
}

export default Result;
