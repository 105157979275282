import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query';
import { api } from 'api/api';
import Laypop from "components/Laypop";
import Confirmpop from "components/Confirmpop";
import * as CommonFunc from 'common/js/func';

import 'common/css/usage/askWrite.css'
import { useRecoilValue } from 'recoil';
import loginInfoState from 'store/State';
import {decodedToken} from 'common/js/func';

const AskWrite = () => {

    // 로그인 정보 불러오기
    const loginInfoValue = useRecoilValue(loginInfoState);
    const { token} = loginInfoValue;
    const decodeInfoValue = decodedToken(token);
    const { pnidx, unm} = decodeInfoValue;
    

    // 페이지 이동
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    // boardIdx
    const urlParams = new URLSearchParams(window.location.search);
    const idx = urlParams.get('no');

    const [askSubj, setAskSubj] = useState('문의하기');
    const [askBtn, setAskBtn] = useState('문의하기');

    // 페이지 실행 시, 데이터 호출
    useEffect(() => {
        if (idx !== null) {
            setAskSubj('문의 수정')
            setAskBtn('수정하기')
            askUpdateData();
        }
    }, [idx]);


    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);

        boardSave.mutate();
    };

    const closePop2 = () => {
        setPopData(null);
        setPopOpen(false);
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };


    const [certification, setCertification] = useState(false);
    // 삽입 데이터 초기화
    const [askSaveData, setAskSaveData] = useState({
         boardTp : "P"          // 공통
        ,showTp  : 1            // 공통
        ,subj    : ""
        ,conts   : ""
        ,regIdx  : pnidx
        ,regNm   : unm
        ,parentIdx: 1
    });

    /************************
     * 1:1 문의 상세
     ************************/
    const askUpdateData = async () => {
        const params = { 
            params: { boardTp: "P", regIdx: pnidx, showTp: 1, modIdx: pnidx } 
        };
        const res = await api.get(`/api/v1/boardView/${idx}`, params);
        if (res.status === 200) {
            setAskSaveData(res.data);
        }
    };

    // 작성된 데이터 세팅
    const inputChange = (e) => {
        setAskSaveData({
            ...askSaveData,
            [e.target.name]: e.target.value,
        });
    };

    // 제목/내용 모두 값이 있을 경우, 문의하기 버튼 활성화
    const certifiActiv = () => { 
        const isValid = 
        askSaveData.subj !== '' && askSaveData.conts !== '' && 
        askSaveData.subj.length < 50 && askSaveData.conts.length < 3000;
        
        setCertification(isValid);
    }
    useEffect(() => {
        certifiActiv();
    }, [askSaveData])

    // 등록 버튼 핸들러
    const handleAskSubmit = () => {
        // validation chk
        if (!CommonFunc.isValidation('string', askSaveData.subj)) {
            openPop('제목을 입력해 주세요.');
            return false;
        }

        if (!CommonFunc.isValidation('string', askSaveData.conts)) {
            openPop('내용을 입력해 주세요.');
            return false;
        }

        if (askSaveData.subj.length > 50) {
            openPop('제목은 50자를 초과할 수 없습니다.');
            return false;
        }
    
        if (askSaveData.conts.length > 3000) {
            openPop('내용은 3000자를 초과할 수 없습니다.');
            return false;
        }
        
        if(!idx) {
            openPop(`등록하시겠습니까?`);
        } else {
            openPop(`수정하시겠습니까?`);
        }
    };

    /***************************
     * 1:1 문의 등록/수정
     ***************************/
    const boardSave = useMutation(
        async () => {

            let updateBoardData  = { ...askSaveData }

            if(idx !== null) {
                updateBoardData = {
                     ...updateBoardData
                    ,modIdx : pnidx
                }
            }

            const res = await api.post(`/api/v1/boardAskSave`, updateBoardData);

            if (res.status === 200) {
                if (res.data > 0) {
                    pageMoveA(`/usage/Ask`);
                }
            }
        }
    );

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 문의</span>
                    <span onClick={() => pageMoveA('/usage/Ask')}>1:1문의</span>
                </div>
            </div>
            <section className="subCont askWrite" id='askWrite'>
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>{askSubj}</h4>
                    {!idx && (
                        <div>
                            <p>자주 묻는 질문을 먼저 확인해 보시겠어요?</p>
                            <a href="/usage/Inquiry" onClick={(e) => {pageMoveA('/usage/Inquiry'); e.preventDefault();}}>FAQ 바로가기</a>
                        </div>
                    )}
                </div>
                <div className="askWriteCont">
                    <input 
                        placeholder="제목을 입력해 주세요."
                        type="text" 
                        name="subj"
                        value={askSaveData.subj}
                        onChange={inputChange} />
                    <textarea 
                        placeholder="문의 내용을 입력해 주세요."
                        cols="30" 
                        rows="10" 
                        name="conts" 
                        value={askSaveData.conts} 
                        onChange={inputChange} />
                    {/* <!-- input과 textarea 입력되면 .btnGray > .btnPoint로 클래스 변경 --> */}
                    <a href="javascript:;" className={'btn btn100  btnPoint'} style={{ cursor: 'pointer' }} onClick={(e) => handleAskSubmit()}>{askBtn}</a>
                </div>
                {/* <!-- 알림 팝업 --> */}
                {popOpen && (
                    certification ? (
                        <Confirmpop data={popData} closePop={closePop} closePop2={closePop2} />
                        ) : (
                        <Laypop data={popData} closePop={closePop2} />
                    )
                )}
            </section>
        </>
    )
}

export default AskWrite;
