import React, { useState, useEffect, useRef } from 'react';

/**
 * 
 * @param {*} value 값
 * @param {*} onChange 값 변경
 * @param {*} onClear 값 초기화
 * @param {*} labelNm 라벨이름
 * @param {*} maxLength input 최대 길이
 * @param {*} enterKey 엔터키 처리
 * @param {*} disableCheck disable체크
 * @param {*} onFocusChange 포커스 부모 전달
 * @returns 
 */
const ClearInput = ({ value, onChange, onClear, labelNm, maxLength, enterKey, disableCheck, onFocusChange }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const inputRef = useRef(null);

    //포커스
    const clearFocus = () => {
        setIsFocused(true);
        setIsDisplay(true);
        onFocusChange(true);//
    };
    //포커스 아웃
    const clearBlur = () => {
        setIsFocused(false);
        timeoutDisplay();
        onFocusChange(false);//
    };

    //버튼클릭후 포커스 맞춤
    const clearButtonClick = () => {
        onClear();
        inputRef.current.focus();
        setIsDisplay(true);
        
    };

    //0.3초 후 실행
    const timeoutDisplay = () => {
        setTimeout(() => {
            //포커스 되있으면 true 아니면 false
            setIsDisplay(document.activeElement === inputRef.current);
        }, 300);
    };


    return (
        <>
            <input
                ref={inputRef}
                type="text"
                value={value}
                onChange={onChange}
                onFocus={clearFocus}
                onBlur={clearBlur}
                maxLength={maxLength}
                onKeyUp={enterKey}
                disabled={disableCheck}
            />
            <label style={{ display: disableCheck ? 'none' : 'block' }}>{labelNm}</label>
            {/* clearBox 보이기/숨기기 */}
            <div className={`clearBox ${isFocused ? 'fade-in' : 'fade-out'}`}
                style={{ display: isDisplay ? 'block' : 'none' }}
                onClick={clearButtonClick}>
                <span style={{ cursor: 'pointer' }}><img src="/images/btn/x-circle.svg" alt="지우기" /></span>
            </div>
        </>
    );
};

export default ClearInput;
