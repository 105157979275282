import React from 'react'

function Error404() {
  return (
    <div style={{
        color: 'black',
        fontSize: 25,
        textAlign: 'center',
        position: 'absolute',
        top: '30%',
        margin : 0,
        width: '100%',
    }}>
        <h1>404 Not Found</h1>
        <p style={{fontSize: 15}}>This Page you are looking for doesn't exist.</p>
    </div>
  );
}

export default Error404;
