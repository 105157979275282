import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from 'api/api';
import { useQuery, useMutation } from 'react-query';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import loginInfoState from 'store/State';
import Confirmpop from "components/Confirmpop";
import {decodedToken} from 'common/js/func';

import 'common/css/myinfo/withdrawal.css'
const Withdrawal = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    const resetLoginInfoState = useResetRecoilState(loginInfoState); // 로그인 정보 초기화
    const { token, isLogin, loginExpiration} = loginInfoValue;
    const decodeInfoValue = decodedToken(token);
    const { pnidx} = decodeInfoValue;

    // 페이지 이동
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

     //*************************************/ 알림 팝업
     const [popOpen, setPopOpen] = useState(false);
     const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
        //  pageMoveA('/');
        userDelete.mutate()
    };

    const closePop2 = () => {// 확인버튼
         setPopData(null);
         setPopOpen(false);
     };

    const openPop = (data) => {
        // console.log(agre);
        // 빈값이면 동작x
        if (conts === '' || !agre) {
            return false;
        }
        setPopData(data);
        setPopOpen(true);
    };
     //*************************************/

    const [conts, setConts] = useState('');

    const userDelete = useMutation(async () => {
        if (conts === '') return false;
        const requestData = {
            pn_idx: pnidx,
            wdwal_reason: conts,
            wdwal: 1
        };
        // console.log(requestData);
        const res = await api.patch(`/api/v1/myinfo/${pnidx}/userDelete`, requestData);
        // console.log(res);
        return res;
    }, {
        onSuccess: (res) => {
            if (res.status === 200) {
                resetLoginInfoState();
                localStorage.clear();
                window.location.href = '/';
            } 
        },
    });
    
    // 체크박스 이미지
    const checkImg = (check) => {
        return check ? '/images/btn/checked.svg' : '/images/btn/unchecked.svg'
    };
    const [agre, setAgre] = useState(false) // 약관전체 동의

    return (
        <section className="subCont withdrawal" id='withdrawal'>
        {/* <!-- 컨텐츠 --> */}
        <div className="mobileBox">
            <h4>회원 탈퇴</h4>
            <div>
                <p>탈퇴 사유</p>
                <textarea name="" cols="30" rows="10"  placeholder="탈퇴 사유를 입력해 주세요."
                value={conts} onChange={(e) => setConts(e.target.value)}></textarea>
                <p>유의사항</p>
                <ul>
                    <li>회원정보 및 서비스 이용 기록은 개인정보보호 처리 방침 기준에 따라 삭제됩니다.</li>
                    <li>회원 탈퇴 후 재가입하더라도 탈퇴 전의 회원정보, 수익, 홍보 데이터 등은 복구되지 않으며 동일한 ID로 재가입할 수 없습니다.</li>
                    <li>회원 탈퇴 시 즉시 홍보가 중지되며, <span className="colRed ftBold">지급되지 않은 잔여 수익금은 받을 수 없습니다</span></li>
                </ul>
                    {/* <!-- 활성화 시 btnGray > btnPoint로 변경 --> */}
                    <div className="imageCheckbox">
                        <img src={checkImg(agre)} alt="유의사항 체크박스 해제됨" className="checkbox" onClick={() => setAgre(!agre)} />
                        <p>유의사항을 모두 확인했으며, 이에 동의합니다.</p>
                </div>
                <a href="javascript:;" className={"btn btn100 " + (conts !== '' && agre? "btnPoint" : "btnGray")} style={{ cursor: conts === '' || !agre ? 'auto' : 'pointer' }}
                onClick={() => openPop('정말 탈퇴하시겠습니까?')}>탈퇴</a>
            </div>
            
        </div>
            {popOpen && (
                <Confirmpop data={popData} closePop={closePop} closePop2={closePop2} />
            )}
    </section>
    )
}

export default Withdrawal;
