// SmoothScroll.js
export const smoothScrollTo = (endY, duration) => {
    const startY = window.scrollY;
    const distanceY = endY - startY;
    let startTime = null;
  
    const step = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
  
      window.scrollTo(0, startY + distanceY * easeInOutQuad(progress));
  
      if (timeElapsed < duration) {
        requestAnimationFrame(step);
      }
    };
  
    const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
  
    requestAnimationFrame(step);
  };