import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { useNavigate } from "react-router-dom";
import { api } from 'api/api';

import loginInfoState from 'store/State';
import Laypop from "components/Laypop";
import * as CommonFunc from 'common/js/func';
// import * as CommonAlert from 'common/js/alert';

import ClearInput from "components/InputClear";
import InputPw from "components/InputPw";

import { jwtDecode } from 'jwt-decode';
import 'common/css/myinfo/login.css'
const Login = () => {
    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
    };

    const openPop = (data) => {
        const usernameInput = document.querySelector('#userId');
        const passwordInput = document.querySelector('#userPw');

        // 아이디 비밀번호 포커스를 제거
        if (usernameInput && passwordInput) {
            usernameInput.blur();
            passwordInput.blur();
        }

        setPopData(data);
        setPopOpen(true);
    };


    const [loginInfo, setLoginInfo] = useRecoilState(loginInfoState);
    // const [userId, setUserId] = useState('master@rysoft.co.kr');
    // const [password, setPassword] = useState('123123@@');
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [isSaveId, setIsSaveId] = useState(false);

    const handleOnKeyUp = e => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleLogin = () => {
        if(popOpen){
            return false;
        }
        if (!CommonFunc.isValidation('string', userId)) {
            openPop('아이디를 입력해 주세요.');
            return false;
        }

        if (!CommonFunc.isValidation('string', password)) {
            openPop('비밀번호를 입력해 주세요.');
            return false;
        }
        loginProc.mutate({
            id: userId,
            pwd: password,
        });
    }
    const loginProc = useMutation(async (requestData) => {
        // console.log(requestData);
       const res = await api.post(`/api/v1/myinfo/login`, requestData);
    //    console.log(res)
        if (res.status === 200) {
            const responseData = res.data
            const date = new Date();
            // const loginExpiration = new Date(date.setHours(date.getHours() + 6)); // 로그인 만료 6시간으로 설정
            // const loginExpiration = new Date(date.setHours(date.getHours() + 1)); // 로그인 만료 1시간테스트
            const loginExpiration = new Date(date.getTime() + 30 * 60000);// 30분
            // const loginExpiration = new Date(date.setSeconds(date.getSeconds() + 5)); // 로그인 만료 5초 테스트
            const token = responseData.u_token//responseData.data.token;

            // const decodedToken = jwtDecode(token);
            setLoginInfo({
                // pnidx: decodedToken.pnidx,
                // id: responseData.id, // 비민감 정보
                // unm: decodedToken.unm,
                // nnm: decodedToken.nnm,
                // pncd: decodedToken.pncd,
                // imcd: decodedToken.imcd,
                // rmcd: decodedToken.rmcd,
                // rmpnidx: decodedToken.rmpnidx,
                token: token,
                isLogin: true,
                // regDate: decodedToken.regDate,
                loginExpiration: loginExpiration.toISOString(),
            });

            localStorage.setItem('token', token);
            if (isSaveId) localStorage.setItem('saveId', userId);
            window.location.href = '/';
        } else {
            openPop('아이디 또는 비밀번호를 확인해 주세요.');
            return false;
        }
    });

    // 포커스 상태관리
    const [isFocus, setIsFocus] = useState({
        id: false,
        pw: false,
    });

    const focusChange = (inputName, isFocused) => {
        setIsFocus(prevState => ({
            ...prevState,
            [inputName]: isFocused
        }));
    };
    return (
        // <!-- 컨텐츠 -->
        <section className="subCont default" id="login">
            <div className="mobileBox">
                <h4>로그인</h4>
                <div className={'ibsInfo' + (userId === '' && !isFocus.id? '' : ' labelInput')}>
                    <ClearInput
                        value={userId || ""}
                        onChange={(e) => setUserId(e.target.value)}
                        onClear={() => setUserId('')}
                        labelNm={'이메일'}
                        enterKey={handleOnKeyUp}
                        onFocusChange={(isFocused) => focusChange('id', isFocused)}
                    />
                </div>
                <div className={'ibsInfo' + (password === '' && !isFocus.pw ? '' : ' labelInput')}>
                    <InputPw id="userPw"
                        value={password || ""}
                        onChange={(e) => setPassword(e.target.value)}
                        labelNm={'비밀번호'}
                        enterKey={handleOnKeyUp}
                        onFocusChange={(isFocused) => focusChange('pw', isFocused)}
                    />
                </div>
                <a href="javascript:;" className="btn btn100 btnPoint" onClick={handleLogin}>로그인</a>
                <ul className="joinBox">
                    <li><a onClick={() => pageMoveA('/myinfo/IdInquiry')}>아이디 찾기</a></li>
                    <li><a onClick={() => pageMoveA('/myinfo/PwInquiry')}>비밀번호 찾기</a></li>
                    <li><a className="join" onClick={() => pageMoveA('/myinfo/Join')}>회원가입</a></li>
                </ul>
            </div>
            {/* <!-- 알림 팝업 --> */}
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        </section>
    )
}

export default Login;
