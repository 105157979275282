import { api } from 'api/api';
import React, { useEffect } from 'react';
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const appLink = process.env.REACT_APP_IMIN_APP_LINK;
const PromoApp = () => {

    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    //*************************************/
    const location = useLocation();
    const pncd = location.pathname.replace('/promoApp/', ''); // 추천인코드
    // console.log(pncd)

    useEffect(() => {
         
        refetch(); // 앱 설치 및 회원가입 하지 않아도 홍보 카운트 update

        const confirmMsg = window.confirm("앱 설치 페이지로 이동하시겠습니까?");
        if(confirmMsg) {
            // 확인 버튼 클릭 시, 앱 설치 딥 링크로 이동 (param : pncd)
            
            // useNavigate 사용 시, 페이지가 넘어가지 않는 이슈로 window.location.href 사용
            // pageMoveA(`${appLink}&deep_link_sub1=${pncd}`);
            window.location.href = `${appLink}&deep_link_sub1=${pncd}`;
        }
        else {
            // console.log("취소");
            pageMoveA('/');
        }

    }, [pncd])

    const { refetch } = useQuery(['promoCntUpt'], async () => {
        const promoCntUpt = await api.get(`/api/v1/promoCntUpt/${encodeURIComponent(pncd)}`);
        const resultCd = promoCntUpt.data.result.resultCd;
        const errReason = promoCntUpt.data.result.errReason;
        
        // console.log(errReason, resultCd)
    }, 
    {
        enabled: false // 새로고침 하기 전 까지 1번만 로딩
    })
}

export default PromoApp;