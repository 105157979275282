import React, { useState, useEffect, useRef } from 'react';

/**
 * 
 * @param {*} value 값
 * @param {*} onChange 값 변경
 * @param {*} labelNm 라벨이름
 * @param {*} maxLength input 최대 길이
 * @param {*} enterKey 엔터키 처리
 * @returns 
 */
const InputPw = ({ value, onChange, labelNm, maxLength, enterKey, onFocusChange }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const [isPwTp, setIsPwTp] = useState(true);
    const inputRef = useRef(null);

    const pwFocus = () => {
        setIsFocused(true);
        setIsDisplay(true)
        onFocusChange(true);//
    };

    const pwBlur = () => {
        setIsFocused(false);
        timeoutDisplay();
        onFocusChange(false);//
    };

    const pwBoxClick = () => {
        setIsPwTp(!isPwTp);
        inputRef.current.focus();
    };

    
    //0.3초 후 실행
    const timeoutDisplay = () => {
        setTimeout(() => {
            //포커스 되있으면 true 아니면 false
            setIsDisplay(document.activeElement === inputRef.current);
        }, 300);
    };


    return (
        <>
            <input
                ref={inputRef}
                type={isPwTp ? 'password' : 'text'}
                value={value}
                onChange={onChange}
                onFocus={pwFocus}
                onBlur={pwBlur}
                maxLength={maxLength}
                onKeyUp={enterKey}
            />
            <label>{labelNm}</label>
            {/* clearBox 보이기/숨기기 */}
            <div className={`clearBox ${isFocused ? 'fade-in' : 'fade-out'}`}
                style={{ display: isDisplay ? 'block' : 'none' }}
                onClick={pwBoxClick}>
                <span style={{ cursor: 'pointer' }}><img src={isPwTp ? '/images/icon/eye-off.svg' : '/images/icon/eye.svg'} alt="지우기" /></span>
            </div>
        </>
    );
};

export default InputPw;
