import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from 'react-query';
import { api } from 'api/api';
import { saveAs } from 'file-saver';
import Laypop from "components/Laypop";
import Pagination from "components/Pagination";
import YouTube from 'react-youtube';
import 'common/css/promotion/promotion.css';
import clipboardCopy from 'clipboard-copy';
import { useRecoilValue } from "recoil";
import loginInfoState from "store/State";
import {decodedToken} from 'common/js/func';

const s3ImgUrl = process.env.REACT_APP_IMG_URL;
const appUrl = process.env.REACT_APP_PARTNERS_FRONT

const Promotion = () => {

    // console.log(s3ImgUrl);
    // console.log(process.env.REACT_APP_ENVIRONMENT);
    // 로그인 정보 불러오기
    const loginInfoValue = useRecoilValue(loginInfoState);
    const { token, isLogin, loginExpiration} = loginInfoValue;
    const decodeInfoValue = decodedToken(token);
    const { rmcd} = decodeInfoValue;

    // url 정보
    const origin = window.location.origin;


    /****************************************************/
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호
    const [tabTp, setTabTp] = useState(1);  // 현재 탭 번호

    const [totalCnt, setTotalCnt] = useState(0);

    let pageRowCnt = 8; // 페이지에 표시할 행 개수
    if(tabTp === 3) pageRowCnt = 4;

    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수

    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    const paraGetTab = parseInt(urlParams.get("tab"));
    /****************************************************/

    // 탭, 페이지 변경, 뒤로가기 이벤트
    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
        !paraGetTab ? setTabTp(1) : setTabTp(paraGetTab);
        onList();
    }, [paraGetPage, paraGetTab])

    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    // 페이지 이동
    const changePages = (page) => {
        setPageNum(page);
        movePage(`/promotion/Promotion?page=${page}&tab=${tabTp}`);
    }

    // 탭 이동
    const changeTabs = (tab) => {
        setTabTp(tab)
        movePage(`/promotion/Promotion?page=${1}&tab=${tab}`);
    }

    //리스트 검색
    const [isList, setIsList] = useState(true);
    const onList = () => {
        setIsList(!isList);
    };

    // 알림 팝업 
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
    };

    // 클립보드
    const [userCode, setUserCode] = useState(rmcd);
    const urlApp = `${origin}/promoApp/${rmcd}`;  // https://iminpartners.kr/${rmCd}
    const urlJoin =  `${origin}/myinfo/Join/${rmcd}`; // http://localhost:3001/myinfo/Join/${rmCd}

    // 복사하기 버튼 클릭
    const copyUrl = (url) => {
        clipboardCopy(url)
            .then(() => {
                let urlTp = ''
                if (url === userCode) {
                    urlTp = '내 추천인 코드가'
                }
                else if (url === urlApp || url === urlJoin) {
                    urlTp = '홍보 URL이'
                }
                else {
                    urlTp = '영상 URL이'
                }
                openPop(`${urlTp} 복사되었습니다.`);
            })
            .catch((err) => {
                console.error('클립보드 복사 중 오류:', err);
            });
    }
    
    // 이미지 다운로드
    const downloadImage = async (filePath, fileName, idx, rfnm, subj, event) => {
        event.preventDefault();
        const res = updateImageCount.mutateAsync(idx);

        res.then(async () => {
            try {
                // const imgDownload = await api.get(`/common/filedownload?fpt=${filePath}&fnm=${fileName}&rfnm=${rfnm}`, {
                const imgDownload = await api.get(`/common/s3Download?fpt=${filePath}&fnm=${fileName}&rfnm=${rfnm}`, {
                    responseType: 'blob' // 이미지 파일 형식
                })

                // console.log(imgDownload);
                const blob = new Blob([imgDownload.data], { type: 'image/jpeg' }); // 이미지 타입에 따라 수정

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', subj); // 파일 이름 설정 ==> 이미지명으로 수정
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('## 이미지 다운로드 실패:', error);
            }
        })
            .catch(error => {
                console.error('## 이미지 다운로드 실패:', error);
            });
    };
    
    // 이미지 다운로드 증가 
    const updateImageCount = useMutation( async (idx) => {
        const res = await api.post(`/api/v1/promoList/${idx}/promoImgCntUpt`)
        if (res.status === 200) {
            // 성공 1
            return res.data;
        }
        else {
            // 실패 2
            console.log("## 실패 >> " + res.data)
        }
    });

    /*************************
     * 홍보하기 리스트
     **************************/
    const [promoList, setPromoList] = useState([]);
    useQuery(['promoList', isList], async () => {
        
        const requestData = {
            params: { boardTp: "M", showTp: 1, prType: paraGetTab || tabTp, page: pageNum - 1, size: pageRowCnt }
        };

        const promoList = await api.get(`/api/v1/promoList`, requestData);

        if(promoList.status === 200) {
            setPromoList(promoList.data.content)
            setTotalCnt(promoList.data.totalElements)
        }
        
    });

    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/promotion/Promotion')}>홍보하기</span>
                </div>
            </div>
            <section className="subCont promotion" id="promotion">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>홍보하기</h4>
                    <p>
                    아임인 파트너스에서 제공하는 콘텐츠를 활용해서 아임인을 홍보해 주세요.<br/>
                    전용 URL 또는 내 추천인 코드를 통해서만 수익이 발생하니, 잊지말고 URL을 꼭 활용하세요!
                    </p>
                </div>
                <div className="prmoTop">
                    <div className="imgBox"><img src="/images/png/promotion.png" alt="imin 홍보이미지"/></div>
                    <ul className="urlBox">
                        <li>
                            <p>아임인 앱 다운로드 URL</p>
                            <div>
                                <input type="text" readOnly value={urlApp} />
                                <a href="javascript:;" className="btn btnPointLine" onClick={() => copyUrl(urlApp)}>URL 복사</a>
                            </div>
                        </li>
                        <li>
                            <p>아임인 파트너스 가입 URL</p>
                            <div>
                                <input type="text" readOnly value={urlJoin}/>
                                <a href="javascript:;" className="btn btnPointLine" onClick={() => copyUrl(urlJoin)}>URL 복사</a>
                            </div>
                        </li>
                        <li>
                            <p>내 추천인 코드</p>
                            <div>
                                <input type="text" readOnly value={userCode}/>
                                <a href="javascript:;" className="btn btnPointLine" onClick={() => copyUrl(userCode)}>코드 복사</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="promoBottom">
                    <div className="tabBox">
                        <ul>
                            <li className={tabTp === 1 ? 'active' : ''}><a href="javascript:;" onClick={(e) => changeTabs(1)}>기본정보</a></li>
                            <li className={tabTp === 2 ? 'active' : ''}><a href="javascript:;" onClick={(e) => changeTabs(2)}>이미지</a></li>
                            <li className={tabTp === 3 ? 'active' : ''}><a href="javascript:;" onClick={(e) => changeTabs(3)}>영상</a></li>
                        </ul>
                    </div>


                    {/* <!-- 기본정보 --> */}
                    <div className="baseInfo" style={{ display: tabTp === 1 ? 'block' : 'none' }} >
                        <div>
                            {
                                Array.isArray(promoList) && totalCnt > 0 ? (
                                    promoList.map((promoInfo, index) => (
                                        promoInfo.prType === 1 && 
                                        <div key={promoInfo.prIdx} className="tpBox">
                                            <div className="editorView ck-content" dangerouslySetInnerHTML={{ __html: promoInfo.conts }} />
                                        </div>
                                    ))) :
                                (
                                    <p>검색된 내용이 없습니다.</p>
                                )
                            }
                        </div>
                    </div>





                    {/* <!-- 이미지 --> */}
                    <div className="img" style={{ display: tabTp === 2 ? 'block' : 'none' }}>
                        <ul>
                            {
                                Array.isArray(promoList) && totalCnt > 0 ? (
                                    promoList.map(promo => (
                                        promo.prType === 2 &&
                                        <li key={promo.prIdx}>
                                            <div className="imgBox">
                                                <img src={s3ImgUrl + promo.filePath + `/` + promo.fileNm} alt={promo.fileNm}/>
                                                <a href="javascript:;" onClick={(e) => downloadImage(promo.filePath, promo.fileNm, promo.prIdx, promo.realFileNm, promo.subj, e)}>
                                                    <img src="/images/icon/download_w.svg" alt="다운로드"/>
                                                </a>
                                            </div>
                                            <div className="txtBox">
                                                <p>{promo.subj}</p>
                                                {/* <p>{promo.downloadCnt}</p> */}
                                            </div>
                                        </li>
                                    ))) :
                                    (
                                        <p>검색된 내용이 없습니다.</p>
                                    )
                            }
                        </ul>
                        <div className="pagingDiv" style={{display: "block"}}>
                            <Pagination
                                totalPage={totalPage === 0 ? 1 : totalPage}
                                limit={pageSize}
                                page={pageNum}
                                setPage={changePages}
                            />
                        </div>
                    </div>




                    
                    {/* <!-- 영상 --> */}
                    <div className="video" style={{ display: tabTp === 3 ? 'block' : 'none' }}>
                        <ul>
                            {
                                Array.isArray(promoList) && totalCnt > 0 ? (
                                    promoList.map(promo => (
                                        promo.prType === 3 &&
                                        <li key={promo.prIdx}>
                                            <div className="imgBox">
                                            <YouTube
                                                videoId={promo.youtubeKey}
                                                opts={{
                                                    width: "100%",
                                                    height: "315",
                                                    playerVars: {
                                                        autoplay: 0, //자동재생 O
                                                        rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                                                        modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                                                    },
                                                }}
                                                //이벤트 리스너 
                                                onEnd={(e) => { e.target.stopVideo(0); }}
                                            />
                                                <a href="javascript:;" onClick={() => copyUrl(promo.movieUrl)}>
                                                    <img src="/images/icon/copy.svg" alt="복사"/>
                                                </a>
                                            </div>
                                            <div className="txtBox">
                                                <p>{promo.subj}</p>
                                            </div>
                                        </li>
                                    ))) :
                                (
                                    <p>검색된 내용이 없습니다.</p>
                                )
                            }
                        </ul>


                        {totalCnt > 0 && (
                            <div className="pagingDiv">
                                <Pagination
                                    totalPage={totalPage === 0 ? 1 : totalPage}
                                    limit={pageSize}
                                    page={pageNum}
                                    setPage={changePages}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </section>
            
            {/* <!-- 알림 팝업 --> */}
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        </>
    )
}

export default Promotion;