import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useNavigate, useParams} from "react-router-dom";
import loginInfoState from 'store/State';

import Laypop from "components/Laypop";

import Header from 'components/Header';
import Footer from 'components/Footer';
import Main from 'page/Main';
import IdInquiry from 'page/myinfo/IdInquiry';
import Join from 'page/myinfo/Join';
import Login from 'page/myinfo/Login';
import Myinfo from 'page/myinfo/Myinfo';
import PwInquiry from 'page/myinfo/PwInquiry';
import Result from 'page/myinfo/Result';
import Withdrawal from 'page/myinfo/Withdrawal';
import Auth from 'page/myinfo/Auth';
import Authfail from 'page/myinfo/Authfail';
import PopupPage from 'page/myinfo/PopupPage';
import Notice from 'page/notice/Notice';
import NoticeView from 'page/notice/NoticeView';
import Agreement from 'page/private/Agreement';
import Private from 'page/private/Private';
import OperationalPolicy from 'page/private/OperationalPolicy';
import Promotion from 'page/promotion/Promotion';
import Calculate from 'page/report/Calculate';
import Performance from 'page/report/Performance';
import PerformanceView from 'page/report/PerformanceView';
import Recommend from 'page/report/Recommend';
import Ask from 'page/usage/Ask';
import AskView from 'page/usage/AskView';
import AskWrite from 'page/usage/AskWrite';
import Guide from 'page/usage/Guide';
import Inquiry from 'page/usage/Inquiry';
import Introduction from 'page/usage/Introduction';
import Error404 from 'page/errorPage/404';

import * as layout from 'common/js/layout';
import 'common/css/Editor.css';
import 'common/css/ckeditor5.css';
import 'common/css/quill.css';
import 'common/css/function.css';
import 'common/css/common.css';
import 'common/css/layout.css';
import 'common/css/sub.css';


const PageLayout = () =>{
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    const { isLogin } = loginInfoValue;
    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    //팝업
    /************************************* */
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);
    const closePop = () => {
        setPopData(null);
        setPopOpen(false);
        movePage('/myinfo/Login');
    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
    };

    /******************************************** */
    // 태그 방지
    const aLinkClick = (event) => {
        const href = event.currentTarget.getAttribute('href');
        if (href && href.startsWith('http')) {
            return;
        }
        event.preventDefault();
    };

    const currentPath = window.location.pathname
    useEffect(() => {
        // console.log(currentPath);
        // 로그인이 안됐을시 해당 페이지 접근 불가
        if (currentPath.includes('promotion') || currentPath.includes('report') || currentPath.includes('Ask')) {
            if (!isLogin) {
                openPop('로그인 후 이용해주세요.');
            }
        }

        // 모든 a태그 #방지
        document.querySelectorAll('a').forEach(link => {
            link.addEventListener('click', aLinkClick);
        });

    }, [currentPath]);

    // footer 여부
    const [hideFooter, setHideFooter] = useState(false);
    return (
        <>
        {/* <Header /> */}
        <Header setHideFooter={setHideFooter} />
            {currentPath === "/" ? <Main />
            //회원 정보
            :currentPath ==='/myinfo/IdInquiry' ?<IdInquiry />
            :currentPath ==='/myinfo/Join' ?<Join />
            :currentPath .includes('/myinfo/Join/ICT') ?<Join />
            // :currentPath.startsWith('/myinfo/Join') ? <Join />
            // : currentPath.match(/^\/myinfo\/Join\/ICT/) ? <Join />
            :currentPath ==='/myinfo/Login' ?<Login />
            :currentPath ==='/myinfo/Myinfo' ?<Myinfo />
            :currentPath ==='/myinfo/PwInquiry' ?<PwInquiry />
            :currentPath ==='/myinfo/Result' ?<Result />
            :currentPath ==='/myinfo/Withdrawal' ?<Withdrawal />
            :currentPath ==='/myinfo/Auth' ?<Auth />
            :currentPath ==='/myinfo/Authfail' ?<Authfail />
            :currentPath ==='/myinfo/PopupPage' ?<PopupPage />
            //게시판 
            :currentPath ==='/notice/Notice' ?<Notice />
            :currentPath ==='/notice/NoticeView' ?<NoticeView />
            //약관
            :currentPath ==='/private/Agreement' ?<Agreement />
            :currentPath ==='/private/Private' ?<Private />
            :currentPath ==='/private/OperationalPolicy' ?<OperationalPolicy />
            //홍보하기
            :currentPath ==='/promotion/Promotion' ?<Promotion />
            //리포트
            :currentPath ==='/report/Calculate' ?<Calculate />
            :currentPath ==='/report/Performance' ?<Performance />
            :currentPath ==='/report/PerformanceView' ?<PerformanceView />
            :currentPath ==='/report/Recommend' ?<Recommend />
            //공지사항
            :currentPath ==='/usage/Ask' ?<Ask />
            :currentPath ==='/usage/AskView' ?<AskView />
            :currentPath ==='/usage/AskWrite' ?<AskWrite />
            :currentPath ==='/usage/Guide' ?<Guide />
            :currentPath ==='/usage/Inquiry' ?<Inquiry />
            :currentPath ==='/usage/Introduction' ?<Introduction />
            : <Error404 /> }
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        {/* <Footer /> */}
        {!hideFooter && <Footer />}
        </>
    )
}
export default PageLayout;
