// 체크박스
export const checkBtn = (pNum) => {
    return pNum === 1 ? '/images/btn/checked.svg' : '/images/btn/unchecked.svg'
};


export const topMenu = [
	{ m_url: '/promotion/Promotion', m_nm: '홍보하기' },
	{ m_url: '/report/Performance', m_nm: '리포트' },
	{ m_url: '/usage/Guide', m_nm: '이용문의' },
	{ m_url: '/notice/Notice', m_nm: '공지사항' },
]

export const sideMenu = [
	[
		// { m_url: '/promotion/Promotion', m_nm: '홍보하기' }	,
	],
	[
		{ m_url: '/report/Performance', m_nm: '실적 리포트' },
		{ m_url: '/report/Calculate', m_nm: '정산 리포트' },
		{ m_url: '/report/Recommend', m_nm: '추천인 리포트' },
	],
	[
		{ m_url: '/usage/Guide', m_nm: '이용 가이드' },
		{ m_url: '/usage/Introduction', m_nm: '아임인 소개' },
		{ m_url: '/usage/Inquiry', m_nm: '자주 묻는 질문' },
		{ m_url: '/usage/Ask', m_nm: '1:1 문의' },
	],
	[
		// { m_url: '/notice/Notice', m_nm: '공지사항' },
	],
]


export const sideMenuNm = (pUrl) =>{
	let menuNm = '';
	switch (pUrl) {
		case '/myinfo/IdInquiry':
			menuNm = '아이디 찾기';
			break;
		case '/myinfo/Join':
			menuNm = '회원가입';
			break;
		case '/myinfo/Login':
			menuNm = '로그인';
			break;
		case '/myinfo/Myinfo':
			menuNm = '내 정보 관리';
			break;
		case '/myinfo/PwInquiry':
			menuNm = '비밀번호 찾기';
			break;
		case '/myinfo/Withdrawal':
			menuNm = '회원탈퇴';
			break;
		case '/notice/Notice':
			menuNm = '공지사항';
			break;
		case '/notice/NoticeView':
			menuNm = '공지사항 ';
			break;
		case '/usage/Ask':
			menuNm = '1:1 문의';
			break;
		case '/usage/AskView':
			menuNm = '1:1 문의';
			break;
		case '/usage/Introduction':
			menuNm = '아임인 소개';
			break;
		case '/usage/Inquiry':
			menuNm = '자주 묻는 질문';
			break;
		case '/usage/Guide':
			menuNm = '이용 가이드';
			break;
		case '/report/Performance':
			menuNm = '실적 리포트';
			break;
		case '/report/PerformanceView':
			menuNm = '실적 리포트';
			break;
		case '/report/Calculate':
			menuNm = '정산 리포트';
			break;
		case '/report/Recommend':
			menuNm = '추천인 리포트';
			break;
		case '/promotion/Promotion':
			menuNm = '홍보하기';
			break;
		case '/private/Agreement':
			menuNm = '서비스이용약관';
			break;
		case '/private/OperationalPolicy':
			menuNm = '운영정책';
			break;			
		case '/private/Private':
			menuNm = '개인정보 처리방침';
			break;			
		}

	return menuNm;
}