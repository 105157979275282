import React from 'react';
import { useNavigate } from "react-router-dom";
import 'common/css/usage/guide.css'
const Guide = () => {
    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 문의</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 가이드</span>
                </div>
            </div>
            <section className="subCont guide" id='guide'>
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>이용 가이드</h4>
                    <p>아임인 파트너스 이렇게 사용하세요!</p>
                </div>
                {/* <!-- guide_01 --> */}
                <div className="guide_01 guideCont">
                    <img src="/images/png/img_section01.png" alt="아임인 파트너스는 무엇인가요?" />
                    <div className="txtBox">
                        <p className="txtTitle">아임인 파트너스는 무엇인가요?</p>
                        <p className="txtSub">소셜핀테크 서비스 '아임인’을 홍보하고 수익을 창출할 수 있는 제휴마케팅 플랫폼입니다. <br/>
                            아임인 파트너스에서 제공하는 콘텐츠를 활용해 SNS, 카페, 블로그 등 다양한 채널에 <br/>
                            홍보하고 신규 가입 또는 서비스 이용으로 전환되는 즉시 수익이 발생합니다.</p>
                        <a className="btnLink" href={"https://bit.ly/3NMTqeI"}>
                            아임인 더 알아보기
                        </a>
                    </div>
                </div>
                {/* <!-- guide_02 --> */}
                <div className="guide_02 guideCont">
                <div className="txtBox">
                        <p className="txtTitle">어떻게 사용하나요?</p>
                        <p className="txtSub">제휴마케팅 플랫폼, 아임인 파트너스 이렇게 이용해 보세요.</p>
                    </div>
                    <ul>
                        <li>
                            <div><img src="/images/png/parCon_2-1.png" alt="간편 회원가입" /></div>
                            <p>간편 회원가입</p>
                            <p>간단하게 회원가입만 하고 아임인 파트너스 활동을 할 수 있어요.</p>
                        </li>
                        <li>
                            <div><img src="/images/png/parCon_2-2.png" alt="홍보 콘텐츠 제작하기" /></div>
                            <p>홍보 콘텐츠 제작하기</p>
                            <p>아임인 파트너스에서 제공하는 콘텐츠로 나만의 포스팅을 만들어보세요.</p>
                        </li>
                        <li>
                            <div><img src="/images/png/parCon_2-3.png" alt="홍보 콘텐츠 등록하기" /></div>
                            <p>홍보 콘텐츠 등록하기</p>
                            <p>나의 SNS, 카페, 블로그 등에 홍보 콘텐츠를 등록하세요.</p>
                        </li>
                        <li>
                            <div><img src="/images/png/introduction_01.png" alt="수익 창출" /></div>
                            <p>수익 창출</p>
                            <p>나의 전용링크를 통해 전환되면 즉시 수익이 생겨요.</p>
                        </li>
                    </ul>
                </div>
                {/* <!-- guide_03 --> */}
                <div className="guide_03 guideCont">
                    <div className="txtBox">
                        <p className="txtTitle">마케팅용 이미지 아직도 제작하시나요?</p>
                        <p className="txtSub">아임인 파트너스에서 제공하는 다양한 콘텐츠를 활용하여 손쉽게 홍보해 보세요!</p>
                    </div>
                    <ul>
                        <li>
                            <div><img src="/images/png/img_promotion.png" alt="웹사이트 상단 메뉴에서 홍보하기 선택" /></div>
                            <p>홍보하기 메뉴에서 <br />콘텐츠 선택</p>
                            <p>홍보에 활용할 수 있는 수많은 <br />이미지와 영상을 제공해 드려요.</p>
                        </li>
                        <li>
                            <div><img src="/images/png/img_post.png" alt="이미지, 동영상을 활용해 블로그, SNS에 게시글을 작성" /></div>
                            <p>이미지, 영상, 링크를 활용해 <br />나의 블로그와 SNS에 게시글 작성</p>
                            <p>다양한 콘텐츠를 활용하여 <br />간편하게 게시글을 작성할 수 있어요.</p>
                        </li>
                        <li>
                            <div><img src="/images/png/img_report.png" alt="웹사이트 상단 리포트를 통해 통계를 확인하고, 수익금 정산" /></div>
                            <p>리포트를 통한 통계 확인 <br />& 매월 수익금 자동 정산</p>
                            <p>리포트를 통해 일별 및 월별 실적을 확인하고 <br />매월 수익금을 자동으로 정산 받아보세요.</p>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Guide;
