import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PageLayout from'container/PageLayout';
import Error404 from 'page/errorPage/404';
import PromoApp from 'page/promotion/PromoApp';

const App = () => {
  return (
    <>
      <Routes>
        <Route exact={true} path="/" element={<PageLayout/>}></Route>
        {/* 회원 정보 */}
        <Route path="/myinfo/IdInquiry" element={<PageLayout/>}></Route>
        <Route path="/myinfo/Join/:rmCd?" element={<PageLayout/>}></Route>
        {/* <Route path="/myinfo/Join" element={<PageLayout />}></Route> */}
        <Route path="/myinfo/Login" element={<PageLayout />}></Route>
        <Route path="/myinfo/Myinfo" element={<PageLayout />}></Route>
        <Route path="/myinfo/PwInquiry" element={<PageLayout />}></Route>
        <Route path="/myinfo/Result" element={<PageLayout />}></Route>
        <Route path="/myinfo/Withdrawal" element={<PageLayout />}></Route>
        <Route path="/myinfo/Auth" element={<PageLayout />}></Route>
        <Route path="/myinfo/Authfail" element={<PageLayout />}></Route>
        <Route path="/myinfo/PopupPage" element={<PageLayout />}></Route>
        {/* 게시판 */}
        <Route path="/notice/Notice" element={<PageLayout />}></Route>
        <Route path="/notice/NoticeView" element={<PageLayout />}></Route>
        {/* 약관 */}
        <Route path="/private/Agreement" element={<PageLayout />}></Route>
        <Route path="/private/Private" element={<PageLayout />}></Route>
        <Route path="/private/OperationalPolicy" element={<PageLayout />}></Route>
        {/* 홍보하기 */}
        <Route path="/promotion/Promotion" element={<PageLayout />}></Route>
        {/* 리포트 */}
        <Route path="/report/Calculate" element={<PageLayout />}></Route>
        <Route path="/report/Performance" element={<PageLayout />}></Route>
        <Route path="/report/PerformanceView" element={<PageLayout />}></Route>
        <Route path="/report/Recommend" element={<PageLayout />}></Route>
        {/* 공지사항 */}
        <Route path="/usage/Ask" element={<PageLayout />}></Route>
        <Route path="/usage/AskView" element={<PageLayout />}></Route>
        <Route path="/usage/AskWrite" element={<PageLayout />}></Route>
        <Route path="/usage/Guide" element={<PageLayout />}></Route>
        <Route path="/usage/Inquiry" element={<PageLayout />}></Route>
        <Route path="/usage/Introduction" element={<PageLayout />}></Route>

        {/* 앱 다운로드 URL 이동 페이지 */}
        <Route path="/promoApp/*" element={<PromoApp />} ></Route>

        <Route path="*" element={<Error404 />}></Route>
      </Routes>
    </>
    );
}

export default App;
