import React from 'react';

const TableListB = ({ item, index, totalCnt, pageNum, pageRowCnt, pageMoveA, isAsk }) => {
    const categoryClass = isAsk
        ? item.answer !== null
            ? 'category categoryCompletion'
            : 'category categoryWaiting'
        : item.noticeTp === 1
        ? 'category categoryNoti'
        : 'category categoryIssue';

    const categoryText = item.fanswerStatus || item.fnoticeTp;
    const dateText = item.f_modDate || item.f_regDate;
    const url = isAsk ? `/usage/AskView?no=${item.boardIdx}` : `/notice/NoticeView?no=${item.boardIdx}`;

    return (
        <li key={item.boardIdx} onClick={() => pageMoveA(url)}>
            <div className="notiInfo">
                <span className={categoryClass}>{categoryText}</span>
                <span className="no">NO.{(totalCnt - (pageNum - 1) * pageRowCnt - index)}</span>
            </div>
            <div className="notiTitle">
                <p>{item.subj}</p>
            </div>
            <span className="date">{dateText}</span>
        </li>
    );
};

export default TableListB;
