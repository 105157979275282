import React, { useEffect, useState, useRef  } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import { smoothScrollTo } from 'components/SmoothScroll';
import Pagination from 'components/Pagination';
import 'common/css/usage/inquiry.css'

const Inquiry = () => {

    /****************************************************/
    const itemRefs = useRef([]);
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호
    const [tabTp, setTabTp] = useState(1); // 현재 탭 번호

    const [totalCnt, setTotalCnt] = useState(20);
    
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수

    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    const paraGetTab = parseInt(urlParams.get("tab"));
    /****************************************************/
    
    // 탭, 페이지 변경, 뒤로가기 이벤트
    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
        !paraGetTab ? setTabTp(1) : setTabTp(paraGetTab);
        onList();
        setOpenIndex(null); // 드롭다운 초기화

        smoothScrollTo(0, 60); // 600ms 동안 스크롤
        /*
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        */

    }, [paraGetPage, paraGetTab])
    
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    // 페이지 이동
    const changePages = (page) => {
        // setPageNum(page);
        movePage(`/usage/Inquiry?page=${page}&tab=${tabTp}`);
    }
    
    // 탭 이동
    const changeTabs = (tab) => {
        // setTabTp(tab)
        movePage(`/usage/Inquiry?page=${1}&tab=${tab}`);
    }

    //리스트 검색
    const [isList, setIsList] = useState(true);
    const onList = () => {
        setIsList(!isList);
    };

    // 질문 답변 드롭다운
    const [openIndex, setOpenIndex] = useState(null);
    const wrapOpen = (index) => {
        setTimeout(() => {
            if (itemRefs.current[index]) {

                // itemRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' }); // 해당위치로 스크롤
                const elementPosition = itemRefs.current[index].getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - 63; // 메뉴만큼 더이동
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }, 100);
        setOpenIndex(openIndex => openIndex === index ? null : index);
    }

    // 검색조건
    const [keyword, setKeyword] = useState('');
    const handleInputChange = (e) => {
        setKeyword(e.target.value);
    };
    
    // Enter 키가 눌렸을 때 검색 실행
    // const [enter, setEnter] = useState(true);
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // console.log(keyword);
            setKeyword(e.target.value);
            movePage(`/usage/Inquiry?page=${1}&tab=${tabTp}`);
            onList();
            // setEnter(!enter);
        }
    };

    /*************************
     * 자주묻는 질문 리스트
     **************************/
    const [inquiryData, setInquiryData] = useState([]);
    useQuery(['inquiryList', isList], async () => {
        const requestData = { 
            params: { boardTp: "Q", showTp: 1, questionTp: tabTp, sValue: keyword, page: pageNum - 1, size: pageRowCnt } 
        };

        const res = await api.get(`/api/v1/boardList`, requestData);
        if(res.status === 200) {    
            setInquiryData(res.data.content);
            setTotalCnt(res.data.totalElements)
        }
    });

    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 문의</span>
                    <span onClick={() => pageMoveA('/usage/Inquiry')}>자주 묻는 질문</span>
                </div>
            </div>
            <section className="subCont inquiry" id='inquiry'>
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>자주 묻는 질문</h4>
                    <p>궁금한 사항이 있으시면 FAQ를 확인해보세요.</p>
                    <div className="addSearch">
                        <input 
                            type="text" 
                            enterkeyhint="search"
                            placeholder="궁금한 내용을 검색해 보세요." 
                            value={keyword}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                </div>
                <div className="tabBox">
                    <ul>
                        <li className={tabTp === 1 ? 'active' : ''}><a href="javascript:;"  onClick={(e) => changeTabs(1)}>서비스</a></li>
                        <li className={tabTp === 2 ? 'active' : ''}><a href="javascript:;"  onClick={(e) => changeTabs(2)}>가입 & 탈퇴</a></li>
                        <li className={tabTp === 3 ? 'active' : ''}><a href="javascript:;"  onClick={(e) => changeTabs(3)}>수익 & 정산</a></li>
                    </ul>
                </div>
                <div className="inquiryCont">
                    <ul className="">
                        { 
                            Array.isArray(inquiryData) && (
                                totalCnt === 0 ? (
                                    <p className="noData">등록된 내용이 없습니다.</p>
                                ) : (
                                    inquiryData.map((inquiry, index) => (
                                        <li key={inquiry.boardIdx} ref={(el) => (itemRefs.current[index] = el)} onClick={() => wrapOpen(index)}>
                                            <div className="qusetion">
                                                <div className="qusetionTitle">
                                                    <span className="category categoryQuestion">질문</span>
                                                    <p>{ inquiry.subj }</p>
                                                </div>
                                                <a href="javascript:;" onClick={(e) => e.preventDefault()} className="">
                                                    <img src="/images/btn/chevron-down_g.svg" alt="열기" />
                                                </a>
                                            </div>
                                            {/* <!-- {openIndex === index && ( --> */}
                                            
                                                <div className="answer ck-content" style={{ display: openIndex === index ? 'flex' : 'none' }}>
                                                    <span className="category categoryAnswer">답변</span>
                                                    <div dangerouslySetInnerHTML={{ __html: inquiry.conts }} />
                                                </div>
                                            {/* <!-- )} --> */}
                                            
                                        </li>
                                )))
                            )                        
                        }
                    </ul>
                </div>
                {totalCnt > 0 && (
                    <div className="pagingDiv">
                        <Pagination
                            totalPage={totalPage === 0 ? 1 : totalPage}
                            limit={pageSize}
                            page={pageNum}
                            setPage={changePages}
                        />
                    </div>
                )}
            </section>
        </>
    )
}

export default Inquiry;
