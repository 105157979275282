import React from 'react';
import 'common/css/private/agreement.css';
import 'common/css/policy.css';

const Agreement = () => {
    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span>홈</span>
                    <span>서비스 이용약관</span>
                </div>
            </div>
            <section className="subCont agreement policy" id="agreement">
                {/* <!-- 컨텐츠 --> */}
                <h4>서비스 이용약관</h4>
                <select name="" id="">
                    <option value="">2024.07.01</option>
                </select>
                <h2 style={{marginTop: 0}}>제1조 목적</h2>
                <p>
                    본 이용약관(이하 “약관”이라 합니다)는 (주)티웨이브(이하 “회사”)라 합니다)가 제공하는 성과형 광고 플랫폼 아임인 파트너스(<a
                    href="http://minpartners.kr/">iminpartners.kr</a>)의 이용과 관련하여 회사와 회원 사이의 권리ᆞ의무, 책임사항, 서비스 이용절차 및 그 밖의
                    제반 사항을 명확히 하여 “회원”과 “회사”의 간에 필요한 사항을 규정함을 목적으로 합니다.
                </p>
                <h2 className="title">제2조 용어의 정의</h2>
                <ol className="top-level">
                    <li>이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 이 약관에서 정의되지 않은 용어는 일반 거래 관행에 따라 정의된 의미를 가집니다.
                        <ol className="nested">
                            <li>
                                아임인 파트너스 서비스(이하 “아임인 파트너스” 또는 “서비스”라고 합니다)란 회사가 제공하는 서비스 링크를 제3자에게 전달하거나, 회원이 운영하는 미디어에
                                게재하여 방문자의 관심에 의해 발생한 클릭을 통해 회사가 얻은 수익을 회사와 회원이 일정한 비율로 홍보에 대한 수수료를 제공하는 서비스를 의미합니다.
                            </li>
                            <li>
                                “회원”이란 회사가 제공하는 서비스 링크를 제3자에게 전달하거나 자신의 미디어에 게재하고, 아임인 파트너스 내 공유하기 기능을 통해 그 성과에 대한 수익을
                                취할 목적으로 이 약관에 동의하고 서비스 가입자로 승인을 받은 자를 의미합니다. 회원은 성과에 대해 회사로부터 약정된 대가를 받게 됩니다.
                            </li>
                            <li>“추천인”이란 아임인 파트너스 서비스에 가입 후 이용하고 있는 “기존 회원”을 의미합니다.</li>
                            <li>“피추천인”이란 함은 “추천인”의 초대 또는 공유 링크에 응답하여 아임인 파트너스에 가입한 “신규 회원”을 의미합니다.</li>
                            <li>“미디어”란 이 약관에 따라 회사의 광고를 게재하는 매체로 회원이 운영하는 SNS, 블로그, 웹사이트 등을 의미합니다.</li>
                            <li>“광고매체”란 “회원”가 직접 또는 “플랫폼”을 통하여 제공하는 “광고소재”가 게재되는 영역을 의미하며, PC, 모바일 웹사이트 및 모바일 어플리케이션 등을
                                포함합니다.
                            </li>
                            <li>“광고소재”란 “회원”이 “회사”의 승인을 받아 광고매체에 게재되는 텍스트, 이미지, 동영상 형태의 제작물 및 클릭 후 연결되는 연결 페이지 주소 등
                                “회사”의 광고 내용을 의미합니다.
                            </li>
                            <li>“광고매체 이용자”라 함은 광고매체를 이용하는 이용자를 의미하며, 광고매체의 회원 또는 “비회원 모두를 포함합니다.</li>
                            <li>“수익”이란 회사의 광고를 회원의 미디어에 게재하여 매출이 발생했을 때 회사가 회원에게 지급하게 되는 금액을 의미하며 정산이 완료된 ‘수수료’와 동일한 의미를
                                갖습니다.
                            </li>
                            <li>“부당 수익”이란 회원이 약관, 운영정책, 또는 관련 법령 등을 위반하거나 방문자가 약관, 운영정책 또는 관련법령에서 금지한 행위를 하여 회원에게 발생한
                                수익을 의미합니다.
                            </li>
                        </ol>
                    </li>
                    <li>본 약관에서 사용하는 용어 중 본 약관에서 정하지 않은 부분은 “회사”와 “회원”간 체결한 서비스 이용약관 및 관계법령에 따릅니다.</li>
                </ol>
                <h2 className="title">제3조 약관의 명시, 효력 및 개정</h2>
                <ol className="top-level">
                    <li>회사는 약관의 내용을 회원이 쉽게 알 수 있도록 “아임인 파트너스” 초기 화면 또는 연결화면을 통하여 본 약관을 게시합니다.</li>
                    <li>회사는 이 약관에서 규정되지 않은 세부적인 내용은 별도의 “아임인 파트너스 운영 정책(이하 “운영정책”이라 합니다)으로 규정할 수 있고, 이를 서비스의 관련 화면 등을
                        통하여 공지하기로 합니다. 또한, 이러한 운영정책은 이 약관의 일부를 구성하며, 이 약관과 동일한 효력이 있습니다.
                    </li>
                    <li>회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관(또는 운영정책, 이하 동일함)을 개정할 수 있습니다.</li>
                    <li>회사가 약관을 개정하는 경우 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관 적용 일자 7일 전 부터 적용일자 전일까지 공지합니다. 다만,
                        회원에게 불리하게 개정되는 경우 또는 회원에게 중대한 영향을 미칠 수 있는 개정인 경우에는 적용일자 30일 전부터 공지합니다.
                    </li>
                    <li>회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
                        회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                    </li>
                    <li>회원은 개정약관에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 회사에 거부 의사를 표시하고 회원탈퇴를 할 수 있습니다. 회원이 거부
                        의사를 표시한 후 상당한 기간 내에 회원 탈퇴를 하지 않을 경우 회사는 직권 해지 조치를 할 수 있습니다.
                    </li>
                </ol>
                <h2 className="title">제4조 약관의 해석</h2>
                <ol className="top-level">
                    <li>회사는 본 약관에 규정되지 않은 세부적인 내용에 대하여 회사가 수시로 개정할 수 있는 아임인 파트너스 운영정책(이하 “운영정책”이라 합니다)을 제정하여 운영할 수 있으며,
                        해당 내용을 아임인 파트너스 초기화면 또는 연결화면을 통하여 게시하거나 회원에게 사전 안내합니다.<br/> 이때, 해당 운영정책은 이 약관의 일부를 구성하며, 이 약관과 같은 효력이
                        있습니다. 만약, 운영정책과 이 약관의 내용이 충돌할 때는 이 약관이 우선합니다.
                    </li>
                    <li>회사는 필요할 경우 본 약관 이외에 개별약관 또는 추가 약관(이하 “개별약관 등”이라 합니다)을 별도로 제정할 수 있으며, 회원이 개별약관 등에 동의한 경우, 개별약관 등과
                        본 약관에 상충하는 내용이 있을 때 개별약관 등이 우선으로 적용됩니다.
                    </li>
                    <li>이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따릅니다.</li>
                    <li>본 이용약관에 명시된 한 개 또는 수개의 조항이 법령에 따라 무효로 되는 경우에도 본 이용약관에 명시된 나머지 조항의 효력은 여전히 유효합니다.</li>
                </ol>

                <h2 className="title">제5조 회원가입</h2>
                <ol className="top-level">
                    <li>회원가입은 서비스를 이용하고자 하는 자가 본 약관의 내용에 동의한 다음, 회사가 정한 절차에 따라 가입 신청을 하고 회사가 이를 승낙함으로써 이루어집니다.</li>
                    <li>회사는 다음 각호의 사유가 있는 경우에는 가입 승낙을 하지 않을 수 있으며, 가입 이후에도 다음 각호의 사유가 확인될 경우에는 “아임인 파트너스” 이용을 제한하거나 직권
                        해지 조치를 취할 수 있습니다.
                        <ol className="nested">
                            <li>회원 가입을 신청한 자가 본 약관에 의하여 회원 자격을 상실한 적이 있는 경우</li>
                            <li>본 약관 위반 등의 사유로 “아임인 파트너스” 이용 제한 중에 회원 탈퇴 후 재가입 신청을 하는 경우</li>
                            <li>실명이 아닌 명의 또는 타인의 명의를 기재한 경우</li>
                            <li>허위 또는 잘못된 정보를 기재 또는 제공하거나 회사가 정한 신청 요건이 미비한 경우</li>
                            <li>회사가 제시한 회원가입 승인 요건을 충족하지 않았거나, 위법 또는 부당한 회원가입 신청임이 확인된 경우</li>
                            <li>만 19세 미만의 자가 가입 신청을 하는 경우</li>
                            <li>기술적 지장 등으로 이용 신청에 대한 승낙이 어려운 경우</li>
                            <li>기타 회사가 합리적인 판단에 의하여 “아임인 파트너스” 이용 제한이 필요하다고 인정하는 경우</li>
                            <li>회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이 있는 경우 등</li>
                        </ol>
                    </li>
                    <li>회원은 회원가입에 필요한 정보를 회사에 제공하는데 동의합니다.</li>
                    <li>회사는 제1항에 따른 회원가입 신청에 대하여 회원가입 신청 절차 중 실명 확인 또는 본인인증을 요청할 수 있습니다.</li>
                    <li>회원 가입의 성립 시기는 회사가 회원 가입 완료 사실을 신청 절차 상에 표시한 시점으로 합니다.</li>
                </ol>

                <h2 className="title">제6조 회원 계정 관리</h2>
                <ol className="top-level">
                    <li>회원 계정의 아이디와 비밀번호에 대한 관리 책임은 회원에게 있으며, 회원은 이를 제삼자가 이용하도록 하여서는 안됩니다.<br/> 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에
                        대한 책임은 회원에게 있습니다.
                    </li>
                    <li>회원은 아이디 또는 비밀번호가 도용되거나 제삼자가 자신의 회원 계정을 이용하고 있음을 인지한 경우에는, 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</li>
                    <li>회원은 가입신청 시 기재한 회원정보에 변경이 있는 경우, “서비스”에 접속하여 직접 수정하는 등의 방법으로 그 변경 사항을 반영하거나, 고객센터를 통하여 회사에 변경 사항을
                        즉시 통지하여야 합니다.
                    </li>
                    <li>회원이 본 조의 내용을 준수하지 않거나 통지를 지연함으로써 발생한 회원의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.</li>
                </ol>

                <h2 className="title">제7조 수익 정산 및 지급</h2>
                <ol className="top-level">
                    <li>회사는 서비스를 통하여 측정된 실적과 그에 따라 별도로 정한 수수료의 종류와 배분 기준 및 운영정책에 따라 산정하고, 최종 확정된 수익을 회원에게 지급합니다.</li>
                    <li>수익 지급은 1개월 단위를 원칙으로 하며, 수익 발생 월의 1일부터 해당월 말일까지의 수익을 ‘지정 지급일’에 일괄 지급합니다.</li>
                    <li>지급의 최저 금액은 2,000원이며, 월간 수익이 2,000원을 넘지 않을 경우에 정산 금액은 이월처리 됩니다. <br/>금액 미달로 인한 이월 가능 기간은 1개월 단위로 총
                        5번까지 자동으로 이월되며, 그 이후에는 6개월간 적립된 금액이 일괄 지급됩니다.
                    </li>
                    <li>회원이 사업자가 아닌 경우 회사는 수익 지급 금액에 따라 발생하는 원천징수소득세 등을 수익에서 공제하고 지급할 수 있습니다.</li>
                    <li>지급 과정에서 회원의 은행계좌정보 등이 유효하지 않아 수익을 지급받지 못하는 경우 귀책사유는 회원에게 있으며 회사는 이를 보상하지 않습니다.</li>
                    <li>전항의 사유로 수익이 지급되지 않은 경우 회사는 제12조에 따라 회원에게 통지하며, 회사는 회원이 은행계좌정보를 수정할 때까지 최대 6개월까지 수익의 지급이 보류됩니다.
                    </li>
                    <li>회사는 수익 지급 및 세무처리를 위하여 개인식별정보의 수집 동의, 세금계산서 등 조세 관련법령에서 규정하는 세무적 증빙의 발행을 요청할 수 있으며, 회원이 개인식별정보수집에
                        동의하지 않거나 회원이 제공한 정보가 유효하지 않은 경우 회사는 수익의 지급을 보류할 수 있습니다.
                    </li>
                    <li>회원은 수익 잔액이 0원 미만이 되는 경우, 해당 금액을 회사에 변제하여야 합니다.</li>
                    <li>회원 또는 회사가 이 약관 해지 의사를 표시한 경우, 회사는 해지 의사를 표시한 날까지의 수익(이하 “잔여수익”이라고 합니다)을 확인하여, 잔여수익이 회사가 정한 지급
                        기준액 이상이 되는 경우 회원에게 지급합니다. 구체적인 지급 방법 및 시기는 운영정책에서 정합니다.
                    </li>
                    <li>이용 제한 상태에 있는 회원에게는 수익의 지급이 제한될 수 있습니다.</li>
                    <li>그 외 구체적인 기준은 운영정책에서 정합니다.</li>
                </ol>

                <h2 className="title">제8조 수익 지급 보류</h2>
                <ol className="top-level">

                    <li>회사는 회원에게 다음 각 호의 사유가 발생할 경우 사유가 해소될 때까지 회원에 대한 수익 지급을 보류할 수 있습니다. 이때, 회사는 해당 사유 발생 사실을 지체없이 회원에게
                        통지합니다.
                        <ol className="nested">
                            <li>수익금 지급 과정에서 증빙 서류 불충분 및 회원이 제공한 정보가 유효하지 않은 경우</li>
                            <li>이용약관의 규정을 위반하거나 위반한 것으로 의심될 만한 타당한 정황으로 발생한 수익이 부당 수익인 것으로 의심되는 경우</li>
                            <li>회원에 의한 부정거래가 의심되는 경우</li>
                            <li>회원의 채권자의 신청에 의한 수익금의 가압류 또는 압류 등 법원의 결정이 있을 경우</li>
                            <li>기타 법령의 규정에 의하거나 합리적인 사유가 있는 경우</li>
                        </ol>
                    </li>
                    <li>지급 보류된 수익금을 청구하고자 할 경우, 회원은 지급 보류 사유가 해소되었음을 확인하기 위하여 회사가 요구하는 서류를 서면으로 제출하여야 합니다.</li>
                    <li>전항의 수익 지급 보류 기간은 정산일로부터 최대 180일로 하며, 회사의 시정 요구에도 해당 기한 이내에 지급 보류 사유가 해소되지 않는 경우, 해당 수익은 소멸하고 회사는
                        수익 지급 의무를 면합니다.
                    </li>
                    <li>회사는 회사의 고의 또는 과실이 없는 한 본 조에 따른 정산 또는 지급 보류 시 지연에 따른 지체 책임을 부담하지 아니합니다.</li>
                    <li>회사는 회원에게 가지는 채권을 회원의 정산금 채권과 상계할 수 있습니다. 이때, 상계에 관하여는 민법 등 관련법령이 정하는 바에 따릅니다.</li>
                </ol>

                <h2 className="title">제9조 수익의 소멸</h2>
                <ol className="top-level">
                    <li>수익은 최초 적립된 시점부터 5개월이 경과한 월의 1일에 먼저 적립된 수익부터 월 단위로 자동 소멸됩니다.</li>
                    <li>수익은 회사가 정한 유효기간 내에서 서비스 이용 시 뿐 만이 아니라, 미이용(회원의 유효한 계정이 있어 회원자격이 유지되고 있는 상태)시에도 유지되며, 만기일 경과 시 자동
                        소멸됩니다.
                    </li>
                    <li>회사는 회원이 적립한 수수료를 소멸예정일이 도래하여 소멸시키는 경우 수익 소멸과 관련된 내용을 최소 1개월 전에 회사가 지정한 방법(문자메세지, e-mail 등)으로 1회
                        이상 안내합니다. 단, 특정 이벤트를 통해 지급된 수수료는 이벤트 진행 시 수수료 유효기간 및 소멸일을 별도로 안내하고 소멸 안내 내용에서는 제외될 수 있습니다.
                    </li>
                    <li>회원의 활동으로 인해 적립되는 수익이 아닌 회사나 제휴사가 이벤트 등의 마케팅 활동으로 회원에게 추가로 제공한 수수료의 유효기간은 회사나 제휴사의 정책에 따라 별도로 책정될
                        수 있으며 이는 별도로 공지합니다.
                    </li>
                    <li>회사는 회원이 탈퇴나 개인정보 삭제를 요청한 경우 탈퇴 또는 삭제 전 잔여 수익에 대한 정보를 안내하여야 합니다.</li>
                    <li>본 약관 회원은 제22조 1항에 정해진 방법으로 탈퇴를 하고자 하는 회원이 잔여 수익을 지급받고자 할 경우 회원탈퇴 요청일 전까지 적립된 수익을 본 약관 및 운영정책이
                        정하는 바에 따라 지급받아야 합니다.
                    </li>
                    <li>탈퇴 전까지 지급받지 않은 수익은 제22조 1항에 의거 즉시 자동으로 소멸됩니다.</li>
                </ol>

                <h2 className="title">제10조 부당 수익의 계산</h2>
                <ol className="top-level">
                    <li>회사는 신고 등에 의해 회원에 대한 수익이 부당 수익인 것으로 의심될 경우 운영 정책에서 정한 절차에 따라 조사, 자료 요청, 지급 보류 등의 조치를 취할 수 있습니다.
                    </li>
                    <li>회사에 의해 부당 수익 확인을 위한 조사가 진행되는 경우 회원은 회사의 조사에 협조하여야 합니다.</li>
                    <li>회사는 회원에 대한 수익이 부당 수익인 것으로 확인된 경우 해당 수익 지급을 거절할 수 있으며 이미 지급된 경우에는 해당 부당 수익 대한 반환을 청구할 수 있습니다. 회원은
                        회사로부터 해당 청구를 받은 경우 지체없이 이를 반환하여야 합니다.
                    </li>
                </ol>

                <h2 className="title">제11조 개인정보의 보호</h2>
                <p>
                    회사는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및
                    사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.
                </p>

                <h2 className="title">제12조 아이디 및 비밀번호의 관리에 대한 의무</h2>
                <ol className="top-level">
                    <li>아이디와 비밀번호에 대한 모든 관리 책임은 회원에게 있으며, 회원은 이를 제3자가 이용하도록 하여서는 안됩니다.</li>
                    <li>아이디와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.</li>
                    <li>회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</li>
                    <li>회원이 전항을 준수하지 않아 발생하는 회원의 손해에 대하여 회사는 어떠한 책임도 지지 않습니다.</li>
                </ol>

                <h2 className="title">제13조 회원에 대한 통지</h2>
                <ol className="top-level">
                    <li>회사는 회원에 대하여 통지하는 경우 본 약관에 별도의 규정이 없는 한 “회원”이 제공한 전자우편주소, (휴대)전화번호, 카카오톡 메시지, “아임인 파트너스”의 로그인 시
                        알림창 등의 수단으로 할 수 있습니다.
                    </li>
                    <li>회사는 회원 전체 또는 불특정 다수의 “회원”에 대하여 통지를 하는 경우 7일 이상 “서비스” 내 공지사항 게시판에 게시함으로써 제1항의 통지를 갈음할 수 있습니다.
                    </li>
                    <li>회원은 회사에 실제로 연락이 가능한 전자우편주소 및 (휴대)전화번호 등의 정보를 제공하고 이를 최신의 상태로 유지하여야 하며 “회사”의 통지를 확인하여야 합니다. 회원이
                        이러한 의무를 소홀히 하여 발생한 불이익에 대하여는 보호 받지 못합니다.
                    </li>
                </ol>

                <h2 className="title">제14조 회사의 의무</h2>
                <ol className="top-level">
                    <li>회사는 본 약관 및 회사가 별도로 고지한 이용정책에 따른 기준 및 지급 절차를 준수하여 수익을 회원에게 지급합니다.</li>
                    <li>회사는 관련법령과 본 약관을 준수하여 계속적이고 안정적으로 “아임인 파트너스” 서비스를 제공하기 위하여 최선을 다하여 노력합니다.</li>
                    <li>회사는 회원이 안전하게 “아임인 파트너스”를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위하여 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
                    </li>
                    <li>회사는 회원의 관련법령, 본 약관 및 운영 정책 등의 준수 여부를 상식적으로 모니터링할 수 있으며, 광고매체 이용자 또는 제3자 등의 의견이나 불만이 제기된 경우
                        “회원”에게 이에 대한 소명을 요청하는 등의 조치를 취할 수 있습니다.
                    </li>
                    <li>회사는 서비스의 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정될 경우 이를 신속하게 처리하여야 하며, “서비스” 내 게시판 또는 회원이 제공한
                        전자우편주소 등을 통하여 “회원”에게 처리과정 및 결과를 전달할 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">제15조 회원의 의무</h2>
                <ol className="top-level">
                    <li>회원은 관련 법령, 본 약관의 규정, 회사의 운영 정책 및 회사가 공지하거나 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안
                        됩니다.
                    </li>
                    <li>회원은 계정을 “아임인 파트너스” 서비스를 정상적으로 이용하기 위한 용도(회원정보 관리, 광고 소재 관리, 수익 정산 등)로만 이용하여야 합니다.</li>
                    <li>회원은 회사의 사전 동의 없이 본 약관상의 권리, 의무를 제3자에게 양도, 이전, 담보제공 등 일체의 처분행위를 할 수 없습니다.</li>
                    <li>부당하게 서비스, 광고 또는 광고매체에 위해가 되는 제3자의 행위를 인지하는 경우 즉시 해당 사실을 회사에 알리고 만약 해당 제삼자와의 거래 관계가 있는 경우에는 이를 즉시
                        중단하여야 합니다.
                    </li>
                    <li>회원은 어떠한 경우에도 회사의 사전 승낙 없이 다음 각 호의 행위를 하는 에이전트(agent), 로봇(robot), 스크립트(script), 스파이더(spider),
                        스파이웨어(spyware) 등의 수단을 제작, 배포, 설치하거나 이를 유도하여서는 안 되며, 기타 불법부정한 방법 등을 통해 다음 각 호의 행위를 하거나 그러한 시도를
                        해서는 안 됩니다.
                        <ol className="nested">
                            <li>회사가 제공하지 않은 방법으로 “아임인”에 접속하거나 “아임인 파트너스” 서비스를 이용하는 행위</li>
                            <li>부정한 방법을 사용하여 광고를 노출하거나 클릭을 반복하여 회사에 손해를 끼치거나 자신 또는 제삼자에게 이익을 주는 행위, 또는 타인에게 그러한 행위를 유도하는
                                행위
                            </li>
                            <li>회사의 서버 또는 설비에 과도한 부하를 야기하는 행위</li>
                            <li>광고 노출 수 및 클릭 수 등을 부정하게 생성하거나 조절하는 행위</li>
                            <li>기타 “아임인” 또는 “아임인 파트너스” 서비스의 정상적 운영을 방해하는 행위</li>
                        </ol>
                    </li>
                    <li>회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
                        <ol className="nested">
                            <li>회원가입을 신청하거나 회원정보 변경 신청 시 허위 또는 타인의 정보를 입력하는 행위</li>
                            <li>회사 또는 제3자의 저작권 등 지식재산권을 침해하는 행위</li>
                            <li>회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                            <li>회사 또는 제3자를 가장 또는 사칭하여 허가 받지 않은 게시판, 스팸메일 등의 활용으로 회사의 명성에 해를 끼치는 행위</li>
                            <li>회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보, 공개되지 말아야 할 기밀정보(클릭 수, CTR 등)를 회사의 사전 서면 승낙 없이 영리
                                또는 비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위
                            </li>
                            <li>광고의 게재를 유도하기 위해 미디어의 콘텐츠 내용과 관계없이 무의미한 키워드를 반복하여 사용하는 행위</li>
                            <li>계정을 매매, 양도, 명의변경, 재 판매하거나 질권의 목적으로 사용하는 행위</li>
                            <li>서비스 이용 권한을 타인에게 공유, 제공, 양도, 중개, 재 판매하는 행위</li>
                            <li>타인의 계정으로 서비스를 이용하는 행위</li>
                            <li>서비스 내에 게재된 광고의 내용, 링크, 순서 및 이에 포함된 정보 등을 변경, 조작하는 등의 행위.</li>
                            <li>회원이 회사와 약속한 방법 이외의 부정한 수익을 추구하여 회사에 고의적인 손실을 입히는 행위.</li>
                            <li>본 서비스 제공 취지에 반하여 방문자의 관심이 아닌 회원이 발생시킨 광고 클릭이나 본인 및 가족 구매를 통해 수익을 발생시키는 행위</li>
                            <li>“아임인”이 그 권리를 보유하고 있는 표장 또는 이와 혼동을 일으킬 정도로 유사한 단어와 동일하거나 유사한 상표를 “아임인”과 오인, 혼동을 일으키는 방식으로
                                상표/도메인으로써 사용하는 행위
                            </li>
                            <li>회원이 미디어에 사실과 다르거나, 과장∙왜곡∙오인의 가능성이 있는 내용을 표시하는 등 표시광고법 기타 관련 법령에 위반되는 내용을 게시하는 행위</li>
                            <li>회원이 제3자 컨텐츠 등에 해당 컨텐츠의 의도와 상관없는 내용이나 회사의 이미지/사업 등에 손실을 끼치는 내용 등의 댓글을 게시하는 행위</li>
                            <li>해당 게시물과 관계없는 브랜드 및 제품명 등 제품정보를 언급하여 제3자에게 피해를 끼치거나 끼칠 우려가 있는 행위</li>
                            <li>“아임인” 또는 “아임인 파트너스”에 공식적으로 공개되지 않은 프로모션 등을 광고하는 행위</li>
                            <li>서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보를 전송하는 행위</li>
                            <li>“아임인 파트너스”의 이용 목적 및 기타 서비스의 운영정책에 위배되는 모든 행위</li>
                            <li>기타 회사의 업무를 방해하는 부당한 행위</li>
                        </ol>
                    </li>
                    <li>회원은 “아임인 파트너스” 서비스 제공 또는 본 조 위반 여부를 확인하기 위하여 회사가 자료 또는 접근권한의 제공 및 관련사실에 대한 소명을 요청하는 경우 이에 성실히
                        임하여야 합니다.
                    </li>
                    <li>회원이 회사의 귀책 없이 관련 법령, 본 약관 또는 운영정책 등을 위반하여 제3자가 회사를 상대로 이의를 제기하거나, 민형사상의 소송 또는 행정 처분 등이 발생하는 경우
                        회원은 본인의 책임과 비용으로 이를 해결하여야 하며, 회사를 면책시켜야 합니다. 이와 관련하여 회사의 귀책사유 없이 회사에 손해가 발생한 경우 손해 전부를 배상합니다.
                    </li>
                </ol>

                <h2 className="title">제16조 서비스의 제공 등</h2>
                <ol className="top-level">
                    <li>회사”는 “회원”에게 아래와 같은 서비스를 제공합니다.
                        <ol className="nested">
                            <li>홍보 컨텐츠 제공</li>
                            <li>수익 관련 리포트 서비스</li>
                            <li>게시판형 서비스(이용문의, 공지사항 등)</li>
                            <li>기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스</li>
                        </ol>
                    </li>
                    <li>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</li>
                    <li>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
                    <li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. <br/>이 경우 회사는
                        제12조 ‘회원에 대한 통지’에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                    </li>
                    <li>회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.</li>
                </ol>

                <h2 className="title">제17조 서비스의 변경</h2>
                <ol className="top-level">
                    <li>회사는 안정적인 서비스의 제공을 위하여 서비스의 내용, 운영상 또는 기술상 사항을 변경할 수 있습니다.</li>
                    <li>회사는 서비스 내용을 변경할 경우 변경 내용과 적용 일자를 명시하여 “아임인 파트너스”에 사전 공지합니다. <br/>다만, 회원의 권리나 의무 및 “아임인 파트너스” 이용과 관련되는
                        실질적인 사항을 변경할 경우 적용 일자 7일 이전에, 회원에게 불리한 변경의 경우 적용 일자 30일 이전에 사전 공지합니다.
                    </li>
                    <li>회사는 서비스의 품질 향상 및 효과 증대를 위하여 회원에 대한 별도의 공지 또는 통지 없이 서비스의 일부 또는 전부의 기능 변경, 개선에 대한 테스트를 실시할 수
                        있습니다.
                    </li>
                    <li>회사는 제1항 내지 제3항에 따른 서비스의 변경이나 테스트 등으로 발생한 회원의 손해에 대하여 고의 또는 중과실이 없는 한 책임을 지지 않습니다.</li>
                    <li>회원”은 제1항의 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 회원 탈퇴를 할 수 있습니다. 회원이 거부 의사를 표시한 후 상당한 기간 내에 회원 탈퇴를
                        하지 않을 경우 회사는 직권 해지 조치를 할 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">제18조 서비스의 중단</h2>
                <ol className="top-level">
                    <li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체, 고장, 시스템 장애, 통신두절 또는 기술상, 운영상, 사업상 합리적인 사유가 있는 경우 “아임인 파트너스”의 전부 또는
                        일부의 서비스 제공을 중단할 수 있습니다.
                    </li>
                    <li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.</li>
                    <li>제1,2항의 경우 회사는 회원에게 사전 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 사후에 통지할 수 있습니다.</li>
                    <li>본 조에 따른 “아임인 파트너스” 또는 “아임인” 서비스 중단으로 인하여 발생한 회원의 손해에 대하여 회사는 고의 또는 과실이 없는 한 책임을 부담하지 않습니다.</li>
                </ol>

                <h2 className="title">제19조 회원의 게시물에 대한 권리 및 의무</h2>
                <ol className="top-level">
                    <li>회원은 자신이 운영하는 미디어 및 게재한 게시물의 내용이 회사 또는 제삼자의 권리에 대한 침해나 지식재산권 및 관련 법령에 대한 위반이 되지 않음을 보증하여야 합니다.
                    </li>
                    <li>회원이 본 조 제1항을 위반하거나 회사가 규정한 게시물 또는 광고물 규격을 지키지 않는 등이 이 약관을 위반(운영정책에서 정한 제한행위 모두 포함)하는 경우, 회사는
                        회원에게 이에 대한 시정 요구 등을 할 수 있으며, 회원이 이를 거부하는 경우 회사는 서비스 이용 제한, 회원 자격상실 등의 조치를 임의로 취할 수 있습니다.
                    </li>
                    <li>회원이 본 조를 위반하여 제삼자의 권리를 침해하거나 지식재산권 및 관련 법령을 위반하였다는 이유로 회사가 금지 청구, 손해배상청구, 고소 등 법적 조치를 당하면 자기
                        비용으로 회사를 면책 시켜야 합니다. 이와 관련하여 회사가 제삼자에게 손해배상책임을 부담하는 경우에는 회사의 손해배상액 및 그 배상과 관련된 부대비용 등(이자 또는 변호사
                        보수 등의 방어비용 포함)을 회원이 전적으로 부담합니다.
                    </li>
                    <li>회원은 자신이 운영하는 광고매체 및 미디어에 서비스 추천의 내용이 포함된 게시물을 게재할 경우, 공정거래위원회의 ‘추천보증 등에 관한 표시·광고 심사지침’에 따라
                        추천·보증인인 회원과 회사의 경제적 이해관계에 대하여 공개하는 등 관련 법령 및 행정청의 가이드에 따른 의무를 준수하여야 합니다.
                    </li>
                    <li>본조에도 불구하고 회원은 지식재산권 침해 등 이용약관 및 운영정책을 위반하는 콘텐츠가 포함된 게시물이 발견되는 즉시 책임지고 삭제하여야 합니다.</li>
                </ol>

                <h2 className="title">제20조 실적 측정</h2>
                <ol className="top-level">
                    <li>회원의 미디어를 통해 발생하는 모든 실적 집계는 회사에서 제공되는 실적 측정을 기준으로 합니다.</li>
                    <li>회원은 서비스 화면을 통하여 실적을 확인할 수 있으며, 회원은 이에 대해 이의를 제기할 수 없습니다.</li>
                </ol>
                <h2 className="title">제21조 데이터의 수집 및 이용</h2>
                <ol className="top-level">
                    <li>회사는 서비스를 제공함에 있어 비정상적인 방법의 광고 여부 판단 등을 위하여 OS와 디바이스의 정보를 수집할 수 있습니다. <br/> 회사는 수집하는 데이터의 주요 항목에 대해서는
                        운영 정책에서 정합니다.
                    </li>
                    <li>회사는 전항에 따라 수집한 정보를 회사가 제공하는 서비스 등을 위하여 이용할 수 있으며, 관련 법령에 따라 제삼자에게 제공할 수 있습니다.</li>
                </ol>

                <h2 className="title">제22조 이용제한</h2>
                <ol className="top-level">
                    <li>회사는 다음 각호에 해당하는 사유가 발생하거나 확인되면 회원의 서비스 이용을 사전에 제한하거나, 수익금 지급 중지, 회원자격 상실 등 단계적으로 이용을 제한할 수 있습니다.
                        <ol className="nested">
                            <li>회원이 관련 법령, 이 약관 또는 운영정책상 의무를 위반하거나 기타 서비스의 정상적인 운영을 방해하는 경우</li>
                            <li>회원이 등록한 광고매체에 등록한 광고소재가 각 목에 해당하는 경우
                                <ul className="nested">
                                    <li>회사 또는 광고매체에 법률적 또는 재산적 위험을 발생시키거나 발생시킬 우려가 있는 경우</li>
                                    <li>회사 또는 광고매체의 명예, 평판, 신용이나 신뢰도를 훼손하거나 훼손할 우려가 있는 경우</li>
                                    <li>광고매체 또는 광고매체 이용자가 광고 게재를 합리적인 사유로 거부하거나 광고 게재에 합리적인 이의를 제기하여 “회사”가 이를 인정하는 경우
                                    </li>
                                    <li>광고소재의 내용이 사실과 다르거나, 광고매체 이용자를 오인혼동하게 하거나 그러한 우려가 있는 경우</li>
                                    <li>“아임인 파트너스” 또는 광고매체의 품질을 저하하거나 저하시킬 우려가 있는 경우</li>
                                    <li>광고소재가 지식재산권, 초상권, 개인정보 등 타인의 권리를 침해하거나 그러한 가능성이 있는 경우</li>
                                    <li>광고소재가 사회 또는 정치적 논란을 주제로 하는 경우</li>
                                    <li>광고소재가 공공질서 및 미풍양속에 어긋나거나 그러한 가능성이 있는 경우</li>
                                    <li>광고소재에 청소년 또는 일반 국민의 정서를 저해할 우려가 있는 정보, 문장, 도형, 이미지, 영상 등이 포함된 경우</li>
                                    <li>광고소재가 과도한 선정성, 폭력성, 공포 및 혐오 감정 등을 유발하는 경우</li>
                                    <li>광고소재가 다른 회원 또는 제삼자를 비방하는 내용이거나, 타인의 명예를 훼손할 가능성이 있는 경우</li>
                                    <li>광고소재가 관련 법령 또는 회사의 약관, 운영 정책 및 회사가 공지하거나 통지한 사항 등에 위반되는 경우</li>
                                </ul>
                            </li>
                            <li>회원의 “아임인 파트너스” 이용과 관련하여 회사 또는 회원이 행정기관 기타 정부 기관으로부터 경고, 고발 등의 조치를 당하는 경우</li>
                            <li>회원의 “아임인 파트너스” 이용과 관련하여 회사 또는 회원과 제삼자 사이에 분쟁이 발생하는 경우</li>
                            <li>제5조 제2항에 따른 승낙 제한 사유가 있는 것을 사후적으로 발견한 경우</li>
                        </ol>
                    </li>
                    <li>회사가 본 조에 따라 회원의 “아임인 파트너스” 이용을 제한하는 경우 제10조에서 정하는 방법으로 “회원”에게 통지합니다. <br/> 이 경우 “회사”는 원칙적으로 이용 제한 전에
                        회원에게 일정한 기간을 정하여 이의신청을 할 수 있도록 합니다.
                    </li>
                    <li>제2항에도 불구하고 관련 법령, 본 약관 또는 “아임인 파트너스” 및 회사가 공지하거나 통지한 사항 등에서 별도로 정하는 사유에 해당하여 이용이 제한되는 경우 회사는 별도의
                        이의신청 기간을 부여하지 않거나, 이용 제한 조치 이후에 이의신청 기간을 부여할 수 있습니다.
                    </li>
                    <li>회원은 본 조에 따른 이용 제한에 대하여 회사가 정하는 절차에 따라 이의신청을 할 수 있습니다. <br/>회사는 회원의 이의가 정당하다고 인정되면 즉시 이용 제한 조치 등을
                        해제합니다.
                    </li>
                    <li>회사는 회원이 본 조에 따른 이용 제한 조치를 받은 경우, 회원이 이용하는 회사의 다른 서비스에 대하여도 이용을 제한하거나 이용계약을 해지할 수 있습니다.</li>
                    <li>회사는 회원의 관련 법령, 본 약관 또는 운영정책 등의 위반사항이 심각한 것으로 판단되는 경우, 회원 탈퇴 처리 및 향후 회원의 재가입 신청 승인을 거부할 수 있습니다.
                        이용 제한의 구체적인 기준 등에 대하여는 운영정책에서 정합니다.
                    </li>
                </ol>

                <h2 className="title">제23조 해지(탈퇴)</h2>
                <ol className="top-level">
                    <li>회원은 언제든지 직접 서비스에 접속하여 이 약관의 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다. <br/> 다만, 탈퇴 신청
                        시점에회원의 미정산 수익이 있는 경우 해당 수익은 소멸하고 회사는 수익 지급 의무를 면합니다.
                    </li>
                    <li>본 조 제1항의 경우 회원은 이 약관의 해지일 이후 재가입 신청을 할 수 있습니다. 또한, 재가입하는 경우에도 회원의 기존의 이용 기록이 승계되지는 않습니다.</li>
                    <li>회사는 회원에게 다음 각 호의 어느 하나에 해당하는 사유가 있는 경우에는 해당 회원과의 이 약관을 해지할 수 있습니다.<br/> 이 경우 회원에게 의견제출의 기회를 사전에
                        부여할 수
                        있습니다.
                        <ol className="nested">
                            <li>회사 또는 제3자의 권리, 명예, 신용 및 그 밖의 정당한 이익을 침해하거나 관련 법령 또는 공서 양속에 위배되는 행위를 한 경우</li>
                            <li>회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우</li>
                            <li>제4조에 따른 승낙거부사유가 있는 경우</li>
                            <li>회원이 회사로부터 요청 받은 정보 또는 증빙자료를 제공하지 아니하거나 허위로 제공한 경우</li>
                            <li>이 약관 등에서 금지 또는 제한행위(등록제한, 이용제한 행위 등 모두 포함)를 한 경우</li>
                            <li>이 약관에 따른 의무를 위반한 경우</li>
                            <li>그 밖에 회사가 합리적인 이유로 서비스의 제공을 거부할 수 있는 경우</li>
                        </ol>
                    </li>
                    <li>회사는 회원에게 다음 각 호의 어느 하나에 해당하는 사유로 인하여 이 약관을 정상적으로 이행할 수 없게 된 경우에는 그 상대방에게 별도의 최고 없이 해지의 통지를 함으로써
                        이 약관을 해지할 수 있습니다.
                        <ol className="nested">
                            <li>이용계약에 따른 의무를 위반하고 이를 시정하지 아니한 경우</li>
                            <li>부도 등 금융기관의 거래정지, 회생 및 파산절차의 개시가 있거나 이에 해당할 상당한 우려가 있는 경우</li>
                            <li>법률, 법원의 판결이나 명령, 영업정지 및 취소 등의 행정처분, 또는 행정지도 등 정부의 명령 등</li>
                            <li>가압류, 압류, 가처분, 경매 등의 강제집행 등</li>
                            <li>주요 자산에 대한 보전처분, 영업양도 및 합병 등</li>
                        </ol>
                    </li>
                    <li>본 조. 제3항 및 제4항에 따라 해지된 회원 또는 이용제한 기간 중 해지한 회원은 재가입이 제한될 수 있습니다.</li>
                </ol>

                <h2 className="title">제24조 지식재산권 등</h2>
                <ol className="top-level">
                    <li>회원이 작성한 콘텐츠, 게시물 등은 회원의 동의 없이 회사 가 제공하는 서비스 및 관련 프로모션 등에 노출되거나 해당 노출을 위해 필요한 범위 내에서 전부 또는 일부 수정,
                        복제, 편집 등의 행위를 할 수 있으며 회원은 본 항에 따른 일시적 사용에 대한 무상 사용권을 회사에 부여합니다.
                    </li>
                    <li>회사가 제공하는 서비스와 관련하여 회사가 자체적으로 제작한 저작물(회원으로부터 제공받은 콘텐츠를 활용하여 회사가 제작한 2차적 저작물을 포함)에 관한
                        지식재산권(특허ㆍ실용실안ㆍ디자인ㆍ상표권과 저작권 포함)은 회사에게 있으며, 회원은 회사의 서면 동의 없이 회사의 지식재산권을 이용해서는 아니됩니다.
                    </li>
                    <li>본 조의 내용은 이 약관이 종료된 이후에도 유효하게 존속합니다.</li>
                </ol>

                <h2 className="title">제25조 손해배상</h2>
                <ol className="top-level">
                    <li>관련 법령, 본 약관 또는 운영 정책에서 별도로 규정한 사항을 제외하고, 회사 또는 회원의 고의 또는 과실로 상대방에게 손해가 발생한 경우 귀책사유 있는 당사자는 상대방이
                        입은 손해를 배상하여야 합니다.
                    </li>
                    <li>회사는 회원의 실적이 없는 동안의 서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.</li>
                </ol>

                <h2 className="title">제26조 책임제한</h2>
                <ol className="top-level">
                    <li>회사는 천재지변, 전쟁 및 분쟁, 정전, 분산 서비스 공격(DDoS 공격), 인터넷 서비스 제공자(ISP)의 네트워크 장애 등 이에 준하는 불가항력적인 사태로 인하여
                        “서비스”를 제공할 수 없거나 정상적인 광고 집행이 불가한 경우 이에 대한 책임을 지지 않습니다.
                    </li>
                    <li>회사는 회원의 귀책사유로 인하여 발생한 “서비스” 이용 장애 및 광고 미집행에 대하여 책임을 지지 않습니다.</li>
                    <li>권한 설정을 통하여 권한을 위탁하거나 위탁 받은 “회원”간에 분쟁이 발생하는 경우 “회사”는 귀책사유가 없는 한 책임을 지지 않습니다.</li>
                    <li>회원이 직접 광고매체에 제공한 광고 소재가 지식재산권을 포함한 제3자의 권리를 침해하거나 허위정보 등을 포함함으로써 관련법령 위반 또는 광고매체 이용자에 대한 손해를
                        발생시킨 경우 회사는 이로부터 면책되며 이에 대한 모든 법적 책임은 회원에게 있습니다.
                    </li>
                    <li>회사는 광고매체를 이용하여 게시 또는 전송한 자료 등과 관련하여 회원에게 손해가 발생하거나 자료 등의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도
                        이로 인한 모든 법적 책임은 회원에게 있습니다.
                    </li>
                    <li>회사는 회원이 광고매체에 등록한 광고소재 및 광고소재에 연결된 페이지에서 특정 브라우저나 앱 등 일부 환경에서만 제한적으로 발생하는 오류 또는 장애에 대해서는 책임을 지지
                        않으며, 이에 대한 사전 확인 의무는 회원에게 있습니다.
                    </li>
                </ol>

                <h2 className="title">제27조 해외이용</h2>
                <ol className="top-level">
                    <li>회사는 대한민국 내에 설치된 서버를 기반으로 서비스를 제공, 관리하고 있습니다. 따라서, 회사는 회원이 대한민국 영토 이외의 지역에서 서비스를 이용하고자 하는 경우 서비스의
                        품질 또는 사용성을 보장하지 않습니다.
                    </li>
                    <li>회원은 대한민국의 영토 이외의 지역에서 서비스를 이용하고자 하는 경우 스스로의 판단과 책임에 따라서 이용 여부를 결정하여야 하고, 특히 서비스의 이용과정에서 현지 법령을
                        준수할 책임은 회원에게 있습니다.
                    </li>
                </ol>

                <h2 className="title">제28조 약관의 해석</h2>
                <ol className="top-level">
                    <li>본 약관에 대한 동의 이전에 회사가 제공하는 광고 관련 제반 서비스와 관련하여 회원과 회사간 체결한 계약 또는 합의 내용과 본 약관의 내용이 상충하는 경우 본 약관이
                        우선하여 적용됩니다.
                    </li>
                    <li>본 약관에 대한 동의 이후 회원과 회사가 개별적으로 합의한 사항이 본 약관에 정한 사항과 다를 때에는 개별적 합의사항이 본 약관에 우선하여 적용됩니다.</li>
                    <li>본 약관의 내용 중 관련 법령의 강행규정과 배치되는 부분이 있는 경우, 그 부분에 한하여 본 약관의 해당 규정은 무효로 합니다.</li>
                    <li>본 약관에 명시적으로 규정되지 않고, 당사자간에 미리 합의되지 않은 사항의 처리에 관하여는 관련 법령 및 상관습에 따릅니다.</li>
                </ol>

                <h2 className="title">제29조 준거법 및 재판 관할</h2>
                <ol className="top-level">
                    <li>회사와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                    <li>본 약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생할 경우 상호합의에 따라 해결합니다.</li>
                    <li>전 항에도 불구하고 합의가 되지 않는 경우 진행되는 회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
                        전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
                    </li>
                    <li>해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.</li>
                </ol>

                <h2 className="title">부칙</h2>
                <p>제1조 (시행일) 본 약관은 2024년 7월 1일부터 시행합니다.</p>

            </section>
        </>
    )
}

export default Agreement;
