import React, {useState} from 'react';
import 'common/css/private/private.css';
import 'common/css/policy.css';

const Private = () => {
    const [selectedDate, setSelectedDate] = useState('2024.11.12');

    // 셀렉트 박스 변경 핸들러
    const handleSelectChange = (e) => {
        setSelectedDate(e.target.value);
    };
    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span>홈</span>
                    <span>개인정보 처리방침</span>
                </div>
            </div>
            <section className="subCont privte policy" id="privte">
                {/* <!-- 컨텐츠 --> */}
                <h4>개인정보 처리방침</h4>
                <select value={selectedDate} onChange={handleSelectChange}>
                    <option value="2024.11.12">2024.11.12</option>
                    <option value="2024.07.01">2024.07.01</option>
                </select>

                <h2 style={{marginTop: 0}}>총칙</h2>
                <p>”아임인 파트너스” 서비스를 운영하는 (주)티웨이브(이하 회사라 함)는 “아임인 파트너스 회원”(이하 "회원"이라 함)의 개인정보를 매우 중요하게 생각하고 있습니다.</p>
                <p>회사는 이용자의 권익 보호를 위해 「개인정보 보호법」및 관계 법령상의 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 이용자의 권익 보호에 최선을 다하고
                    있습니다.</p>
                <p>본 개인정보 처리방침은 회사가 제공하는 서비스 이용에 적용되고 다음과 같은 내용을 담고 있습니다.</p>

                <h2 className="title">제1조 개인정보의 처리 목적</h2>
                <p>회사는 서비스 이용에 필요한 개인정보를 다음과 같은 목적을 위하여 처리합니다. </p>
                <p>처리하는 개인정보는 다음의 목적 이외의 용도로 이용되지 않으며 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</p>

                <table style={{border: '1px solid black', width: '100%'}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black',}}>구분</th>
                        <th style={{border: '1px solid black',}}>목적</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>플랫폼 회원가입 및 관리</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>회원제 서비스 이용에 따른 본인 확인</li>
                                <li>이용자 식별, 회원 가입, 연령 확인, 가입의사 및 가능여부 확인</li>
                                <li>불량회원의 부정 이용 방지와 비인가 사용 방지, 불만처리 등 민원 처리, 서비스 제공에 관한 계약 이행</li>
                                <li>광고 및 마케팅 정보 제공 등</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>민원 처리</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>전화 문의 및 채팅 상담</li>
                                <li>이용자에 대한 분쟁 해결 및 민원처리, 문의 사항 대응, 공지사항 전달, 분쟁 조정을 위한 기록 보존 등</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>서비스 관리</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>서비스 제공, 청구서 발송, 무료 콘텐츠 제공, 정산, 이자 소득세 납부에 관련한 원천징수 업무</li>
                                <li>신규 서비스 개발, 이용 기록 분석, 서비스 개선</li>
                                <li>서비스 안정성 확보, 부정 이용자 방지, 이용자 식별</li>
                                <li>통계학적 특성 분석에 기반한 회원 별 맞춤형 서비스 제공 등</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>마케팅 및 품질 관리</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>프로모션 및 기타 서비스 등 안내</li>
                                <li>이벤트 참여 및 고지사항 안내, 경품/상품 배송, 제세공과금 부과</li>
                                <li>앱 푸시, 카카오톡, 문자 메시지 광고 발송 등</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h2 className="title">제2조 처리하는 개인정보의 항목</h2>
                <p>회사는 회원 가입 또는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다</p>
                <h3 style={{marginTop: 10, marginBottom: 10}}>1. 필수 수집 항목</h3>
                <table style={{border: '1px solid black', width: '100%'}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>구분</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>항목</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>
                            <p style={{fontWeight: 'bold'}}>1. 회원 가입</p>
                            <p>- 본인 확인 및 인증</p>
                        </td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>
                                    ID(이메일 주소), 비밀번호, 휴대전화번호, 개인신용정보(은행, 계좌번호), 고유식별정보(주민등록번호), 생년월일 회원코드
                                    약관(서비스 이용 약관, 개인정보 수집 및 이용) 동의 여부, 쿠키, 서비스 이용 기록
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>
                            <p style={{fontWeight: 'bold'}}>2. 민원 처리</p>
                            <p>- 회원의 민원 처리, 분쟁 해결을 위한 기록 보존, 법령 상 의무 이행을 위한 의사소통 경로 확보</p>
                        </td>
                        <td style={{border: '1px solid black',}}>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>
                            <p style={{fontWeight: 'bold'}}>3. 서비스 관리</p>
                            <p>- 서비스 제공, 이용 내역 확인, 부정 이용 방지 관련 고지/통지, 마케팅 및 품질관리, 서비스의 품질 향상을 위한 개발, 통계 분석</p>
                        </td>
                        <td style={{border: '1px solid black',}}>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h3 style={{marginTop: 10, marginBottom: 10}}>2. 선택적 수집 항목</h3>
                <table style={{border: '1px solid black', width: '100%'}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>구분</th>
                        <th style={{border: '1px solid black', fontWeight: 'bold'}}>항목</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black'}}>
                            <li style={{listStyleType: 'none'}}><strong>1. 회원 가입</strong></li>
                            <li style={{listStyleType: 'none'}}>- 본인 확인 및 인증</li>

                        </td>
                        <td style={{border: '1px solid black',}}>추천인 코드</td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black'}}>
                            <li style={{listStyleType: 'none'}}><strong>2. 내 정보 관리</strong></li>
                            <li style={{listStyleType: 'none'}}>- 정보 수정 및 기입</li>
                        </td>
                        <td style={{border: '1px solid black',}}>마케팅 정보 수신 동의</td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black'}}>
                            <li style={{listStyleType: 'none'}}><strong>3. 상담 이력</strong></li>
                            <li style={{listStyleType: 'none'}}>- 온라인/전화 상담 정보 관리</li>
                        </td>
                        <td style={{border: '1px solid black',}}>채팅/통화 내역, 녹취파일</td>
                    </tr>
                    </tbody>
                </table>
                <h2 className="title">제3조 개인정보의 처리 및 보유기간</h2>
                <p className='mgB8'>회사는 회원이 탈퇴를 요청하거나 개인정보 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용 목적이 달성되거나 보유 기간이 종료될 시 이용자의
                    개인정보를 지체 없이
                    파기합니다.</p>
                <p className='mgB8'>단, 다음의 사유에 해당하는 경우 해당 사유 종료 시까지 보관할 수 있습니다.</p>
                <ol className="top-level">
                    <li>회사 내부방침에 따라 보관해야 하는 항목 및 기간은 다음 각호와 같습니다.</li>
                    <ol style={{paddingLeft: 30}} className="nested">
                        <li>회원가입 시점부터 플랫폼에서 제공하는 서비스 이용과 혜택 부여가 정상 종료된 후 본 조 제2항의 관련 법령에 따라 보관 근거가 해소되는 경우</li>
                        <li>플랫폼 서비스 부정가입 및 부정사용 방지
                            <ul className="nested">
                                <li>보관항목: 이름, 아이디, 사유</li>
                                <li>보관기간: 영구보존</li>
                            </ul>
                        </li>
                        <li>관계법령 위반에 따른 수사 및 조사 등이 진행 중인 경우 해당 사건의 종료 시점까지</li>
                        <li>전화상담에 의한 문의 처리 및 분쟁 해결 등의 기록 관리, 서비스 개선, 감정 노동자 보호
                            <ul className="nested">
                                <li>보관항목: 이름, 휴대전화번호, 상담내용</li>
                                <li>보관기간: 녹취 일로부터 3년</li>
                            </ul>
                        </li>
                    </ol>
                    <li>관련 법령에 따라 보관해야 하는 항목 및 기간은 다음 각호와 같습니다.</li>
                    <ol style={{paddingLeft: 30}} className="nested">
                        <li>전자금융거래에 관한 기록: 3년 (전자금융거래법 시행령 제12조)</li>
                        <li>대금 결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률 시행령 제6조)</li>
                        <li>소비자의 불만 또는 분쟁 처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률 시행령 제6조)</li>
                        <li>플랫폼 방문 기록: 3개월 (통신비밀보호법 시행령 제41조)</li>
                    </ol>
                </ol>
                <h2 className="title">제4조 개인정보의 제3자 제공</h2>
                <ol className="top-level">
                    <li>회사는 원칙적으로 정보주체의 개인정보를 제1조에서 명시한 목적 범위 내에서 처리하며, 정보주체의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지
                        않습니다.
                    </li>
                    <li>단, 다음의 각 호의 경우에는 정보주체 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수
                        있습니다.
                        <ol className="nested">
                            <li>이용자가 사전에 제3자 제공 및 공개에 동의한 경우</li>
                            <li>다른 법률에 특별한 규정이 있는 경우</li>
                            <li>정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한
                                생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
                            </li>
                        </ol>
                    </li>
                </ol>
                <h2 className="title">제5조 개인정보처리의 위탁</h2>
                <p className='mgB8'>회사는 서비스 제공에 있어 필요한 업무 중 일부를 제휴회사로 하여금 수행하도록 개인정보처리를 위탁하고 있습니다.</p>
                <ol className="top-level">
                    <li>개인정보의 처리업무를 위탁한 업체명, 업무내용, 개인정보 보유 및 이용기간은 다음과 같습니다.</li>
                    <table style={{border: '1px solid black', width: '100%'}}>
                        <thead>
                        <tr>
                            <th style={{border: '1px solid black', fontWeight: 'bold'}}>위탁 업체명 (수탁자)</th>
                            <th style={{border: '1px solid black', fontWeight: 'bold'}}>위탁 업무내용</th>
                            <th style={{border: '1px solid black', fontWeight: 'bold'}}>보유 및 이용기간</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{border: '1px solid black',}}>LG U+, (주)채널코퍼레이션</td>
                            <td style={{border: '1px solid black',}}>고객상담, 민원처리, 내역관리</td>
                            <td style={{border: '1px solid black',}}>회원 탈퇴 및 관련 법령이 정한 시점, 위탁 계약 종료 시까지</td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid black',}}>Amazon Web Services Inc. (Amazon Web Services Korea
                                LLC)
                            </td>
                            <td style={{border: '1px solid black',}}>클라우드 IT인프라 제공, 시스템 운영 및 데이터 보관</td>
                            <td style={{border: '1px solid black',}}></td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid black',}}>삼정데이타서비스(주)</td>
                            <td style={{border: '1px solid black',}}>문자메시지(SMS, LMS, MMS) 대량 발송</td>
                            <td style={{border: '1px solid black',}}></td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid black',}}>㈜모두싸인</td>
                            <td style={{border: '1px solid black',}}>전자계약 발송 및 처리</td>
                            <td style={{border: '1px solid black',}}></td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid black',}}>신우회계법인</td>
                            <td style={{border: '1px solid black',}}>소득세, 원천징수 등 조세 관련 업무</td>
                            <td style={{border: '1px solid black',}}></td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid black',}}>비즈톡</td>
                            <td style={{border: '1px solid black',}}>알림톡, 친구톡 대량 발송</td>
                            <td style={{border: '1px solid black',}}></td>
                        </tr>
                        </tbody>
                    </table>
                    <li style={{marginTop: 10}}>회사는 위탁계약 체결 시 개인정보보호법에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 기술적·관리적 보호 조치, 재 위탁
                        제한, 수탁자에 대한 관리·감독,
                        손해배상 등
                        책임에 관한 사항을 계약서 등 문서에 명시하고 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                    </li>
                    <li>위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보처리방침을 통하여 공개하도록 하겠습니다.</li>
                </ol>
                <h2 className="title">제6조 가명정보의 처리 및 위탁, 제3자 제공</h2>
                <ol className="top-level">
                    <li>회사는 개인정보보호법에 근거하여 개인정보의 처리목적 범위 내에서 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명 처리하여 통계작성, 과학적 연구, 공익적 기록보전 등을
                        위하여 정보주체의 동의 없이 가명정보를 생성하여 이용 또는 제공할 수 있습니다.
                    </li>
                    <li>가명정보란 개인정보를 가명 처리함으로써 원래의 상태로 복원하기 위한 추가정보의 사용∙결합 없이는 특정 개인을 알아볼 수 없는 정보를 말합니다.</li>
                    <li>가명처리란 개인정보의 일부를 삭제하거나 일부 또는 전체를 대체하는 등의 방법으로 추가정보 없이는 특정 개인을 알아볼 수 없도록 처리하는 것을 말합니다.</li>
                    <li>회사는 가명정보를 제3자에게 제공하는 경우, 업무 처리 목적에 필요한 최소한의 가명정보를 제공합니다.</li>
                    <li>이 경우 가명정보 재 식별 금지, 가명정보 및 추가정보의 분리 보관 등 안전성 확보를 위한 준수사항이 반영된 계약서를 사용하여 계약을 체결하고 있습니다.
                        <ol className="nested">
                            <li>이용현황: 없음</li>
                            <li>제3자 제공현황: 없음</li>
                        </ol>
                    </li>
                    <li>회사는 가명정보가 재식별 되지 않도록 개인정보와 분리하여 별도 저장 및 관리하고 필요한 기술적관리적·물리적 보호조치를 취합니다.</li>
                </ol>

                <h2 className="title">제7조 개인정보의 파기절차 및 방법</h2>
                <ol className="top-level">
                    <li>회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때는 바로 해당 개인정보를 파기합니다.</li>
                    <li>정보 주체로부터 동의받은 개인정보의 보유기간이 지나거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우(제3조 개인정보의 처리 및
                        보유기간 참조), 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
                    </li>
                    <li>파기절차 및 방법은 다음과 같습니다.
                        <ol className="nested">
                            <li>파기절차: 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보보호책임자의 승인을 받아 개인정보를 파기</li>
                            <li>파기방법: 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 파쇄기로 파쇄 또는 소각하여
                                파기
                            </li>
                        </ol>
                    </li>
                    <li>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</li>
                    <li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                    <li>회사는 정보주체 권리에 따른 열람의 요구, 정정삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다</li>
                </ol>

                <h2 className="title">제8조 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한
                    사항</h2>
                <ol className="top-level">
                    <li>회사는 개인화되고 맞춤화 된 서비스를 제공하기 위하여 이용자의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다.</li>
                    <li>쿠키는 플랫폼 운영에 이용되는 서버가 이용자의 브라우저에서 보내는 아주 작은 텍스트 파일로 이용자가 이용하는 단말기(PC/스마트폰/태블릿PC 등)의 하드디스크에 저장됩니다.
                        이후 이용자가 플랫폼에 방문할 경우 플랫폼 서버는 이용자 단말기의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화 된 서비스를 제공하기
                        위해 이용됩니다.
                    </li>
                    <li>쿠키는 개인을 식별하는 정보를 자동적, 능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.</li>
                    <li>회사의 쿠키 사용 목적은 이용자들이 방문한 회사 플랫폼의 각 서비스 방문 및 이용 형태, 인기 검색어, 보안 접속 여부, 이용자 규모 등을 파악하여 이용자에게 광고를 포함한
                        최적화된 맞춤형 정보를 제공하기 위해 사용합니다.
                    </li>
                    <li>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                        아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
                    </li>
                </ol>

                <h2 className="title">제9조 개인정보의 안전성 확보 조치</h2>
                <p>회사는 개인정보의 안정성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.</p>

                <table style={{border: '1px solid black', width: '100%'}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black',}}>구분</th>
                        <th style={{border: '1px solid black',}}>내용</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>관리적 조치</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>내부관리계획 수립·시행, 전담 조직 운영, 직원 대상 정기적 정보보호 교육, 개인정보 취급자 단말기 보안점검 실시 등</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>기술적 조치</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>개인정보 처리 시스템 접근 통제, 접근 권한 관리, 개인정보의 암호화, 보안 프로그램 설치 및 운영 등</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>물리적 조치</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>전산실 및 자료 보관실의 접근 통제, 영상 정보 처리기기 운영 및 관리 등</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h2 className="title">제10조 정보주체의 권리∙의무 및 행사방법</h2>
                <ol className="top-level">
                    <li>이용자는 정보주체로써 언제든지 자신의 개인정보를 열람, 정정, 삭제, 처리정지를 회사에 요청할 수 있습니다.</li>
                    <li>본 조 제1항에 따른 권리행사는 ‘개인정보 (열람,정정 · 삭제,처리정지) 요구서’를 작성하여 서면 또는 이메일, 고객센터로 접수할 수 있습니다.
                        <ol className="nested">
                            <li>고객센터 : 02-583-9442</li>
                            <li>이메일 : <a href="mailto:twave@twave.co.kr">twave@twave.co.kr</a></li>
                            <li><a
                                href="https://imin-app-bucket-real.s3.ap-northeast-2.amazonaws.com/terms/PI/6/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4(%EC%97%B4%EB%9E%8C%2C+%EC%A0%95%EC%A0%95%E3%86%8D%EC%82%AD%EC%A0%9C%C2%B8+%EC%B2%98%EB%A6%AC%EC%A0%95%EC%A7%80)+%EC%9A%94%EA%B5%AC%EC%84%9C.pdf"
                                target="_blank" rel="noopener noreferrer">개인정보 (열람, 정정·삭제, 처리정지) 요구서 다운로드:
                                <strong style={{color: 'blue'}}>PDF</strong>
                            </a>,
                                <a href="https://imin-app-bucket-real.s3.ap-northeast-2.amazonaws.com/terms/PI/6/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4(%EC%97%B4%EB%9E%8C%2C+%EC%A0%95%EC%A0%95%E3%86%8D%EC%82%AD%EC%A0%9C%C2%B8+%EC%B2%98%EB%A6%AC%EC%A0%95%EC%A7%80)+%EC%9A%94%EA%B5%AC%EC%84%9C.hwp"
                                   target="_blank" rel="noopener noreferrer">
                                    <strong style={{color: 'blue'}}>HWP</strong>
                                </a>
                            </li>
                        </ol>
                    </li>
                    <li>본 조 제1항에 따른 권리 행사는 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있습니다.<br/>
                        대리인 자격으로 요구하는 경우, ‘개인정보 처리 방법에 관한 고시 별지
                        11호’ 서식에 따른 위임장을 제출해야 합니다.
                        <ol className="nested">
                            <li><a
                                href="https://imin-app-bucket-real.s3.ap-northeast-2.amazonaws.com/terms/PI/6/%5B%EB%B3%84%EC%A7%80+11%5D+%EC%9C%84%EC%9E%84%EC%9E%A5(%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4+%EC%B2%98%EB%A6%AC+%EB%B0%A9%EB%B2%95%EC%97%90+%EA%B4%80%ED%95%9C+%EA%B3%A0%EC%8B%9C).pdf"
                                target="_blank">개인정보 처리 방법에 관한 고시 별지 11호 다운로드:
                                <strong style={{color: 'blue'}}>PDF</strong>
                            </a>,
                                <a href="https://imin-app-bucket-real.s3.ap-northeast-2.amazonaws.com/terms/PI/6/%5B%EB%B3%84%EC%A7%80+11%5D+%EC%9C%84%EC%9E%84%EC%9E%A5(%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4+%EC%B2%98%EB%A6%AC+%EB%B0%A9%EB%B2%95%EC%97%90+%EA%B4%80%ED%95%9C+%EA%B3%A0%EC%8B%9C).hwp">
                                    <strong style={{color: 'blue'}}>HWP</strong>
                                </a>
                            </li>
                        </ol>
                    </li>
                    <li>회사는 정보주체의 권리요구를 한 자가 본인 또는 정당한 대리인 인지 다음 각 호의 조건으로 확인합니다.
                        <ol className="nested">
                            <li>정보주체(본인)인 경우: 주민등록증, 운전면허증 등 신분확인 증명서</li>
                            <li>대리인인 경우: 위임장, 위임자(정보주체), 대리인의 주민등록증 등 신분확인 증명서</li>
                        </ol>
                    </li>
                    <li>또한 회사의 플랫폼 서비스 내 설정을 통한 회원정보수정 및 회원탈퇴 신청을 할 수 있습니다.</li>
                    <li>개인정보의 오류 등의 대한 정정, 삭제를 요청한 경우에는 정정, 삭제를 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.</li>
                    <li>회사는 정보주체의 요구를 연기하거나 거부하는 경우에는 정당한 사유가 없는 한 정보주체의 요구가 있는 날로부터 10일 이내에 연기또는 거절의 사유 및 이의제기 방법을
                        정보주체에게 알려 드립니다.
                    </li>
                </ol>
                <h2 className="title">제11조 개인정보 보호책임자 및 관리담당자</h2>
                <p>회사는 수집된 회원의 개인정보 관리를 위해 개인정보 보호책임자 및 관리담당자를 다음의 각 호와 같이 지정하여 관리하고 있으며, 회원은 모든 개인정보보호 관련 민원을 개인정보
                    보호책임자 및 관리담당자에게 신고할 수 있습니다.</p>

                <table style={{border: '1px solid black', width: '100%'}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid black',}}>구분</th>
                        <th style={{border: '1px solid black',}}>내용</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{border: '1px solid black',}}>개인정보 보호책임자</td>
                        <td style={{border: '1px solid black',}}>
                            <ul>
                                <li>이름 : 서재준</li>
                                <li>직책 : 대표이사</li>
                                <li>전화 : 02-583-9442</li>
                                <li>FAX : 02-581-9444</li>
                                <li>이메일 : <a href="mailto:twave@twave.co.kr">twave@twave.co.kr</a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: '1px solid black',}}>개인정보 관리담당자</td>
                        <td style={{border: '1px solid black',}}>
                            {/* 개인정보 관리담당자 정보 */}
                            {
                                selectedDate === '2024.11.12' ? (
                                    <ul>
                                        <li>이름 : 승진엽</li>
                                        <li>직책 : 프로</li>
                                        <li>전화 : 02-583-9442</li>
                                        <li>FAX : 02-581-9444</li>
                                        <li>이메일 : <a href="mailto:twave@twave.co.kr">twave@twave.co.kr</a></li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>이름 : 한상훈</li>
                                        <li>직책 : TL</li>
                                        <li>전화 : 02-583-9442</li>
                                        <li>FAX : 02-581-9444</li>
                                        <li>이메일 : <a href="mailto:test@twave.co.kr">test@twave.co.kr</a></li>
                                    </ul>
                                )
                            }

                        </td>
                    </tr>
                    </tbody>
                </table>

                <h2 className="title">제12조 정보 주체의 권익침해 구제방법</h2>
                <p className='mgB8'>회사는 이용자의 개인정보 유출 및 권리 침해 시, 그 피해구제와 권익을 보장하기 위하여 최선을 다하고 있습니다.<br/> 이용자는 회사의 개인정보
                    불만처리 및 피해구제 결과에 만족하지 않는
                    경우 또는 자세한 도움이 필요한 경우에 아래의 기관에 문의할 수 있습니다.</p>
                <ol className="top-level">
                    <li>개인정보 분쟁 조정위원회: (국번없이) 1833-6972 (<a href="http://www.kopico.go.kr" target="_blank"
                                                            rel="noopener noreferrer">kopico.go.kr</a>)
                    </li>
                    <li>개인정보침해 신고센터: (국번없이) 118 (<a href="http://privacy.kisa.or.kr" target="_blank"
                                                    rel="noopener noreferrer">kisa.or.kr</a>)
                    </li>
                    <li>대검찰청 사이버수사과: (국번없이) 1301 (<a href="http://www.spo.go.kr" target="_blank"
                                                     rel="noopener noreferrer">spo.go.kr</a>)
                    </li>
                    <li>경찰청 사이버수사국: (국번없이) 182 (<a href="http://ecrm.cyber.go.kr" target="_blank"
                                                   rel="noopener noreferrer">ecrm.cyber.go.kr</a>)
                    </li>
                </ol>

                <h2 className="title">제13조 개인정보처리방침의 변경</h2>
                <p>이 방침은 시행 일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는 시행일의 최소 7일 전부터 홈페이지를 통하여 고지합니다.</p>

                <h2 className="title">- 부칙 -</h2>
                <p className='mgB8'>
                    공고일자: {selectedDate === '2024.11.12' ? '2024년 11월 05일' : '2024년 7월 1일'}
                </p>
                <p>
                    시행일자: {selectedDate === '2024.11.12' ? '2024년 11월 12일' : '2024년 7월 1일'}
                </p>

            </section>
        </>
    )
}

export default Private;
