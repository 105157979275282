import React, { useEffect, useState } from "react";
import { api } from 'api/api';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import loginInfoState from 'store/State';
import Laypop from "components/Laypop";
import * as CommonFunc from 'common/js/func';

import ClearInput from "components/InputClear";
import InputPw from "components/InputPw";

import 'common/css/myinfo/myinfo.css'
const Myinfo = () => {
    const loginInfoValue = useRecoilValue(loginInfoState); // 로그인 정보 불러오기
    const {token} = loginInfoValue;
    const decodeInfoValue = CommonFunc.decodedToken(token);
    const { pnidx } = decodeInfoValue;

    // console.log(pnidx);
    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    // 알림 팝업
    const [popOpen, setPopOpen] = useState(false);
    const [popData, setPopData] = useState(null);

    const closePop = () => {
        setPopData(null);
        setPopOpen(false);

    };

    const openPop = (data) => {
        setPopData(data);
        setPopOpen(true);
        // movePage('/');
    };

    /**************************************************** */
    //계좌번호
    const [codeData, setCodeData] = useState([]);
    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        codeList();
    }, [])
    const codeList = async () => {
        const req = {
            params: { pt_idx: 1 }
        };
        const res = await api.get(`/api/v1/myinfo/codeSelList`, req);
        // console.log(res);
        if (res.status === 200) {
            setCodeData(res.data);
            // console.log(res.data.length);
        }

    }
    //enabled 속성을 줘서 처음에만 실행 (기본정보)
    const [isSearch, setIsSearch] = useState(true);
    useQuery(['userSelView', isSearch],
        async () => {
            const req = {
                params: { pn_idx: pnidx }
            };
            const res = await api.get(`/api/v1/myinfo/${pnidx}`, req);
            // console.log(res);
            if (res.status === 200) {
                setUser(res.data);
            }
            setIsSearch(false);
        },
        {
            enabled: isSearch, // 마운트될 때만 실행
        });

    /**************************************************** */
    const [user, setUser] = useState({
        pn_idx: pnidx,
        id: '',
        u_nm: '',
        mobile: '',
        b_cg: '',
        b_nm: '',
        b_num: '',
        rc_email: '0',
        rc_sms: '0',
    });

    const [userPw, setUserPw] = useState({
        now_pw: '',
        pw: '',
        pw2: '',
    });

    const [pwOk, setPwOk] = useState(true); // 비밀번호 전체 체크
    const [isPw, setIsPw] = useState(true); // 비밀번호 유무 체크
    const [pwMatch, setPwMatch] = useState(true); // 패스워드 일치 확인
    const [pwCondition, setPwCondition] = useState(true); // 패스워드 조건
    const [pwFail, setPwFail] = useState(true); // 모든필드 입력확인

    // 휴대폰 - 추가
    const formatPhone = (pVal) => {
        return pVal.slice(0, 3) + '-' + pVal.slice(3, 7) + '-' + pVal.slice(7);
    }

    // user 입력처리
    const userField = (fieldName) => (e) => {
        let inputValue = e.target.value;

        // 계좌번호
        if (fieldName === 'b_num') {
            inputValue = CommonFunc.NonNumeric(inputValue);
        }

        // console.log(inputValue);
        setUser((preve) => {
            return {
                ...preve,
                [fieldName]: inputValue,
            };
        });
    };

    // pw 입력처리
    const pwField = (fieldName) => (e) => {
        const inputValue = e.target.value;

        // console.log(fieldName);
        setUserPw((preve) => {
            return {
                ...preve,
                [fieldName]: inputValue,
            };
        });
        if (fieldName === 'pw') { // 패스워드 일치, 조건 확인
            const pw2 = userPw.pw2;

            // 새비밀번호가 빈값일 경우 다른 필드도 현재 비밀번호도 빈값이여야함 (재입력은 따로 체크함)
            if (inputValue === '') {
                setPwFail(userPw.now_pw === '' && pw2 === '');
            } else {
                setPwFail(userPw.now_pw !== '' && pw2 !== '');
            }

            // 조건확인
            setPwCondition(CommonFunc.isPwTest(inputValue));
            // 비밀번호 일치확인
            if (pw2 === '') {
                setPwMatch(true);
            } else {
                setPwMatch(pw2 === inputValue);
            }

        } else if (fieldName === 'pw2') {
            const pw = userPw.pw;

            if (inputValue === '') {
                setPwFail(userPw.now_pw === '' && pw === '');
            } else {
                setPwFail(userPw.now_pw !== '' && pw !== '');
            }

            // 비밀번호 일치확인
            if (inputValue === '') {
                setPwMatch(true);
            } else {
                setPwMatch(pw === inputValue);
            }
        }

        if (fieldName === 'now_pw') {
            // 현재비밀번호가 빈값일 경우 새비밀번호도 빈값이여야 함
            if (inputValue === '') {
                setPwFail(userPw.pw === '');
            } else {
                setPwFail(userPw.pw !== '')
            }
            // 현재 비밀번호 유무 체크
            pwUniqueCheck(inputValue);
        }
    };


    // 비밀번호 조건 체크
    const pwCheck = () => {
        let isValid = pwCondition && pwMatch && isPw && pwFail;
        // console.log(pwOk + ' // 비밀번호 전체 체크')
        // console.log(isPw + ' // 비밀번호 유무 체크')
        // console.log(pwMatch + ' // 패스워드 일치 확인')
        // console.log(pwCondition + ' // 패스워드 조건')
        // console.log(pwFail + ' // 모든필드 입력확인')
        setPwOk(isValid);
    }

    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        pwCheck();
    }, [pwMatch, pwCondition, isPw, pwFail])

    // 비밀번호 유무체크
    const pwUniqueCheck = async (pVal) => {
        if (pVal === '') {
            setIsPw(true);
            pwCheck();
            return false;
        }
        // console.log(pVal);
        const req = {
            params: { pwd: pVal, pn_idx: pnidx }
        };

        const res = await api.get(`/api/v1/myinfo/${pnidx}/isPwUnique`, req);
        // const res = await api.get(`/api/v1/myinfo/${pnidx}/isPwUnique?pw=${encodeURIComponent(pVal)}`, null);
        // console.log(res);
        if (res.status === 999) {
            setIsPw(false);
            setIsPw(false);
        } else {
            setIsPw(true);
            // console.log('userId 길이값=> ' + certiBefore.userId.length);
        }
    }
    /************************************************** */
    const [agreAll, setAgreAll] = useState(false) // 약관전체 동의
    const [agreSms, setAgreSms] = useState(false) // 서비스 약관
    const [agreEmail, setAgreEmail] = useState(false)// 개인정보 동의

    const [certification, setCertification] = useState(false); // 저장 버튼 활성화

    // 체크박스 이미지
    const checkImg = (check) => {
        return check ? '/images/btn/checked.svg' : '/images/btn/unchecked.svg'
    };

    //체크박스
    const checkBoxClick = (pNum) => {
        if (pNum === 1) {
            setAgreAll(!agreAll);
            setAgreSms(!agreAll);
            setAgreEmail(!agreAll);
        } else if (pNum === 2) {
            setAgreSms(!agreSms);
        } else {
            setAgreEmail(!agreEmail);
        }
    };

    // 체크박스 이벤트 처리
    useEffect(() => {
        if (agreSms || agreEmail) {
            setAgreAll(true);
        } else {
            setAgreAll(false);
        }
        // console.log(agreEmail);
    }, [agreSms, agreEmail])

    useEffect(() => {
        user.rc_sms === '1' ? setAgreSms(true) : setAgreSms(false);
        user.rc_email === '1' ? setAgreEmail(true) : setAgreEmail(false);
        certifiActiv();
    }, [user])

    // 모든 조건 만족시 저장버튼 활성화
    const certifiActiv = () => {
        const isValid = user.id !== '' && user.mobile !== '' && user.b_cg !== ''
            && pwOk;
        // console.log(isValid);
        setCertification(isValid);
    }

    useEffect(() => { // 조건들 만족시 체크 이벤트 실행
        certifiActiv();
    }, [pwOk])


    //업데이트 수정
    const userUpdate = useMutation(async () => {
        // if(!certification){
        //     return false;
        // }
        // 비밀번호 체크
        if (!pwOk) {
            openPop('비밀번호를 확인해 주세요.');
            return false;
        }
        // 빈값 체크
        if (!CommonFunc.isValidation('String', user.b_num)) {
            openPop('계좌번호를 입력해 주세요.');
            return false;
        }

        const requestData = {
            up_tp: 1,
            pn_idx: pnidx,
            mobile: user.mobile,
            b_cg: user.b_cg,
            b_nm: parseInt(user.b_nm),
            b_num: user.b_num,
            rc_email: agreEmail ? '1' : '0',
            rc_sms: agreSms ? '1' : '0',
            pwd: userPw.pw
        };
        // console.log(requestData);
        const res = await api.patch(`/api/v1/myinfo/${pnidx}/userUpdate`, requestData);
        // const res = '';
        // console.log(res);
        if (res.status === 200) {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
            openPop('내 정보가 수정되었습니다.');
            userPw.now_pw = '';
            userPw.pw = '';
            userPw.pw2 = '';
        }
    })

    /************************************************** */
    //본인인증
    // 본인인증 체크
    const verification = () => {
        nice.mutate(); // 유즈쿼리 실행
    }

    // 본인인증 nice

    const [authRes, setAuthRes] = useState([]);
    const [seqs, setSeqs] = useState('');

    const nice = useMutation(async () => {
        const res = await api.get(`/callApi/main`, null);
        // console.log(res);
        if (res.result_cd === 0) {
            // console.log(res);
            // console.log(res.req_seq);
            setSeqs(res.req_seq);
            openCheckPlusPopup(res.sEncData, res.sMessage);
        } else {
            // setSMessage(res.sMessage);
            console.log(res.sMessage);
            // 에러 처리
        }
    }
    );

    // 본인인증 팝업 띄우기
    const openCheckPlusPopup = (data, message) => {
        // console.log(data);
        // Open the CheckPlus popup
        const popupWindow = window.open(
            '',
            'popupChk',
            'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        );

        // Set up a form dynamically
        const form = document.createElement('form');
        form.name = 'form_chk';
        form.method = 'post';
        form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        form.target = 'popupChk';

        // Add input fields to the form
        const inputM = document.createElement('input');
        inputM.type = 'hidden';
        inputM.name = 'm';
        inputM.value = 'checkplusService';

        const inputEncodeData = document.createElement('input');
        inputEncodeData.type = 'hidden';
        inputEncodeData.name = 'EncodeData';
        inputEncodeData.value = data;
        inputEncodeData.id = 'test123';

        form.appendChild(inputM);
        form.appendChild(inputEncodeData);

        // Append the form to the body and submit it
        document.body.appendChild(form);
        // console.log(form);
        form.submit();
        document.body.removeChild(form);
        // window.self.close();
    };

    //본인 인증 창에서 메세지 전달 시 실행
    useEffect(() => {
        // 메시지 이벤트 리스너 등록
        const handleMessage = (event) => {
            // 여기서 이벤트를 확인하고 데이터를 처리합니다.
            const pVal = event.data.data;
            const pType = event.data.type;
            if (pType === 'popupData') {
                const req = {
                    params: { seq: seqs, encData: pVal }
                };
                console.log(req);
                authSuccesc(req);
            }
        };

        window.addEventListener('message', handleMessage);

        // 컴포넌트가 언마운트될 때 리스너 해제
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [seqs]);

    // 본인인증 성공시
    const authSuccesc = async (reqData) => {
        // refetch 메서드 호출 시, 값을 전달
        // console.log(reqData);
        const res = await api.get(`/callApi/success`, reqData);
        console.log(res);
        //  console.log(res.dto);
        if (res.result_cd === 0) {
            const nm = res.dto.name;
            const birth = res.dto.birth;

            // 회원정보 이름,생년월일과 인증정보가 일치하다면
            if (nm === user.u_nm && birth === user.birth) {
                setUser(prevUser => ({
                    ...prevUser,
                    // b_cg: res.dto.name,
                    mobile: res.dto.phone,
                }));
                openPop('휴대폰 번호가 변경되었습니다.');
            } else {
                openPop('인증 시도한 본인인증 정보와<br />아이디에 등록된 본인인증 정보가<br />일치하지 않습니다.');
            }

        }
    };

    // 포커스 상태관리
    const [isFocus, setIsFocus] = useState({
        now_pw: false,
        pw: false,
        pw2: false,
        b_num : false
    });

    const focusChange = (inputName, isFocused) => {
        setIsFocus(prevState => ({
            ...prevState,
            [inputName]: isFocused
        }));
    };

    return (
        <section className="subCont myinfo" id='myinfo'>
            {/* <!-- 컨텐츠 --> */}
            <div className="mobileBox">
                <h4>내 정보 관리</h4>
                <div>
                    <ul>
                        <li className="inputBox">
                            <ul>
                                {/* <!-- ****(유효성 체크 외 하단 문장이 없었다면) input 유효성 검사 후 아래 멘트 출력 시 li에 .ibsInfo클래스 추가 ****  --> */}
                                <li className="ibsInfo">
                                    <p>이메일(아이디)</p>
                                    <input type="text" disabled
                                        value={user.id} onChange={userField('id')} />
                                </li>
                                <li className="ibsInfo">
                                    <p>이름</p>
                                    <input type="text" disabled
                                        value={user.u_nm} onChange={userField('u_nm')} />
                                </li>
                                <li className={'ibsInfo' + (userPw.now_pw === '' && !isFocus.now_pw ? '' : ' labelInput')}>
                                    <InputPw
                                        value={userPw.now_pw}
                                        onChange={pwField('now_pw')}
                                        labelNm={'현재 비밀번호'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('now_pw', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed" style={{ display: userPw.now_pw !== '' && !isPw ? 'block' : 'none' }}>기존 비밀번호가 일치하지 않습니다.</span>
                                </li>
                                <li className={'ibsInfo' + (userPw.pw === '' && !isFocus.pw ? '' : ' labelInput')}>
                                    <InputPw
                                        value={userPw.pw}
                                        onChange={pwField('pw')}
                                        labelNm={'새 비밀번호'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('pw', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed" style={{ display: pwCondition ? 'none' : 'block' }}>숫자, 영문, 특수문자 중 2가지 이상 조합하여 8~20자로 입력해 주세요</span>
                                </li>
                                <li className={'ibsInfo' + (userPw.pw2 === '' && !isFocus.pw2 ? '' : ' labelInput')}>
                                    <InputPw
                                        value={userPw.pw2}
                                        onChange={pwField('pw2')}
                                        labelNm={'새 비밀번호 재입력'}
                                        maxLength={20}
                                        onFocusChange={(isFocused) => focusChange('pw2', isFocused)} // 포커스 상태 전달
                                    />
                                    <span className="colRed" style={{ display: pwMatch ? 'none' : 'block' }}>비밀번호가 일치하지 않습니다.</span>
                                </li>
                                {/* <li className="ibsInfo">
                                    <input type="password" placeholder="현재 비밀번호" value={userPw.now_pw} onChange={pwField('now_pw')} />
                                    <span className="colRed" style={{ display: userPw.now_pw !== '' && !isPw ? 'block' : 'none' }}>기존 비밀번호가 일치하지 않습니다.</span>
                            </li>
                            <li className="ibsInfo">
                                <input type="password" placeholder="새 비밀번호" value={userPw.pw} onChange={pwField('pw')} maxLength="20"/>
                                <span className="colRed" style={{ display: pwCondition ? 'none' : 'block' }}>숫자, 영문, 특수문자 중 2가지 이상 조합하여 8~20자로 입력해 주세요</span>
                            </li>

                            <li className="ibsInfo">
                                <input type="password" placeholder="새 비밀번호 재입력" value={userPw.pw2} onChange={pwField('pw2')} maxLength="20"/>
                                <span className="colRed" style={{ display: pwMatch ? 'none' : 'block' }}>비밀번호가 일치하지 않습니다.</span>
                            </li>
                            */}
                            </ul>
                        </li>
                        <li className="inputBox">
                            <p className="middleTitle">개인 정보</p>
                            <ul>
                                <li className="ibsInfo">
                                    <p>휴대폰 번호</p>
                                    <div className="phoneNum">
                                        <input type="text" placeholder="휴대폰 번호" disabled
                                            value={formatPhone(user.mobile)} onChange={userField('mobile')} />
                                        <a href="javascript:;" className="btn btnS btnPointLine" onClick={() => verification()}>변경하기</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="inputBox">
                            <p className="middleTitle">계좌 정보</p>
                            <ul>
                                <li>
                                    <p>예금주</p>
                                    <input type="text" placeholder="예금주 - 아임인" disabled
                                        value={user.b_cg} onChange={userField('b_cg')} />
                                </li>
                                <li>
                                    <p>은행</p>
                                    <select value={user.b_nm} onChange={userField('b_nm')} >
                                        <option value="">은행 선택</option>
                                        {
                                            Array.isArray(codeData) && (
                                                codeData.length === 0 ? (
                                                    <></>
                                                ) : (
                                                    codeData.map((code, index) => (
                                                        <option key={index} value={code.cd_idx}>{code.cd_nm}</option>
                                                    )))
                                            )
                                        }
                                    </select>
                                </li>
                                <li className="ibsInfo">
                                    <div className={'ibsInfo' + (user.b_num === '' && !isFocus.b_num ? '' : ' labelInput')}>
                                        <ClearInput
                                            value={CommonFunc.NonNumeric(user.b_num)}
                                            onChange={userField('b_num')}
                                            onClear={() => setUser({ ...user, b_num: '' })}
                                            labelNm={'계좌번호 입력'}
                                            maxLength={16}
                                            onFocusChange={(isFocused) => focusChange('b_num', isFocused)} // 포커스 상태 전달
                                            // onFocusChange={(isFocused) => setIsFocus({ ...isFocus, b_num: isFocused })} // 포커스 상태 전달
                                        />
                                        <span>"-"없이 입력해주세요.</span>
                                    </div>
                                    {/* <input type="text" placeholder="계좌번호 입력" maxLength="16"
                                        value={CommonFunc.NonNumeric(user.b_num)} onChange={userField('b_num')} />
                                    <span>"-"없이 입력해주세요.</span> */}
                                </li>
                                <li className="infoText">
                                    <p>※ 계좌정보 변경 시 꼭 확인해 주세요</p>
                                    <ul>
                                        {/* <li>본인 인증한 이름과 예금주가 다른 경우 지급이 보류됩니다.</li> */}
                                        <li>반드시 본인 명의의 계좌를 입력해 주세요.</li>
                                        <li>잘못된 계좌 또는 타인 명의의 계좌로는 수익금 지급이 되지 않습니다.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className="middleTitle">마케팅 정보 수신 동의(선택)</p>
                            {/* <!-- 이용동의 --> */}
                            <ul className="consent">
                                {/* <li className="agreeAll imageCheckbox">
                                <div className="termsBox">
                                    <img src={checkImg(agreAll)} alt="약관 전체 동의 해제됨" className="checkbox" onClick={() => checkBoxClick(1)} />
                                    <span data-v-de1df31c="">아임인 파트너스 정보 수신</span>
                                </div>
                            </li> */}
                                <li className="agree">
                                    <div className="termsBox">
                                        <div className="imageCheckbox">
                                            <img src={checkImg(agreSms)} alt="SMS 체크박스 해제됨" className="checkbox" onClick={() => checkBoxClick(2)} />
                                            <p data-v-de1df31c="">문자 메시지</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="agree">
                                    <div className="termsBox">
                                        <div className="imageCheckbox">
                                            <img src={checkImg(agreEmail)} alt="이메일 동의 체크박스 해제됨" className="checkbox" onClick={() => checkBoxClick(3)} />
                                            <p>이메일</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <p className="postscript">마케팅 수신 동의를 하시면 다양한 아임인 파트너스 정보와 혜택을 놓치지 않고 받을 수 있어요.</p>
                        </li>
                    </ul>
                    {/* <!-- 활성화 시 btnGray > btnPoint로 변경 --> */}
                    {/* <a href="javascript:;" className={'btn btn100 ' + (certification ? 'btnPoint' : 'btnGray')} style={{ cursor: certification ? 'pointer' : 'auto' }} onClick={()=> userUpdate.mutate()}>저장</a> */}
                    <a className={'btn btn100 ' + (certification ? 'btnPoint' : 'btnPoint')} style={{ cursor: certification ? 'pointer' : 'pointer' }} onClick={() => userUpdate.mutate()}>저장</a>
                    <a className="withdrawalBtn" onClick={() => pageMoveA('/myinfo/Withdrawal')}>회원 탈퇴</a>
                </div>
            </div>
            {/* <!-- 알림 팝업 --> */}
            {popOpen && (
                <Laypop data={popData} closePop={closePop} />
            )}
        </section>
    )
}

export default Myinfo;
