import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import Pagination from 'components/Pagination';
import BoardListItem from 'components/BoardListItem';
import 'common/css/notice/notice.css';

const Notice = () => {

    /****************************************************/
    const [totalCnt, setTotalCnt] = useState(0);
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호

    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    /****************************************************/
    
    // 페이징 url
    const changePages = (page) => {
        setPageNum(page);
        movePage(`/notice/Notice?page=${page}`);
    }
    
    // 페이지 이동
    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }

    // 탭, 페이지 변경, 뒤로가기 이벤트
    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
    }, [paraGetPage])

    /************************
     * 공지사항 리스트
     ************************/
    const [noticeData, setNoticeData] = useState([]);
    useQuery(['boardList', pageNum], async () => {
        const requestData = {
            params: { boardTp: "N", showTp: 1, page: pageNum - 1, size: pageRowCnt }
        };

        const boardList = await api.get(`/api/v1/boardList`, requestData);

        if (boardList.status === 200) {
            setNoticeData(boardList.data.content);
            setTotalCnt(boardList.data.totalElements);
        }
    });

    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/notice/Notice')}>공지사항</span>
                </div>
            </div>
            <section className="subCont notice" id="notice">
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>공지사항</h4>
                    <p>아임인 파트너스 공지사항 입니다.</p>
                </div>
                <div className="totalBox"><p>총 등록된 글 <b>{totalCnt}</b>개</p></div>
                <div className="noticeCont">
                    <ul>
                        {
                            Array.isArray(noticeData) && (
                                totalCnt === 0 ? (
                                    <p>검색된 내용이 없습니다.</p>
                                ) : (
                                    noticeData.map((notice, index) => (
                                        <BoardListItem
                                            key={notice.boardIdx}
                                            item={notice}
                                            index={index}
                                            totalCnt={totalCnt}
                                            pageNum={pageNum}
                                            pageRowCnt={pageRowCnt}
                                            pageMoveA={pageMoveA}
                                            isAsk={false}
                                        />
                                    ))))
                        }
                    </ul>
                </div>
                {totalCnt > 0 && (
                    <div className="pagingDiv">
                        <Pagination
                            totalPage={totalPage === 0 ? 1 : totalPage}
                            limit={pageSize}
                            page={pageNum}
                            setPage={changePages}
                        />
                    </div>
                )}
            </section>
        </>
    )
}

export default Notice;
