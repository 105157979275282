import React, { useEffect, useCallback } from "react";
import { useQuery} from 'react-query';
import { api } from 'api/api';

const Auth = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const EncodeData = urlParams.get('EncodeData');
    // console.log("본인인증 성공 후 auth페이지 진입");
    // console.log("부모 창에서 받은 값 urlParams:", urlParams);
    // console.log("부모 창에서 받은 값 EncodeData:", EncodeData);
    const sendToParent = () => {
        //   console.log(EncodeData);
        window.opener.postMessage({ type: 'popupData', data: EncodeData }, '*');
      };

    const handleLoad = useCallback(() => {
        // ...기타 필요 작업
        sendToParent();
        // window.opener.document.reaload();
        window.self.close();
    }, []);

    useEffect(() => {
    //     // if (!window.opener) return route.back(); // 보통 페이지와 다를 것 없기 때문에 비정상적인 방법으로 진입 시 뒤로가기

        handleLoad(); // 정상적인 루트로 진입한 경우 실행된다. 
    }, []);

    return (
        <>
            <div style={{ zIndex: '999', paddingTop: '100px' }}>{EncodeData}</div>
        </>
    )
}

export default Auth;
