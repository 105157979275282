import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from 'api/api';
import loginInfoState from 'store/State';
import Pagination from 'components/Pagination';
import BoardListItem from 'components/BoardListItem';
import 'common/css/usage/ask.css'
import { useRecoilValue } from 'recoil';
import {decodedToken} from 'common/js/func';

const Ask = () => {

    // 로그인 정보 불러오기
    const loginInfoValue = useRecoilValue(loginInfoState);
    const { token} = loginInfoValue;
    const decodeInfoValue = decodedToken(token);
    const { pnidx} = decodeInfoValue;
    // const pnIdx = loginInfoValue.pnidx;
    // const uNm  = loginInfoValue.uNm;

    /****************************************************/
    const [totalCnt, setTotalCnt] = useState(0);
    const pageRowCnt = 10; // 페이지에 표시할 행 개수
    const pageSize = 5; // 페이지에 표시할 페이지 개수
    const [totalPage, setTotalPage] = useState(totalCnt / pageRowCnt); // 총 페이지 개수
    const [pageNum, setPageNum] = useState(1); // 현재 페이지 번호

    const urlParams = new URLSearchParams(window.location.search);
    const paraGetPage = parseInt(urlParams.get("page"));
    /****************************************************/

    // 페이지 이동
    const changePages = (page) => {
        setPageNum(page);
        movePage(`/usage/Ask?page=${page}`);
    }

    const movePage = useNavigate();
    const pageMoveA = (page) => {
        movePage(page);
    }


    // 탭, 페이지 변경, 뒤로가기 이벤트
    useEffect(() => {
        !paraGetPage ? setPageNum(1) : setPageNum(paraGetPage);
    }, [paraGetPage])



    /************************
     * 1:1 문의 리스트
     ************************/
    const [askData, setAskData] = useState([]);
    useQuery(['askList', pageNum],  async () => {
        const req = {
            params: { boardTp: "P", showTp: 1, regIdx: pnidx, page: pageNum - 1, size: pageRowCnt }
        };
        const res = await api.get(`/api/v1/boardAskList`, req);

        if(res.status === 200) {
            setAskData(res.data.content);
            setTotalCnt(res.data.totalElements)
        }
        return res;
    });

    useEffect(() => {
        setTotalPage(Math.ceil(totalCnt / pageRowCnt));
    }, [totalCnt])


    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 문의</span>
                    <span onClick={() => pageMoveA('/usage/Ask')}>1:1문의</span>
                </div>
            </div>
            <section className="subCont ask" id='ask'>
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>1:1 문의</h4>
                    <p>문의사항을 남겨주시면 신속하게 답변 도와드리겠습니다.</p>
                </div>
                <div className="totalBox">
                    <p>총 등록된 글 <b>{ totalCnt }</b>개</p>
                    <a className="btn btnPointLine btnS" onClick={() => pageMoveA('/usage/AskWrite')}>
                        <img src="/images/icon/edit_v.svg" alt="1:1문의하기"/>
                        1:1 문의하기</a>
                </div>
                <div className="askCont">
                    {/* <!-- 내역이 없을때 ul에 .noData 클래스 추가 --> */}
                    <ul >
                        {
                            Array.isArray(askData) && (
                                totalCnt === 0 ? (
                                    <p>문의 내역이 없습니다.</p>
                                ) : (
                                    askData.map((ask, index) => (
                                        <BoardListItem
                                            key={ask.boardIdx}
                                            item={ask}
                                            index={index}
                                            totalCnt={totalCnt}
                                            pageNum={pageNum}
                                            pageRowCnt={pageRowCnt}
                                            pageMoveA={pageMoveA}
                                            isAsk={true}
                                        />
                                    )))
                            )
                        }
                    </ul>
                </div>
                {totalCnt > 0 && (
                    <div className="pagingDiv">
                        <Pagination
                            totalPage={totalPage === 0 ? 1 : totalPage}
                            limit={pageSize}
                            page={pageNum}
                            setPage={changePages}
                            />
                    </div>
                )}
            </section>
        </>
    )
}

export default Ask;
